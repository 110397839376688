import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { getUserInfo } from '../services/userService'; // Adjust the import according to your project structure

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [userInfoReady, setUserInfoReady] = useState(false);

  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await getUserInfo();
      if (response.status === 200) {
        setUserInfo(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch user info:+++++++ ', error);
    } finally {
      setUserInfoReady(true);
    }
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  return (
    <UserContext.Provider value={{ userInfo, userInfoReady }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};