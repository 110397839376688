import React, { useState } from 'react';
import { getProviderManagerSSOSettings } from "../../../../services/staffingService"; // Adjust path accordingly
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

const SSO = ({ providerCompanyId }) => {
    const [copied, setCopied] = useState(false); // Track copy action

    // Function to handle link generation and redirection
    const handleRedirect = async () => {
        try {
            const response = await getProviderManagerSSOSettings(providerCompanyId); // Fetch the SSO link
            window.location.href = response.link; // Redirect the user to the generated link
        } catch (error) {
            console.error('Error generating SSO link:', error);
        }
    };

    // Function to handle link generation and copying to clipboard
    const handleCopy = async () => {
        try {
            const response = await getProviderManagerSSOSettings(providerCompanyId); // Fetch the SSO link
            await navigator.clipboard.writeText(response.data.link); // Copy link to clipboard
            setCopied(true); // Update the copy status to true
        } catch (error) {
            console.error('Error copying SSO link:', error);
        }
    };

    return (
  
        <div className="d-flex align-items-start account_card">

            {/* <h3>SSO Set Up for IT Admin</h3>
            <button onClick={handleRedirect}>Go</button>  
            <button onClick={handleCopy}>{copied ? 'Link Copied!' : 'Copy'}</button>  */}

            <AlternateEmailIcon className="checkboxSuccess" />
            
                          <div>
                            <h6 className="mb-1 staffing-set-up-guide-title">
                              Inegrate SSO 
                            </h6>
                            <p
                              className="mb-2 staffing-set-up-guide-description"
                              style={{ color: "#5f6368", fontSize: "14px" }}
                            >
                              Share the link with your IT Admin to integrate SSO.
                            </p>

                              {" "}
                              <button onClick={handleCopy} className="staffing-set-up-guide-link">
                              {copied ? 'Link Copied!' : 'Copy'}
                              </button>{" "}
                          </div>
        </div>

    );
};

export default SSO;
