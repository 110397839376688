import React from 'react';
import { ReactComponent as BoxIcon } from '../../../../../_asset/img/box.svg';

const Info = ({ dts, attributes }) => {
    const { city, state, population, households, old, young } = attributes;
    const nf = new Intl.NumberFormat();

    return (
        <div className='map-detail residents'>
            <div className='map-details'>
                {/* Population Section */}
                <div className='resident-count'>
                    <div className='icon'>
                        <BoxIcon />
                        <span>Residents Live Here</span>
                    </div>
                    <h2 className='count'>{nf.format(population)}</h2>
                </div>

                {/* Households Section */}
                <div className='resident-count'>
                    <div className='icon'>
                        <BoxIcon />
                        <span>Households</span>
                    </div>
                    <h2 className='count'>{nf.format(households)}</h2>
                </div>

                {/* Age Distribution Section */}
                <div className='resident-count'>
                    <div className='icon'>
                        <BoxIcon />
                        <span>{young.toFixed(2) > old.toFixed(2) ? 'Younger than 40yo' : 'older than 40yo'}</span>
                    </div>
                    <h2 className='count'>{young.toFixed(2) > old.toFixed(2) ? young.toFixed(2) : old.toFixed(2)}%</h2>
                </div>


                {/* <div className='resident-count d-flex align-items-center'>
                    <div>
                        <img
                            src="https://res.cloudinary.com/zipscores-collection/image/upload/v1633804937/free-tools/family_q40u3g.svg"
                            style={{ width: '47px', marginRight: '20px', marginTop: '10px' }}
                            alt="family"
                        />
                    </div>
                    <div>
                        {nf.format(population)}
                        <div className='resident-title mt-1'> residents live here </div>
                    </div>
                </div>
                <div className='resident-count d-flex align-items-center'>
                    <div>
                        <img
                            src="https://res.cloudinary.com/zipscores-collection/image/upload/v1633776483/free-tools/house_1_a9sesn.svg"
                            style={{ width: '47px', marginRight: '20px', marginTop: '10px' }}
                            alt="houses"
                        />
                    </div>
                    <div>
                        {nf.format(households)}
                        <div className='resident-title mt-1'> households </div>
                    </div>
                </div>
                <div className='resident-count d-flex align-items-center'>
                    <div>
                        <img
                            src="https://res.cloudinary.com/zipscores-collection/image/upload/v1633778015/free-tools/youth_yyf4oo.svg"
                            style={{ width: '48px', marginRight: '20px', marginTop: '14px' }}
                            alt="young"
                        />
                    </div>
                    <div>
                        {young.toFixed(2) > old.toFixed(2) ? (
                            <>
                                {young.toFixed(2)} %
                                <div className='resident-title mt-1'>
                                    of the population is younger than 40 years of age
                                </div>
                            </>
                        ) : (
                            <>
                                {old.toFixed(2)} %
                                <div className='resident-title'>
                                    of the population is older than 40 years of age
                                </div>
                            </>
                        )}
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Info;
