
import React, { useEffect , useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./onboarding.css";
import { createStripeAccountLink , addUserAsProviderManager , inviteUserAsProviderResource , getProviderManagerAndResource } from '../../../services/staffingService';
import { Redirect , Link , useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

import { useUser } from '../../../context/UserContext';
import { useProviderCompany } from '../../../context/ProviderCompanyContext';
import { Loader } from 'rsuite';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';


const providerManagerSchema = yup.object({
    providerManagerEmail: yup.string()
        .required('Email is required for Provider Manager')
        .email('Must be a valid email address')
});

const providerResourceSchema = yup.object({
    providerResourceEmail: yup.string()
        .required('Email is required for Provider Resource')
        .email('Must be a valid email address')
});


const ProviderStaffAllocationCompanyOnboarding = () => {
    
    const { userInfo, userInfoReady } = useUser();
    const { providerCompanyId } = useParams();
    const { providerCompanyData, fetchProviderCompanyData } = useProviderCompany();
    const [loading, setLoading]= useState(true);

    const [managerResponse, setManagerResponse] = useState('');
    const [managerError, setManagerError] = useState(false);

    const [resourceResponse, setResourceResponse] = useState('');
    const [resourceError, setResourceError] = useState(false);

    const [totalProviderManagers, setTotalProviderManagers] = useState(0);
    const [totalProviderResources, setTotalProviderResources] = useState(0);

    const [progress, setProgress] = useState(0);

    // Separate useForm instances for each form
    const {
        register: registerManager,
        handleSubmit: handleSubmitManager,
        formState: { errors: errorsManager },
        reset: resetManager,
    } = useForm({
        resolver: yupResolver(providerManagerSchema),
    });

    const {
        register: registerResource,
        handleSubmit: handleSubmitResource,
        formState: { errors: errorsResource },
        reset: resetResource,
    } = useForm({
        resolver: yupResolver(providerResourceSchema),
    });

    const onSubmitProviderManager = async (data) => {
        try {
            const resp = await addUserAsProviderManager(providerCompanyId, data.providerManagerEmail);
            setManagerResponse(resp.data);
            setManagerError(false);
        } catch (error) {
            setResourceResponse(error.response.data || 'Failed to send invitation');
            setResourceError(true);
        }
    };

    const onSubmitProviderResource = async (data) => {
        try {
            const resp = await inviteUserAsProviderResource(providerCompanyId, data.providerResourceEmail);
            setResourceResponse(resp.data);
            setResourceError(false);
            
        } catch (error) {
            setResourceResponse(error.response.data || 'Failed to send invitation');
            setResourceError(true);
        }
    };


    useEffect(() => {
        if (providerCompanyId) {
            fetchProviderCompanyData(providerCompanyId);
            getProviderManagerAndResourceData(); 
            // setProgress(calculateProgress(providerCompanyData, totalProviderManagers, totalProviderResources ));
            setLoading(false);

        }
    }, [providerCompanyId, fetchProviderCompanyData]);

    useEffect(() => {
        if (providerCompanyData) {
            setProgress(calculateProgress(providerCompanyData, totalProviderManagers, totalProviderResources));
        }
    }, [providerCompanyData, totalProviderManagers, totalProviderResources]);


    const stripeIntegration = async () => {
        setLoading(true);
        try {
            const resp = await createStripeAccountLink(providerCompanyId);
            // setLoading(false);  
            window.location.href = resp.data.accountLink;
        } catch (error) {
            setLoading(false);  
            console.error("Failed to create Stripe account link:", error);
        }
    };

    // get providerManager 
    // get providerResource

    const getProviderManagerAndResourceData = async () => {
        const resp = await getProviderManagerAndResource(providerCompanyId);
        setTotalProviderManagers(resp.data.providerManagers);
        setTotalProviderResources(resp.data.providerResources);
    }

    
    const calculateProgress = (providerCompanyData, totalProviderManagers, totalProviderResources ) => {
        let progress = 0;
    
        // Stripe Onboarding
        if (providerCompanyData.stripeAccount.charges_enabled && 
            providerCompanyData.stripeAccount.details_submitted && 
            providerCompanyData.stripeAccount.payouts_enabled) {
            progress += 30;
        } else if (!providerCompanyData.stripeAccount.email) {
            progress += 10;
        } else if (providerCompanyData.stripeAccount.email && !providerCompanyData.stripeAccount.details_submitted) {
            progress += 20;
        }
    
        // Manager Onboarding
        if (totalProviderManagers < 2) {
            progress += 10;
        } else if (totalProviderManagers >= 2) {
            progress += 20;
        }
    
        // Security Personnel Onboarding
        if (totalProviderResources < 1) {
            progress += 10;
        } else if (totalProviderResources >= 1) {
            progress += 20;
        }
        return progress;
    };
    
    
    return (
        <>
        {
             providerCompanyData && <>
                <div className="dashboard">
                    <div className="container staffing-set-up-guide-container mt-5">
                    <div className="staffing-set-up-guide-card card shadow-sm">
                        <div className="card-body">
                        <h5 className="staffing-set-up-guide-title card-title text-secondary" style={{ color: '!important' }}>
                            { `Hello ${userInfo.firstName.charAt(0).toUpperCase() + userInfo.firstName.slice(1)}, please set up your account with ${providerCompanyData.stripeAccount.business_profile?.name.charAt(0).toUpperCase() + providerCompanyData.stripeAccount.business_profile?.name.slice(1)} to efficiently manage work orders.` }
                        </h5>
                        <div className="progress staffing-set-up-guide-progress mt-3 mb-2" style={{ height: '8px' }}>
                            <div className="progress-bar" style={{ width: `${progress}%`, backgroundColor: '#13A391' }}></div>
                        </div>

                        {/* ========= stripe onboarding ======= */}


                        {
                            // Full verification: charges enabled, details submitted, and payouts enabled
                            providerCompanyData.stripeAccount.charges_enabled && 
                            providerCompanyData.stripeAccount.details_submitted && 
                            providerCompanyData.stripeAccount.payouts_enabled &&
                            <div className="d-flex align-items-start mt-4 staffing-set-up-guide-task">
                                <CheckCircleIcon className="checkboxSuccess" />
                                <div>
                                    <h6 className="mb-1 staffing-set-up-guide-title">
                                        Congratulations! Your Stripe account is verified and ready to receive payments and make payouts.
                                    </h6>
                                    <p className="mb-2 staffing-set-up-guide-description" style={{ color: '#5f6368', fontSize: '14px' }}>
                                        Your account is fully verified and you can start transactions.
                                    </p>
                                    <button onClick={stripeIntegration} className="staffing-set-up-guide-link">Go to Dashboard</button>
                                </div>
                            </div>
                        }

                        {
                            // No email provided yet
                            !providerCompanyData.stripeAccount.email &&
                            <div className="d-flex align-items-start mt-4 staffing-set-up-guide-task">
                                <CheckCircleIcon className="checkboxUnfinished" />
                                <div>
                                    <h6 className="mb-1 staffing-set-up-guide-title">
                                        Set Up Stripe for Business Identity Verification and Payments
                                    </h6>
                                    <p className="mb-2 staffing-set-up-guide-description" style={{ color: '#5f6368', fontSize: '14px' }}>
                                        Ensure your business's credibility and streamline your payment processes by configuring Stripe for identity verification and secure financial transactions.
                                    </p>
                                    <button onClick={stripeIntegration} className="staffing-set-up-guide-link">Start Onboarding</button>
                                </div>
                            </div>
                        }

                        {
                            // Email provided but details not submitted
                            providerCompanyData.stripeAccount.email && 
                            !providerCompanyData.stripeAccount.details_submitted &&
                            <div className="d-flex align-items-start mt-4 staffing-set-up-guide-task">
                                <CheckCircleIcon className="checkboxUnfinished" />
                                <div>
                                    <h6 className="mb-1 staffing-set-up-guide-title">
                                        Complete Your Stripe Setup
                                    </h6>
                                    <p className="mb-2 staffing-set-up-guide-description" style={{ color: '#5f6368', fontSize: '14px' }}>
                                        Ensure your business's credibility and streamline your payment processes by configuring Stripe for identity verification and secure financial transactions.
                                    </p>
                                    <button onClick={stripeIntegration} className="staffing-set-up-guide-link">Complete Verification</button>
                                </div>
                            </div>
                        }


                        {/* ========= add provider manager ======= */}

                        { totalProviderManagers < 2 && (
                            <div className="d-flex align-items-start mt-4 staffing-set-up-guide-task">
                                <CheckCircleIcon className='checkboxUnfinished' />
                                <div>
                                    <h6 className="mb-1 staffing-set-up-guide-title">Add Managers to Your Team</h6>
                                    <p className="mb-2 staffing-set-up-guide-description" style={{ color: '#5f6368', fontSize: '14px' }}>
                                        Add managers to help allocate security staff for various work orders.
                                    </p>
                                    <form onSubmit={handleSubmitManager(onSubmitProviderManager)} className="form-group">
                                        <div className="d-flex align-items-center mb-2">
                                            <input
                                                style={{ width: '250px', height: '30px', fontSize: '14px' }}
                                                type="text"
                                                className="form-control mr-2"
                                                {...registerManager('providerManagerEmail')}
                                                placeholder="Add manager email"
                                            />
                                            <button type="submit" className="btn staffing-button-form-add-user">+</button>
                                        </div>
                                        {managerResponse && (
                                            <div className={`${managerError ? 'errorTextForm' : 'text-success'}`}>{managerResponse}</div>
                                        )}
                                        {errorsManager.providerManagerEmail && <div className="errorTextForm">{errorsManager.providerManagerEmail.message}</div>}
                                    </form>
                                </div>
                            </div>
                        )}

                
                        { totalProviderManagers >= 2 && (
                            <div className="d-flex align-items-start mt-4 staffing-set-up-guide-task">
                                <CheckCircleIcon className='checkboxSuccess' />
                                <div>
                                    <h6 className="mb-1 staffing-set-up-guide-title">Manage Your Team</h6>
                                    <p className="mb-2 staffing-set-up-guide-description" style={{ color: '#5f6368', fontSize: '14px' }}>
                                        Good job! You currently have {totalProviderManagers} {totalProviderManagers === 1 ? 'manager' : 'managers'} to help you manage work orders.
                                    </p>
                                    <form onSubmit={handleSubmitManager(onSubmitProviderManager)} className="form-group">
                                        <div className="d-flex align-items-center mb-2">
                                            <input
                                                style={{ width: '250px', height: '30px', fontSize: '14px' }}
                                                type="text"
                                                className="form-control mr-2"
                                                {...registerManager('providerManagerEmail')}
                                                placeholder="Add manager email"
                                            />
                                            <button type="submit" className="btn staffing-button-form-add-user">+</button>
                                        </div>
                                        {managerResponse && (
                                            <div className={`${managerError ? 'errorTextForm' : 'text-success'}`}>{managerResponse}</div>
                                        )}
                                        {errorsManager.providerManagerEmail && <div className="errorTextForm">{errorsManager.providerManagerEmail.message}</div>}
                                    </form>
                                </div>
                            </div>
                        )}

                        {/* ========= add provider resource ======= */}
            
                        { totalProviderResources < 1 && (
                            <div className="d-flex align-items-start mt-2 staffing-set-up-guide-task">
                                <CheckCircleIcon className="checkboxUnfinished" />
                                <div>
                                    <h6 className="mb-1 staffing-set-up-guide-title">Invite Security Personnel to Join Your Company</h6>
                                    <p className="mb-2 staffing-set-up-guide-description" style={{ color: '#5f6368', fontSize: '14px' }}>
                                        Recruit security personnel to handle security assignments and operations.
                                    </p>
                                    <form onSubmit={handleSubmitResource(onSubmitProviderResource)} className="form-group">
                                        <div className="d-flex align-items-center mb-2">
                                            <input
                                                style={{ width: '250px', height: '30px', fontSize: '14px' }}
                                                type="text"
                                                className="form-control mr-2"
                                                {...registerResource('providerResourceEmail')}
                                                placeholder="Add security personnel email"
                                            />
                                            <button type="submit" className="btn staffing-button-form-add-user">+</button>
                                        </div>
                                        {resourceResponse && (
                                            <div className={`${resourceError ? 'errorTextForm' : 'text-success'}`}>{resourceResponse}</div>
                                        )}
                                        {errorsResource.providerResourceEmail && <div className="errorTextForm">{errorsResource.providerResourceEmail.message}</div>}
                                    </form>
                                </div>
                            </div>
                        )}

                        { totalProviderResources >= 1 && (
                            <div className="d-flex align-items-start mt-2 staffing-set-up-guide-task">
                                <CheckCircleIcon className='checkboxSuccess' />
                                <div>
                                    <h6 className="mb-1 staffing-set-up-guide-title">Manage Your Security Personnel</h6>
                                    <p className="mb-2 staffing-set-up-guide-description" style={{ color: '#5f6368', fontSize: '14px' }}>
                                        You currently have {totalProviderResources} {totalProviderResources === 1 ? 'security personnel' : 'security personnel'} in your team.
                                    </p>
                                    <form onSubmit={handleSubmitResource(onSubmitProviderResource)} className="form-group">
                                        <div className="d-flex align-items-center mb-2">
                                            <input
                                                style={{ width: '250px', height: '30px', fontSize: '14px' }}
                                                type="text"
                                                className="form-control mr-2"
                                                {...registerResource('providerResourceEmail')}
                                                placeholder="Add security personnel email"
                                            />
                                            <button type="submit" className="btn staffing-button-form-add-user">+</button>
                                        </div>
                                        {resourceResponse && (
                                            <div className={`${resourceError ? 'errorTextForm' : 'text-success'}`}>{resourceResponse}</div>
                                        )}
                                        {errorsResource.providerResourceEmail && <div className="errorTextForm">{errorsResource.providerResourceEmail.message}</div>}
                                    </form>
                                </div>
                            </div>
                        )}
                        
                        {/* ========= start managing work orders ======= */}

                        <div className="d-flex align-items-start mt-2 staffing-set-up-guide-task">
                            {/* <CheckCircleIcon style={{ color: '#13A391', fontSize: '20px', marginRight: '20px' }} /> */}
                            <span style={{ fontSize: '20px', marginRight: '20px' }}> {'\u{1F389}'} </span>
                            <div>
                                <h6 className="mb-1 staffing-set-up-guide-title"> Start Managing Work Orders </h6>
                                <p className="mb-2 staffing-set-up-guide-description" style={{ color: '#5f6368', fontSize: '14px' }}>    
                                    { progress === 100 ? 'Congratulations! You have completed all the tasks.' : 'Finish above tasks to start managing work orders.' }
                                </p>
                                <span className="staffing-set-up-guide-link">  </span>
                                <Link to={`/provider-company/${providerCompanyId}/jobs`}> <button className="staffing-set-up-guide-link"> Go </button> </Link>
                            </div>
                        </div>
            
                        <div class="custom-support-section text-center">
                            <div style={{color:'#ffffff' , width:'100px' }} >
                            <Link to={`/home`} style={{color:'inherit'}}>
                                <HomeIcon />
                            </Link>
                            </div>
                        </div>
            
                        </div>
                    </div>
                    </div>
                </div>
            </>
        }
        {
           loading &&  <Loader backdrop center  />
        }
        </>
    );
};

export default ProviderStaffAllocationCompanyOnboarding;
