import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import EditIcon from "@mui/icons-material/Edit";
import { Tag, Button } from "rsuite";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SecurityIcon from "@mui/icons-material/Security";
import { updateProviderResourcePreferencesByOfficer } from "../../../../services/staffingService";

const preferencesSchema = yup.object().shape({
  minHourlyRate: yup.number().required("Min hourly rate is required"),
  maxDistanceInMiles: yup.number().required("Max distance is required"),
  shiftDays: yup
    .array()
    .of(yup.string())
    .min(1, "At least one shift day must be selected")
    .required(),
  shiftTimes: yup
    .array()
    .of(yup.string())
    .min(1, "At least one shift time must be selected")
    .required(),
  servicesOffered: yup
    .array()
    .of(yup.string())
    .min(1, "At least one service must be selected")
    .required(),
});

const PreferencesTab = ({ providerResource, providerCompanyId, setProviderResource }) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(preferencesSchema),
    defaultValues: {
      minHourlyRate: providerResource.preferences.minHourlyRate,
      maxDistanceInMiles: providerResource.preferences.maxDistanceInMiles,
      shiftDays: providerResource.preferences.shiftDays,
      shiftTimes: providerResource.preferences.shiftTimes,
      servicesOffered: providerResource.servicesOffered,
    },
  });

  useEffect(() => {
    if (providerResource) {
      setValue("minHourlyRate", providerResource.preferences.minHourlyRate);
      setValue("maxDistanceInMiles", providerResource.preferences.maxDistanceInMiles);
      setValue("shiftDays", providerResource.preferences.shiftDays);
      setValue("shiftTimes", providerResource.preferences.shiftTimes);
      setValue("servicesOffered", providerResource.servicesOffered);
    }
  }, [providerResource, setValue]);

  const toggleEditMode = () => setIsEditing(!isEditing);

  const onSubmit = async (data) => {
    try {
      await updateProviderResourcePreferencesByOfficer(providerCompanyId, providerResource._id, {
        minHourlyRate: data.minHourlyRate,
        maxDistanceInMiles: data.maxDistanceInMiles,
        shiftDays: data.shiftDays,
        shiftTimes: data.shiftTimes,
        servicesOffered: data.servicesOffered,
      });

      setProviderResource((prevData) => ({
        ...prevData,
        preferences: {
          ...prevData.preferences,
          minHourlyRate: data.minHourlyRate,
          maxDistanceInMiles: data.maxDistanceInMiles,
          shiftDays: data.shiftDays,
          shiftTimes: data.shiftTimes,
        },
        servicesOffered: data.servicesOffered,
      }));

      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update preferences:", error);
    }
  };

  const shiftDayOptions = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

  const shiftTimeOptions = [
    { value: "Morning", label: "Morning" },
    { value: "Afternoon", label: "Afternoon" },
    { value: "Evening", label: "Evening" },
    { value: "Night", label: "Night" },
  ];

  const serviceOfferedOptions = [
    { value: "standing_post", label: "Standing Post" },
    { value: "mobile_patrol", label: "Mobile Patrol" },
    { value: "loss_prevention", label: "Loss Prevention" },
    { value: "event_security", label: "Event Security" },
  ];

  return (
    <div>
      {!isEditing ? (
        <div className="prepopulated-values-data p-3">
          <p>
            <AttachMoneyIcon className="icon" /> Preferred Hourly Rate: $
            {providerResource.preferences.minHourlyRate} and up
          </p>
          <p>
            <LocationOnIcon className="icon" /> Willing to travel up to{" "}
            {providerResource.preferences.maxDistanceInMiles} miles
          </p>
          <p>
            <EventIcon className="icon" /> Preferred Shift Days:{" "}
            {providerResource.preferences.shiftDays.map((day) => (
              <Tag className="prepopulated-value" size="lg" key={day}>
                {day}
              </Tag>
            ))}
          </p>
          <p>
            <ScheduleIcon className="icon" /> Preferred Shift Times:{" "}
            {providerResource.preferences.shiftTimes.map((time) => (
              <Tag className="prepopulated-value" size="lg" key={time}>
                {time}
              </Tag>
            ))}
          </p>
          <p>
            <SecurityIcon className="icon" /> Services Offered:{" "}
            {providerResource.servicesOffered.map((service) => (
              <Tag className="prepopulated-value" size="lg" key={service}>
                {service.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
              </Tag>
            ))}
          </p>
          <Button startIcon={<EditIcon />} onClick={toggleEditMode} className="edit-button">
            Edit
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label className="title">Min Hourly Rate</label>
            <input
              type="number"
              {...register("minHourlyRate")}
              className="form-control"
            />
            {errors.minHourlyRate && (
              <span className="errorTextForm">{errors.minHourlyRate.message}</span>
            )}
          </div>

          <div className="form-group">
            <label className="title">Max Distance (in miles)</label>
            <input
              type="number"
              {...register("maxDistanceInMiles")}
              className="form-control"
            />
            {errors.maxDistanceInMiles && (
              <span className="errorTextForm">{errors.maxDistanceInMiles.message}</span>
            )}
          </div>

          <div className="form-group">
            <label className="title">Shift Days</label>
            <Controller
              name="shiftDays"
              control={control}
              render={({ field }) => (
                <Select
                  isMulti
                  options={shiftDayOptions}
                  value={field.value.map((day) => ({ value: day, label: day }))}
                  onChange={(selected) =>
                    field.onChange(selected.map((option) => option.value))
                  }
                />
              )}
            />
            {errors.shiftDays && (
              <span className="errorTextForm">{errors.shiftDays.message}</span>
            )}
          </div>

          <div className="form-group">
            <label className="title">Shift Times</label>
            <Controller
              name="shiftTimes"
              control={control}
              render={({ field }) => (
                <Select
                  isMulti
                  options={shiftTimeOptions}
                  value={field.value.map((time) => ({ value: time, label: time }))}
                  onChange={(selected) =>
                    field.onChange(selected.map((option) => option.value))
                  }
                />
              )}
            />
            {errors.shiftTimes && (
              <span className="errorTextForm">{errors.shiftTimes.message}</span>
            )}
          </div>

          <div className="form-group">
            <label className="title">Services Offered</label>
            <Controller
              name="servicesOffered"
              control={control}
              render={({ field }) => (
                <Select
                  isMulti
                  options={serviceOfferedOptions}
                  value={field.value.map((service) => ({
                    value: service,
                    label: service.replace(/_/g, " "),
                  }))}
                  onChange={(selected) =>
                    field.onChange(selected.map((option) => option.value))
                  }
                />
              )}
            />
            {errors.servicesOffered && (
              <span className="errorTextForm">{errors.servicesOffered.message}</span>
            )}
          </div>

          <button type="submit" className="staffing-btn confirm-btn">Save</button>
          <button
            type="button"
            className="staffing-btn cancel-btn ml-2"
            onClick={toggleEditMode}
          >
            Cancel
          </button>
        </form>
      )}
    </div>
  );
};

export default PreferencesTab;
