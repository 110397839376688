import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const StatusTabs = ({ providerCompanyId, openOffers, acceptedOffers, completedOffers, activeTab, gridView, setGridView }) => {
  return (
    <div className='d-flex align-items-center justify-content-between status-tabs-wraper'>
      <div className="status-tabs">
        <Link to={`/provider-resource/${providerCompanyId}/dashboard/work-order/open`} style={{ color: 'inherit' }}>
          <div className={`tab ${activeTab === 'open' ? 'active' : ''}`}>
            <div className="label">New</div>
            <div className="count">{openOffers.length}</div>
          </div>
        </Link>
        <Link to={`/provider-resource/${providerCompanyId}/dashboard/work-order/accepted`} style={{ color: 'inherit' }}>
          <div className={`tab ${activeTab === 'accepted' ? 'active' : ''}`}>
            <div className="label">Active </div>
            <div className="count">{acceptedOffers.length}</div>
          </div>
        </Link>
        <Link to={`/provider-resource/${providerCompanyId}/dashboard/work-order/completed`} style={{ color: 'inherit' }}>
          <div className={`tab ${activeTab === 'completed' ? 'active' : ''}`}>
            <div className="label">Completed</div>
            <div className="count">{completedOffers.length}</div>
          </div>
        </Link>
      </div>
      <div className='view_box'>
        <div className={`box-view ${gridView === 0 ? 'active' : ''}`} onClick={() => setGridView(0)}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49984 18.3332H12.4998C16.6665 18.3332 18.3332 16.6665 18.3332 12.4998V7.49984C18.3332 3.33317 16.6665 1.6665 12.4998 1.6665H7.49984C3.33317 1.6665 1.6665 3.33317 1.6665 7.49984V12.4998C1.6665 16.6665 3.33317 18.3332 7.49984 18.3332Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 1.6665V18.3332" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.6665 10H18.3332" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </div>
        <div className={`box-view ${gridView === 1 ? 'active' : ''}`} onClick={() => setGridView(1)}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5832 11.25H3.4165C2.1665 11.25 1.6665 11.7833 1.6665 13.1083V16.475C1.6665 17.8 2.1665 18.3333 3.4165 18.3333H16.5832C17.8332 18.3333 18.3332 17.8 18.3332 16.475V13.1083C18.3332 11.7833 17.8332 11.25 16.5832 11.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.5832 1.6665H3.4165C2.1665 1.6665 1.6665 2.19984 1.6665 3.52484V6.8915C1.6665 8.2165 2.1665 8.74984 3.4165 8.74984H16.5832C17.8332 8.74984 18.3332 8.2165 18.3332 6.8915V3.52484C18.3332 2.19984 17.8332 1.6665 16.5832 1.6665Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </div>
      </div>
    </div>
  );
};

StatusTabs.propTypes = {
  providerCompanyId: PropTypes.string.isRequired,
  openOffers: PropTypes.array.isRequired,
  acceptedOffers: PropTypes.array.isRequired,
  completedOffers: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default StatusTabs;
