import React, { useState, useEffect } from 'react';
import { Steps, Button, Panel, Notification } from 'rsuite';

import { Link } from 'react-router-dom';
// import 'rsuite/dist/rsuite.min.css';
import './offerStepper.css';
import NegotiationSteps from "./NegotiationSteps"; 

import { createCustomerCheckoutSession , sendPayout } from "../../../../../../../services/staffingService"; 


function formatJobDateTime(dateTime) {
    const date = new Date(dateTime);
    return date.toLocaleString('en-US', {
        weekday: 'short', // "Mon", "Tue", etc.
        year: 'numeric',  // "2024"
        month: 'short',   // "Jul"
        day: 'numeric',   // "31"
        hour: '2-digit',  // "10 PM"
        minute: '2-digit' // "30 PM"
    });
  }


  const ActionComponent = ({ shift }) => {
    const now = new Date().getTime();
    const checkInWindowStart = new Date(shift.checkIn.timeWindow.start).getTime();
    const checkInWindowEnd = new Date(shift.checkIn.timeWindow.end).getTime();
    const checkOutWindowStart = new Date(shift.checkOut.timeWindow.start).getTime();
    const checkOutWindowEnd = new Date(shift.checkOut.timeWindow.end).getTime();

    const renderCheckInStatus = () => {
        switch (true) {
            case shift.checkIn.confirmed:
                return `Geo Check In confirmed at ${new Date(shift.checkIn.timestamp).toLocaleTimeString()} Hrs.`;
            case now > checkInWindowEnd:
                return 'No show.';
            default:
                return `Geo Check In - Pending - ${formatJobDateTime(shift.checkIn.timeWindow.start)} Hrs.`;
        }
    };

    const renderCheckOutStatus = () => {
        switch (true) {
            case shift.checkOut.confirmed:
                return `Geo Check Out confirmed at ${new Date(shift.checkOut.timestamp).toLocaleTimeString()} Hrs.`;
            case shift.checkIn.confirmed && now > checkOutWindowEnd:
                return 'Check out window has passed.';
            case shift.checkIn.confirmed:
                return `Geo Check Out - Pending - ${formatJobDateTime(shift.checkOut.timeWindow.start)} Hrs.`;
            default:
                return 'Check-in required before check-out.';
        }
    };

    return (
        <div>
            <p>{renderCheckInStatus()}</p>
            <p>{renderCheckOutStatus()}</p>
        </div>
    );
};


const WorkflowStepper = ({ offer, negotiations, setOffer, setNegotiations, offerId , providerCompanyId, jobId  }) => { 

    return (
    //   <div className="p-4" id="offer-stepper">
    //       <Steps current={offer.overallActiveStep} horizontal>

    //           <Steps.Item title="Negotiation" description={ 
    //                 <NegotiationSteps
    //                     negotiations={ negotiations } 
    //                     setNegotiations = {setNegotiations}
    //                     offer={ offer }
    //                     setOffer={ setOffer }
    //                     offerId={ offerId }
    //                     providerCompanyId= { providerCompanyId }
    //                     jobId={ jobId }
    //                 />
    //            } />
    //            <Steps.Item
    //                 title="Job Status"
    //                 description={
    //                     <>
    //                         {offer.overallActiveStep >= 1 && offer.shifts.map((shift, index) => (
    //                             <div key={index}>
    //                                 <ActionComponent shift={shift} />
    //                             </div>
    //                         ))}
    //                     </>
    //                 }
    //             />
    //           <Steps.Item title="Payment" description={
    //                 <>
    //                     {offer.overallActiveStep >= 2 && (
    //                         <>
    //                             <p>
    //                                 {offer.jobId.invoice.status === 'paid' ? (
    //                                     <>
    //                                         Payment successfully received from {offer.jobId.customerName}.  <br/>
    //                                         <a href={offer.jobId.invoice.url} target="_blank" style={{ color: '#2a9d8f', textDecoration: 'underline', fontWeight: 'bold' }}>
    //                                             View receipt
    //                                         </a>.
    //                                     </>
    //                                 ) : (
    //                                     <>
    //                                         Invoice issued to {offer.jobId.customerName}. <br/>
    //                                         Current payment status -  
    //                                         <strong className="ml-1" style={{ color: offer.jobId.invoice.status === 'open' ? '#e9c46a' : '#e76f51' }}>
    //                                             {offer.jobId.invoice.status}
    //                                         </strong>
    //                                     </>
    //                                 )}
    //                             </p>
    //                             <p>
    //                             {offer.invoice.status === 'paid' ? (
    //                                     <>
    //                                         Payment received for invoice from {offer.provider_resource_id.firstName}. <br/>
    //                                         <a href={offer.invoice.url} target="_blank" style={{ color: '#2a9d8f', textDecoration: 'underline', fontWeight: 'bold' }}>
    //                                             View receipt
    //                                         </a>
    //                                     </>
    //                                 ) : (
    //                                     <>
    //                                         Payment requested by {offer.provider_resource_id.firstName} for invoice. <br/>
    //                                         <a href={offer.invoice.url} target="_blank" style={{ color: '#db2777', textDecoration: 'none', fontWeight: 'bold' }}>
    //                                             Make a payment
    //                                         </a>
    //                                     </>
    //                                 )}
    //                             </p>
    //                         </>
    //                     )}
    //                 </>
    //             } />
    //       </Steps>
    //   </div>

<div className="offer-stepper-container" id="offer-stepper">
  <Steps current={offer.overallActiveStep} vertical>
    <Steps.Item
      title="Negotiation"
      description={
        <NegotiationSteps
          negotiations={negotiations}
          setNegotiations={setNegotiations}
          offer={offer}
          setOffer={setOffer}
          offerId={offerId}
          providerCompanyId={providerCompanyId}
          jobId={jobId}
        />
      }
    />
    <Steps.Item
      title="Job Status"
      description={
        <>
          {offer.overallActiveStep >= 1 &&
            offer.shifts.map((shift, index) => (
              <div key={index} className="shift-action">
                <ActionComponent shift={shift} />
              </div>
            ))}
        </>
      }
    />
    <Steps.Item
      title="Payment"
      description={
        <>
          {offer.overallActiveStep >= 2 && (
            <div className="payment-info">
              <p>
                {offer.jobId.invoice.status === 'paid' ? (
                  <>
                    Payment successfully received from {offer.jobId.customerName}. <br />
                    <a
                      href={offer.jobId.invoice.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="receipt-link"
                    >
                      View receipt
                    </a>
                  </>
                ) : (
                  <>
                    Invoice issued to {offer.jobId.customerName}. <br />
                    Current payment status -
                    <strong className={`payment-status ${offer.jobId.invoice.status}`}>
                      {offer.jobId.invoice.status}
                    </strong>
                  </>
                )}
              </p>
            </div>
          )}
        </>
      }
    />
  </Steps>
</div>


    );
  };
  

export default WorkflowStepper;
 