import React, { useState , useEffect } from "react";
import { Drawer, SelectPicker, Steps , Button } from 'rsuite';
import { toast, Toaster } from 'react-hot-toast';

import '../../../../styles/sideDrawer.css';
import { Fragment } from 'react';
import { Badge } from '@mui/material';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom';
import SendWhiteIcon from '../../../../../_asset/img/send-white_icon.svg';
import Document from '../../../../../_asset/img/docu.svg';
import Paperclip from '../../../../../_asset/img/paperclip-2.png';
import InfoCircle from '../../../../../_asset/img/info-circle.svg';
import TimerStart from '../../../../../_asset/img/timer-start.svg';
import { ReactComponent as Profile } from '../../../../../_asset/img/profile.svg';

import { postJobComment , getCommentsForJob , getTeamManagers , assignJob } from "../../../../../../services/staffingService";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const schema = yup
  .object({
    comment: yup
      .string()
      .required("comment is required")
      .max(3200, "Max length exceeded"),
  })
.required();


const SideDrawer = ({ job, isOpen, setIsOpen, getByItem }) => {
    const { providerCompanyId } = useParams();

    const [comments, setComments] = useState([]);
    const [selectedManager, setSelectedManager] = useState(null);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [dispatchManagers, setDispatchManagers] = useState([]);

    const [countdown, setCountdown] = useState(5);

    // const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    //     item => ({ label: item, value: item })  
    // );
    
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        await postJobComment(providerCompanyId , job._id, data.comment);
        const resp = await getCommentsForJob(providerCompanyId, job._id);
        setComments([...resp.data.comments]);
        reset();
    }

    useEffect(() => {
        fetchComments();
        fetchProviderManagers();
    }, [job]);

    async function fetchComments() {
        const resp = await getCommentsForJob(providerCompanyId, job._id);
        setComments([...resp.data.comments]);
    }

    async function fetchProviderManagers() {
        const resp = await getTeamManagers(providerCompanyId);

        const formattedData = resp.data.providerManagers.map(item => ({
          label: `${item.user_id.firstName} ${item.user_id.lastName}`,
          value: item
        }));
        setDispatchManagers(formattedData);
    }


    const handleSelectManager = (value) => {
        setSelectedManager(value);
        setShowSaveButton(true); 
    };

    const handleSave = async () => {

        if (selectedManager) {
            await assignJob(providerCompanyId, job._id, selectedManager._id);
            setShowSaveButton(false); 

            const toastId = toast.success(`Dispatch Manager assigned. Refreshing page in ${countdown} seconds...`, {
                duration: 5000
            });
        
            const countdownInterval = setInterval(() => {
                setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(countdownInterval);
                    window.location.reload();
                    return 0;
                }
                const newCountdown = prevCountdown - 1;
                toast.dismiss(toastId);
                toast.success(`Dispatch Manager assigned. Refreshing page in ${newCountdown} seconds...`, {
                    id: toastId,
                    duration: newCountdown * 1000,
                });
                return newCountdown;
                });
            }, 1000);
        }

    };
    

    const handleClearManager = () => {
        setSelectedManager(null);
        setShowSaveButton(false); 
    };
    

    return (
        <>
        <Drawer open={isOpen} onClose={() => setIsOpen(false)} className="side_drawer">
            <Drawer.Body>
                <div className='drawer_content'>
                    <div className='left_bar'>
                        <h2 className='title'> {job.customer_company_id.companyLegalName} </h2>
                        {/* <span> {job.formattedAddress} </span> */}
                        <div className='drawer_inner_content' id='scrollBar'>
                            <div className='sub_titles'>
                                <h4>Memo</h4>
                                <p>{job.memo || "..."}</p>
                            </div>
                            <div className='time_amount'>
                                <h2 className='amount'>${job.hourlyPayRate}/hrs</h2>
                                <p className='description'>Max. Hourly Rate</p>
                            </div>
                            <div className='shifts_card'>
                                <h2 className='shifts_title'>Shifts</h2>
                                <table className="table border-0 m-0">
                                    <thead>
                                        <tr>
                                            <th>Shift Date</th>
                                            <th>Shift Times</th>
                                            <th>Officers Required</th>
                                        </tr>
                                    </thead>
                                    <tbody id="data-body">
                                        {job.shifts.map((shift) => (
                                            <tr key={shift._id}>
                                                <td>{new Date(shift.date).toLocaleDateString()}</td>
                                                <td>{`${new Date(shift.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} to ${new Date(shift.endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} Hrs`}</td>
                                                <td>{shift.staffsRequired}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='shifts_card offer_card'>
                                <h2 className='shifts_title'>Offer Statistics</h2>
                                <div className='offer_items'>
                                    <div className='offer_item'>
                                        <p className='label'>Recomendedations </p>
                                        <p className='details'>{ job.totalRecommendations }</p>
                                    </div>
                                    <div className='offer_item'>
                                        <p className='label'>Shift Coverage</p>
                                        <p className='details'>{job.statistics.overallCoverage.toFixed(2)} %</p>
                                    </div>
                                    <div className='offer_item'>
                                        <p className='label'>Offers</p>

                                        <div className='details d-flex text-align-center align-items-center'>
                                            <div className="d-flex align-items-center">
                                                <span className="circle-number-open"> {job.statistics.offerCounts.open} </span>
                                                <span className="ml-1">Open</span>
                                            </div>
                                            <div className="ml-3 d-flex align-items-center">
                                                <span className="circle-number-accepted"> {job.statistics.offerCounts.accepted} </span>
                                                <span className="ml-1">Accepted</span>
                                            </div>
                                            <div className="ml-3 d-flex align-items-center">
                                                <span className="circle-number-rejected"> {job.statistics.offerCounts.rejected} </span>
                                                <span className="ml-1">Rejected</span>
                                            </div>
                                        </div>


                                    </div>
                                    {/* <div className='offer_item'>
                                        <p className='label'>Risk Factor</p>
                                        <div className='details'>
                                            <div className='rist_step'>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className='comment_content'>
                                <Steps current={1} vertical>
                                    {comments.length >=1 && comments.map((comment, index) => (
                                        <Steps.Item
                                            key={comment._id}
                                            title={
                                                <div className='comment_title'>
                                                    <h4 className='comment_heading'>
                                                        {comment.provider_manager_id && comment.provider_manager_id.user_id 
                                                            ? `${comment.provider_manager_id.user_id.firstName} ${comment.provider_manager_id.user_id.lastName}` 
                                                            : 'Unknown Manager'}
                                                    </h4>
                                                    <ul className='other_details'>
                                                        <li>{new Date(comment.createdAt).toLocaleDateString()}</li>
                                                    </ul>
                                                </div>
                                            }
                                            description={
                                                <Fragment>
                                                    <p className='description'>{comment.text}</p>
                                                    {/* Example if there's a document associated - remove this if not applicable */}
                                                    {comment.attachmentUrl && (
                                                        <div>
                                                            <Badge className='document'>
                                                                <img src={Document} alt="Document Icon" /> {comment.attachmentUrl}
                                                            </Badge>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            }
                                            icon={<> <img style={{height : "24px" , width:"24px" , borderRadius: "24px"}} src={comment.provider_manager_id.user_id.profilePictureUrl} /> </>}
                                        />
                                    ))}
                                </Steps>   

                            </div>

                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className='send_comment'>
                                    <textarea rows={3} placeholder='Ask AI' {...register('comment')} ></textarea>
                                    {errors.comment && (
                                        <span className="errorTextForm mt-1">
                                        {" "}
                                        {errors.comment.message}{" "}
                                        </span>
                                    )}
                                    <div className='comment_btn'>
                                        {/* <img src={Paperclip} alt="" /> */}
                                        <button className='btn'>Comment</button>
                                    </div>
                                </div> 
                            </form>    

                        </div>
                    </div>
                    <div className='right_bar' id='scrollBar'>
                        <div>

                            {/* <h2 className='title_assi'>Job Assignment</h2>
                            <div className='data_card'>
                                <p>Assigned Dispatch Manager:</p>
                                <h2>Manjunath <img src={InfoCircle} alt="Information about the manager" /></h2>
                                <label>Reassign Dispatch Manager:</label>
                                <SelectPicker data={data} placeholder="Select Dispatch Manager" />
                            </div> */}

                            <h2 className='title_assi'>Job Assignment</h2>
                                <div className='data_card'>
                                    <p>Assigned Dispatch Manager:</p>
                                    <h2> {job.provider_manager_id.user_id.firstName}  {job.provider_manager_id.user_id.lastName}  <img src={InfoCircle} alt="Information about the manager" /></h2>
                                    <label>Reassign Dispatch Manager:</label>
                                    <SelectPicker
                                    data={dispatchManagers} 
                                    onSelect={handleSelectManager}
                                    onClean={handleClearManager}
                                    placeholder="Managers"
                                    style={{ width: 300 }}
                                    />
                                    {showSaveButton && (
                                    <Button appearance="primary" size="xs" onClick={handleSave} style={{ marginTop: '10px' }}>
                                        Save
                                    </Button>
                                    )}
                            </div>

                            <h2 className='title_assi'>Other Details</h2>
                            <div className='data_card'>
                                <div>
                                    <p>Job ID</p>
                                    <h2>#{job._id}</h2>
                                </div>
                                <div>
                                    <p>Service Type:</p>
                                    <h2>{job.service.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }</h2>
                                </div>
                            </div>
                            <h2 className='title_assi'>Impact</h2>
                            <div className='data_card'>
                                <div className='other_item'>
                                    <p>Revenue:</p>
                                    <div className='right_col'>
                                        <Badge className='high badge_status'>High</Badge>
                                    </div>
                                </div>
                                <div className='other_item'>
                                    <p>Priority:</p>
                                    <div className='right_col'>
                                        <Badge className='low badge_status'>Low</Badge>
                                    </div>
                                </div>
                                <div className='other_item'>
                                    <p>Urgency:</p>
                                    <div className='right_col'>
                                        <Badge className='medium badge_status'>Medium</Badge>
                                    </div>
                                </div>
                                {/* <div className='other_item'>
                                    <p>SLA:</p>
                                    <div className='right_col info'><img src={TimerStart} alt="" /> <span>2 Days</span></div>
                                </div>
                                <div className='other_item'>
                                    <p>Created on</p>
                                    <div className='right_col info'>{new Date(job.createdAt).toLocaleDateString()}, {new Date(job.createdAt).toLocaleTimeString()}</div>
                                </div>
                                <div className='other_item'>
                                    <p>Created by</p>
                                    <div className='right_col info'><Profile /> <span>Alok</span></div>
                                </div>
                                <div className='other_item'>
                                    <p>Last Updated</p>
                                    <div className='right_col info'>{new Date(job.invoice.updatedAt).toLocaleDateString()}, {new Date(job.invoice.updatedAt).toLocaleTimeString()}</div>
                                </div>
                                <div className='other_item'>
                                    <p>Last Updated by</p>
                                    <div className='right_col info'><Profile /><span>Pradeep</span></div>
                                </div> */}
                            </div>
                        </div>
                        <div className='send_offer'>
                            {/* <SelectPicker data={data} placement='topStart' placeholder='In process' /> */}
                            {/* <Link to={`/provider-company/${providerCompanyId}/jobs/${getByItem?._id}?view=recommended`} className='send_offer_btn'> */}
                            <Link to={`/provider-company/${providerCompanyId}/jobs/${getByItem?._id}/offer`} className='send_offer_btn'>
                                Send Offers                    
                                <span className='send_icon'>
                                    <span className='line'></span>
                                    <img src={SendWhiteIcon} alt="" />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </Drawer.Body>
        </Drawer>
        </>
    );
}

export default SideDrawer;