import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import CakeIcon from "@mui/icons-material/Cake";
import EmailIcon from "@mui/icons-material/Email";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import EditIcon from "@mui/icons-material/Edit";
import { updateProviderResourceDetails } from "../../../../../services/staffingService";

import LocationSearchingIcon from '@mui/icons-material/LocationSearching';

// Validation schema for Details
const detailsSchema = yup.object().shape({
  // address: yup.string().required("Address is required"),
  location: yup.string().required("Your location is required."),
  phoneNumber: yup.string().required("Phone number is required"),
  dateOfBirth: yup.date().required("Date of birth is required"),
  //email: yup.string().email("Invalid email").required("Email is required"),
  emergencyContactName: yup
    .string()
    .required("Emergency contact name is required"),
  emergencyContactPhone: yup
    .string()
    .required("Emergency contact phone is required"),
});

const Details = ({ officerData: initialOfficerData, providerCompanyId }) => {
  
  const [officerData, setOfficerData] = useState(initialOfficerData);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    setValue, 
    formState: { errors },
  } = useForm({
    resolver: yupResolver(detailsSchema),
    defaultValues: {
      location: officerData.formattedLocation,
      phoneNumber: officerData.phoneNumber,
      dateOfBirth: officerData.dateOfBirth,
      //email: officerData.user_id?.email,
      emergencyContactName: officerData.emergencyContact?.name,
      emergencyContactPhone: officerData.emergencyContact?.phone,

        city : officerData.city,
        formatted_address : officerData.formattedLocation,
        latitude : officerData.latitude,
        // location : officerData.formattedLocation,
        longitude : officerData.longitude, 
        state : officerData.state,
        zipcode  : officerData.zipcode
    },
  });

  useEffect(() => {
    setOfficerData(initialOfficerData);
  }, [initialOfficerData]);

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };


  const Autocomplete = ({ value, onPlaceSelected }) => {
    const inputRef = useRef(null);
  
    useEffect(() => {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        componentRestrictions: { country: "us" },
      });
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        onPlaceSelected(place);
      });
    }, [onPlaceSelected]);
  
    return (
      <div className="autocomplete-container">
        <input
          type="text"
          ref={inputRef}
          className="form-control"
          defaultValue={value}  // Set initial value here
        />
        <LocationSearchingIcon className="location-icon" />
      </div>
    );
  };
  
  
  
  const onPlaceSelected = (place) => {
    const addressComponents = place.address_components;
    const locationData = {
      formatted_address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      state: "",
      city: "",
      zipcode: "",
    };
  
    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("administrative_area_level_1")) {
        locationData.state = component.long_name;
      }
      if (types.includes("locality")) {
        locationData.city = component.long_name;
      }
      if (types.includes("postal_code")) {
        locationData.zipcode = component.long_name;
      }
    });
  
    setSelectedLocation(locationData);
    setValue("location", locationData.formatted_address);
  };
  
  const handleCancel = () => {
    setSelectedLocation(null);
    setValue("location", "");
  };

  

  const onSubmit = async (data) => {
    try {
      // Make the API call to update the details
      await updateProviderResourceDetails(providerCompanyId, officerData._id, {
        selectedLocation,
        data
      });


      setOfficerData((prevData) => ({
        ...prevData,
        formattedLocation: selectedLocation?.formatted_address || prevData.formattedLocation,
        city: selectedLocation?.city || prevData.city,
        latitude: selectedLocation?.latitude || prevData.latitude,
        longitude: selectedLocation?.longitude || prevData.longitude,
        state: selectedLocation?.state || prevData.state,
        zipcode: selectedLocation?.zipcode || prevData.zipcode,
        phoneNumber: data.phoneNumber,
        dateOfBirth: data.dateOfBirth,
        emergencyContact: {
          name: data.emergencyContactName,
          phone: data.emergencyContactPhone,
        },
      }))

      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update details:", error);
    }
  };

  function calculateAge(birthDate) {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  }

 
  return (
    <div>
      {!isEditing ? (
        <div className="prepopulated-values-data">
          <p>
            <HomeIcon className="icon" /> Address:{" "}
            {officerData.formattedLocation}
          </p>

          <p>
            <PhoneIcon className="icon" /> Phone:{" "}
            {officerData.phoneNumber ? officerData.phoneNumber : "N/A"}
          </p>

          <p>
            <CakeIcon className="icon" /> Date of Birth:{" "}
            {new Date(officerData.dateOfBirth).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            ({calculateAge(officerData.dateOfBirth)} years old)
          </p>

          <p>
            <EmailIcon className="icon" /> Email:{" "}
            {officerData.user_id ? officerData.user_id.email : "N/A"}
          </p>

          <hr />

          <p>
            <AccountBalanceWalletIcon className="icon" /> Stripe Account:{" "}
            {officerData.stripeAccount ? officerData.stripeAccount.id : "N/A"}
          </p>

          <hr />

          <p>
            <ContactEmergencyIcon className="icon" /> Emergency Contact:{" "}
            {officerData.emergencyContact
              ? `${officerData.emergencyContact.name} (${
                  officerData.emergencyContact.phone
                    ? officerData.emergencyContact.phone
                    : "Phone no. not available"
                })`
              : "N/A"}
          </p>

          <Button
            variant="text"
            startIcon={<EditIcon />}
            onClick={toggleEditMode}
            className="edit-button"
          >
            Edit
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">


            {/* <label className="title">Address</label>
            <input
              type="text"
              {...register("address")}
              className="form-control"
            />
            {errors.address && (
              <span className="errorTextForm">{errors.address.message}</span>
            )}  */}

<label className="title">Address</label>
            {selectedLocation ? (
              <div className="selected-location">
                <span>{selectedLocation.formatted_address}</span>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={handleCancel}
                >
                  <CloseIcon className="staffing-cancel-icon" />
                </button>
              </div>
            ) : (
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    value={field.value || officerData.formattedLocation}
                    onPlaceSelected={(place) => {
                      field.onChange(place.formatted_address);
                      onPlaceSelected(place);
                    }}
                  />
                )}
              />
            )}
            {errors.location && <span className="errorTextForm">{errors.location.message}</span>}

          </div>

          <div className="form-group">
            <label className="title">Phone</label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  defaultCountry="US"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.phoneNumber && (
              <span className="errorTextForm">
                {errors.phoneNumber.message}
              </span>
            )}
          </div>

          <div className="form-group">
            <label className="title">Date of Birth</label>
            <input
              type="date"
              {...register("dateOfBirth")}
              className="form-control"
            />
            {errors.dateOfBirth && (
              <span className="errorTextForm">
                {errors.dateOfBirth.message}
              </span>
            )}
          </div>

          {/* <div className="form-group">
            <label className="title">Email</label>
            <input
              type="email"
              {...register("email")}
              className="form-control"
            />
            {errors.email && (
              <span className="errorTextForm">{errors.email.message}</span>
            )}
          </div> */}

          <div className="form-group">
            <label className="title">Emergency Contact Name</label>
            <input
              type="text"
              {...register("emergencyContactName")}
              className="form-control"
            />
            {errors.emergencyContactName && (
              <span className="errorTextForm">
                {errors.emergencyContactName.message}
              </span>
            )}
          </div>

          <div className="form-group">
            <label className="title">Emergency Contact Phone</label>
            <Controller
              name="emergencyContactPhone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  defaultCountry="US"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.emergencyContactPhone && (
              <span className="errorTextForm">
                {errors.emergencyContactPhone.message}
              </span>
            )}
          </div>

          <button type="submit" className="staffing-btn confirm-btn">
            Save
          </button>
          <button
            type="button"
            className="staffing-btn cancel-btn ml-2"
            onClick={toggleEditMode}
          >
            Cancel
          </button>
        </form>
      )}
    </div>
  );
};

export default Details;
