import React from 'react';
import "./style.css";
import OccupationsChart from './OccupationsChart';

const Occupation = ({ dts }) => {

  const options = {
    chart: {
      height: 280,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: 90,
        hollow: {
          size: "100%",
        },

        dataLabels: {
          name: {
            fontSize: '14px',
          },
          value: {
            fontSize: '14px',
          },
          total: {
            show: true,
            label: Object.entries(dts[0].races).sort((x, y) => y[1] - x[1])[0][0],
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return Object.entries(dts[0].races).sort((x, y) => y[1] - x[1])[0][1]
            }
          }
        }

      }
    }
  }

  return (
    <div className='Occupations-wrapper'>
      <div className='Occupations-block'>
        <div className='Occupations-content'>
          <div className='Occupations-title'>
            <h6 className="fontstyleaxiform"> Employment by Industry Sector </h6>
          </div>
        </div>
        <div className='Occupations-description'>
          <OccupationsChart options={options} dts={dts[0].occupations} />
        </div>
      </div>
    </div>
  )
}

export default Occupation