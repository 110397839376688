import React, { useState } from 'react';
import { Tabs } from 'rsuite';
import { Link } from "react-router-dom";
import '../../../styles/dashboard.css';
import DashboardSidebar from "../../../../common/dashboardSidebar"
import { getJobs } from "../../../../../services/staffingService";
import { useParams } from "react-router-dom";
import "../../../styles/assign1.css"
import Header from '../../../../common/header';

import UnassignedOpenListView from './open/unassignedListView';
import UnassignedOpenGridView from './open/unassignedGridView';

import UnassignedDisputedListView from './disputed/unassignedListView';
import UnassignedDisputedGridView from './disputed/unassignedGridView';

import UnassignedCompletedListView from './completed/unassignedListView';
import UnassignedCompletedGridView from './completed/unassignedGridView';

import Box from '../../../../_asset/img/box.svg';
import FolderPpen from '../../../../_asset/img/folder-open.svg';
import Frame from '../../../../_asset/img/Frame.svg';
import Clock from '../../../../_asset/img/clock.svg';
import moment from 'moment';

const Assign = () => {

  const { providerCompanyId } = useParams();
  const [showPredefined, setShowPredefined] = useState(!false);
  const [isAutoAssign, setIsAutoAssign] = useState(false);
  const [loading, setLoading] = useState(false);


  const [jobs, setJobs] = useState([]);
  const [openJobs, setOpenJobs] = useState([]);
  const [disputedJobs, setDisputedJobs] = useState([]); 
  const [completedJobs, setCompletedJobs] = useState([]);

  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [gridView, setGridView] = useState(1);
  const [showSideDrawer, setShowSideDrawer] = useState(false);


  React.useEffect(() => {
    fetchJobs()
  }, [])

  async function fetchJobs() {
    setLoading(true);
    const resp = await getJobs(providerCompanyId);
    setJobs([...resp.data.jobs]);
    setOpenJobs([...resp.data.jobs.filter(job => job.status === "open")]);
    setDisputedJobs([...resp.data.jobs.filter(job => job.status === "disputed")]);
    setCompletedJobs([...resp.data.jobs.filter(job => job.status === "completed")]);
    setLoading(false);
  }


  const getDeadlineText = (shifts) => {
    if (!shifts || shifts.length === 0) {
        return 'No shifts';
    }

    // Find the first shift start time using correct field name `startTime`
    const firstShift = shifts.reduce((earliest, shift) => {
        return new Date(shift.startTime) < new Date(earliest.startTime) ? shift : earliest;
    });

    // Calculate the deadline (12 hours before the first shift start)
    const now = moment();
    const shiftStart = moment(firstShift.startTime);
    const deadline = shiftStart.subtract(12, 'hours');
    const duration = moment.duration(deadline.diff(now));

    // Format the deadline in a user-friendly, compact way
    if (duration.asDays() >= 1) {
        return `${Math.floor(duration.asDays())}d left`;
    } else if (duration.asHours() >= 1) {
        return `${Math.floor(duration.asHours())}h left`;
    } else if (duration.asMinutes() > 0) {
        return `${Math.floor(duration.asMinutes())}m left`;
    } else {
        return 'Expired';
    }
};


  return <>

    <div className="dashboard">
      <DashboardSidebar isOpen={showSideDrawer} />
      <div className='dashboard_content'>
        <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />

        
        
          <div className="staffing-new-assignments-body" id='scrollBar'>
            <div className='page_title'>
              <div className='left_side'>
                <h2>Jobs</h2>
                <p>Manage work orders</p>
              </div>
              <div className='right_side'>
                <Link to={`/provider-company/${providerCompanyId}/customers`}>                 
                <button className="create-new-job-button">
                  + New Job 
                </button>
                </Link>
              </div>
            </div>

            { jobs.length > 0 && !loading && 

            <div className='staffing-new-assignments_inner'>

              {/* <div className='gridbox_view'>
                <div className='card_view'>
                  <h4>
                    <img src={Box} alt="" /> Open</h4>
                    <h2> { openJobs.length }</h2>
                </div>
                <div className='card_view'>
                  <h4><img src={Frame} alt="" /> Disputed</h4>
                  <h2> { disputedJobs.length } </h2>
                </div>
                <div className='card_view'>
                  <h4><img src={Clock} alt="" /> Completed</h4>
                  <h2> { completedJobs.length } </h2>
                </div>
              </div> */}

              <div className='position-relative'>
                <Tabs defaultActiveKey="1" appearance="subtle" className='tabs_content'>
                  <Tabs.Tab eventKey="1" title={(<> Open <span className={`ml-1 circle-number ${activeCardIndex==0? 'active' : ''}`} > {openJobs.length} </span> </>)}>
                    {
                      gridView === 1 ?
                        <UnassignedOpenListView data={ openJobs } getDeadlineText = {getDeadlineText} /> :
                        <UnassignedOpenGridView data={ openJobs } getDeadlineText = {getDeadlineText} />
                    }
                  </Tabs.Tab>
                  <Tabs.Tab eventKey="2" title={(<> Disputed <span className={`ml-1 circle-number ${activeCardIndex==1? 'active' : ''}`} > {disputedJobs.length} </span> </>)}>
                  {
                      gridView === 1 ?
                        <UnassignedDisputedListView data={ disputedJobs } /> :
                        <UnassignedDisputedGridView data={ disputedJobs } />
                    }
                  </Tabs.Tab>
                  <Tabs.Tab eventKey="3" title={(<> Completed <span className={`ml-1 circle-number ${activeCardIndex==2? 'active' : ''}`} > {completedJobs.length} </span> </>)}>
                   {
                      gridView === 1 ?
                        <UnassignedCompletedListView data={ completedJobs } /> :
                        <UnassignedCompletedGridView data={ completedJobs } />
                    }
                  </Tabs.Tab>
                </Tabs>
                <div className='view_box'>
                  <div className={`box-view ${gridView === 0 ? 'active' : ''}`} onClick={() => setGridView(0)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.49984 18.3332H12.4998C16.6665 18.3332 18.3332 16.6665 18.3332 12.4998V7.49984C18.3332 3.33317 16.6665 1.6665 12.4998 1.6665H7.49984C3.33317 1.6665 1.6665 3.33317 1.6665 7.49984V12.4998C1.6665 16.6665 3.33317 18.3332 7.49984 18.3332Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10 1.6665V18.3332" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M1.6665 10H18.3332" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                  </div>
                  <div className={`box-view ${gridView === 1 ? 'active' : ''}`} onClick={() => setGridView(1)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.5832 11.25H3.4165C2.1665 11.25 1.6665 11.7833 1.6665 13.1083V16.475C1.6665 17.8 2.1665 18.3333 3.4165 18.3333H16.5832C17.8332 18.3333 18.3332 17.8 18.3332 16.475V13.1083C18.3332 11.7833 17.8332 11.25 16.5832 11.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M16.5832 1.6665H3.4165C2.1665 1.6665 1.6665 2.19984 1.6665 3.52484V6.8915C1.6665 8.2165 2.1665 8.74984 3.4165 8.74984H16.5832C17.8332 8.74984 18.3332 8.2165 18.3332 6.8915V3.52484C18.3332 2.19984 17.8332 1.6665 16.5832 1.6665Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                  </div>
                </div>
              </div>

            </div>

            }
          { jobs.length === 0 && !loading && <div className="no-data p-5">No work orders found </div> }

          </div>
        
        
        {loading && <div className="loader">Loading...</div>}

      </div>
    </div>

  </>

}
export default Assign; 