import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast"; // Import react-hot-toast

const TrafficMap = ({ selectedCity }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState(null); // Store geocoder instance

  // Initialize map and geocoder when the component mounts
  useEffect(() => {
    if (window.google && mapRef.current) {
      const googleMap = new window.google.maps.Map(mapRef.current, {
        zoom: 12, // No default center here
      });

      const trafficLayer = new window.google.maps.TrafficLayer();
      trafficLayer.setMap(googleMap);

      setMap(googleMap); // Store map instance
      setGeocoder(new window.google.maps.Geocoder()); // Initialize geocoder
    }
  }, []);

  // Update map when selectedCity changes
  useEffect(() => {
    if (map && geocoder && selectedCity) {
      const location = `${selectedCity.boundary.city}, ${selectedCity.boundary.state}`;
      console.log("Geocoding location: ", location);

      geocoder.geocode({ address: location }, (results, status) => {
        if (status === "OK" && results[0]) {
          const location = results[0].geometry.location;
          console.log("Geocoded coordinates: ", location.lat(), location.lng());

          // Set the map center using lat and lng from the geocoding result
          map.setCenter({ lat: location.lat(), lng: location.lng() });
          map.setZoom(12); // Optional: adjust zoom level for cities
        } else {

          toast.error("Traffic flow data not found for the selected city.");
        }
      });
    }
  }, [map, geocoder, selectedCity]); // Re-run this effect when map, geocoder, or selectedCity changes

  return (
    <div>
      <div
        ref={mapRef}
        style={{ height: "500px", width: "100%", borderRadius: "8px" }}
      ></div>
    </div>
  );
};

export default TrafficMap;
