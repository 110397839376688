import React, { useState } from "react";
import { Button, Modal, SelectPicker, Toggle } from "rsuite";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  inviteUserAsProviderResource
} from "../../../../services/staffingService";

const providerResourceSchema = yup.object({
  providerResourceEmail: yup
    .string()
    .required("Email is required for Provider Resource")
    .email("Must be a valid email address"),
});



const OfficerInvite = ({ open , handleClose , providerCompanyId }) => {


  const [resourceResponse, setResourceResponse] = useState("");
  const [resourceError, setResourceError] = useState(false);


  const {
    register: registerResource,
    handleSubmit: handleSubmitResource,
    formState: { errors: errorsResource },
    reset: resetResource,
  } = useForm({
    resolver: yupResolver(providerResourceSchema),
  });

  const onSubmitProviderResource = async (data) => {
    try {
      const resp = await inviteUserAsProviderResource(
        providerCompanyId,
        data.providerResourceEmail
      );
      setResourceResponse(resp.data);
      setResourceError(false);
    } catch (error) {
      setResourceResponse(error.response.data || "Failed to send invitation");
      setResourceError(true);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className='create_customer_modal_wrapper' style={{ display: "flex", alignItems: "center", height: "calc(100% - 60px)" }}>
            <Modal.Header>
                <Modal.Title>Add Officer</Modal.Title>
                <p>Onboard new officers</p>
            </Modal.Header>
            <Modal.Body>
                    <form
                      onSubmit={handleSubmitResource(onSubmitProviderResource)}
                      className="form-group mt-2"
                    >
                      <div className="d-flex align-items-center mb-2">
                        <input
                          style={{
                            width: "250px",
                            height: "30px",
                            fontSize: "14px",
                          }}
                          type="text"
                          className="form-control mr-2"
                          {...registerResource("providerResourceEmail")}
                          placeholder="Add security personnel email"
                        />
                        <button
                          type="submit"
                          className="btn staffing-button-form-add-user"
                        >
                          +
                        </button>
                      </div>
                      {resourceResponse && (
                        <div
                          className={`${
                            resourceError ? "errorTextForm" : "text-success"
                          }`}
                        >
                          {resourceResponse}
                        </div>
                      )}
                      {errorsResource.providerResourceEmail && (
                        <div className="errorTextForm">
                          {errorsResource.providerResourceEmail.message}
                        </div>
                      )}
                    </form>
            </Modal.Body>
            <Modal.Footer>
                {/* <div className='invite_as'>
                    <p>Invite as:</p>
                    <SelectPicker data={['data', '2']} searchable={false} placeholder={"Dispatch Member"} />
                </div> */}
                {/* <button className='invite_btn ml-auto'>Invite</button> */}
            </Modal.Footer>
        </Modal>
  );
}

export default OfficerInvite;