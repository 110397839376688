import React, { useEffect, useState } from 'react';
import { Link, useParams, Redirect, useHistory, useLocation } from "react-router-dom";
import ListingDetails from './listingitem.jsx';
import RecommendedListingDetails from "./recommendedListingItem.jsx"
import ListingDetailsMap from './listingmap.js';
import ListingSidebar from './listingsidebar.js';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// import { getRecommendation } from '../../../../services/staffingService';
import { getRecommendation } from '../../../../../../services/staffingService';

import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PaidIcon from '@mui/icons-material/Paid';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { Dropdown } from 'rsuite';
import JobStatistics from "./job_statistics/statistics.job";
import '../../../../styles/listing.css'; 
import DashboardSidebar from '../../../../../common/dashboardSidebar.jsx';
import Header from '../../../../../common/header.jsx';
// import jobData from "./job";

const Listing = ({ providerCompanyId, jobId , listViewType , setActiveTab , setSelectedOfferId }) => {

    //const { job, recommendedStaffs } = jobData; // Destructuring the new data
    const [location, setLocation] = useState(useParams().location);
    const [geolevel, setGeolevel] = useState(useParams().geolevel)
    const [staffs, setStaffs] = useState([]) // Using recommended staffs
    const [all, setAll] = useState([]);
    const [job, setJob] = useState({});
    const [hoveredStaff, setHoveredStaff] = useState({})
    const [loading, setLoading] = useState(false); // Adjusting based on new data structure
    const [exception, setException] = useState(false);
    const [positionsFilled, setPositionsFilled] = useState(false);
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const locationParam = useLocation();
    const query = new URLSearchParams(locationParam.search);
    const view = query.get('view');
    // const jobId = useParams().jobId ;

    // const { providerCompanyId, jobId } = useParams();

    // const [listViewType, setListViewType] = useState(view === 'recommended' ? 'recommended' : 'closest');
    
    const history = useHistory();
    /*
    const handleNavigation = (viewType) => {
        setListViewType(viewType);
        history.push(`/provider-company/${providerCompanyId}/jobs/${jobId}?view=${viewType}`);
    };
    */


    useEffect(() => {
        closestStaffs();
    }, []);

    const closestStaffs = async () => {

        // const { data } = await getSecurityStaffsOrderId(orderId) ;
        const { data } = await getRecommendation(providerCompanyId, jobId);

        const staffsWithOffersField = data.staffs.map(staff => ({
            ...staff,
            offerPrice: Math.round(data.job.hourlyPayRate * (1 - 0.35)),
            customerRatePercentage: 35,
            offerId: staff.offerId || null
        }));

        const acceptedOffers = staffsWithOffersField.filter(staff => staff.offer && (staff.offer.status === 'accepted' || staff.offer.status === 'completed' || staff.offer.status === 'paid'));
        const totalStaffsRequired = data.job.staffsQty;

        if (acceptedOffers.length >= totalStaffsRequired) {
            setStaffs(acceptedOffers);
            setAll([...acceptedOffers]);
            setJob({ ...data.job });
            setPositionsFilled(true);

            return;
        } else {
            setStaffs(staffsWithOffersField);
            setAll([...staffsWithOffersField]);
            setJob({ ...data.job });
        }
    }

    if (exception) return <Redirect to="/404" />;

    return (
        <div className="main_layout_content">
            {/* <DashboardSidebar isOpen={showSideDrawer} /> */}
            <div className='main_layout_content_inner'>
                {/* <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} /> */}
                <div className='send_offer_page' id='scrollBar'>
                    {/* <div className='page_title'>
                        <div className='left_side'>
                            <h2>Officer Recommendations</h2>
                            <p>Send offers to the available officers</p>
                        </div>
                        all offers 
                         <JobStatistics providerCompanyId={providerCompanyId} jobId={job._id} statistics={job.statistics} />
                        shift calendar
                    </div> */}

                    {
                        staffs.length >= 1 && Object.keys(job).length > 0 &&
                        <>
                            {
                                positionsFilled === true &&
                                <div class="sticky-header-message text-center">
                                    <div class="alert alert alert-danger p-2" role="alert" style={{ marginBottom: '0px' }}>
                                        <span>
                                            All positions are currently filled. No further offers can be sent at this time.
                                        </span>
                                    </div>
                                </div>
                            }
                            {/* <JobStatistics providerCompanyId={providerCompanyId} jobId={job._id} statistics={job.statistics} /> */}
                            <div id="listing-billboard">
                                <div className='listing-section' id='scrollBar'>
                                    {
                                        listViewType === 'closest' &&
                                        <div className="custom-container">
                                            <div className="custom-row mt-2">
                                                <div className='sideBar'>
                                                    <ListingSidebar staffs={staffs} all={all} setStaffs={setStaffs} />
                                                </div>
                                                <div className='listing-content'>
                                                    <ListingDetails providerCompanyId={providerCompanyId} staffs={staffs} setStaffs={setStaffs} job={job} hoveredStaff={hoveredStaff} setHoveredStaff={setHoveredStaff} />
                                                    <ListingDetailsMap staffs={staffs} job={job} hoveredStaff={hoveredStaff} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        listViewType === 'recommended' &&
                                        <div className="custom-container">
                                            <div className="custom-row">
                                                <div className='listing-content d-block'>
                                                    <div className="listing-section-header text-center">
                                                        <div className="sort_by align-items-center" >
                                                            
                                                            <div className='sort_by_item'><span> <LocationOnIcon style={{ fontSize: '18px' }} /> </span> {job.formattedAddress}</div>
                                                            {<div className='sort_by_item ml-3'><span> <LocalPoliceIcon style={{ fontSize: '18px' }} /> </span> <b>{staffs.length} officers</b> within 15 miles </div>}
                                                            {<div className='sort_by_item ml-3'><span> <PaidIcon style={{ fontSize: '18px' }} /> </span> <b>${job.hourlyPayRate}</b> per hour</div>}
                                                              <div className="sort_by_item ml-auto"> 
                                                              <JobStatistics providerCompanyId={providerCompanyId} jobId={job._id} statistics={job.statistics} />
                                                              </div>                                                         
                                                            {/* <div className="" style={{ marginRight: '35%' }}> */}
                                                                {/* uncomment below to show different listing types only supporting recommendations for now  */}
                                                                {/* <Dropdown title={<span style={{fontWeight: 500, fontSize: '13px', color: '#828282'}}> { listViewType.charAt(0).toUpperCase() + listViewType.slice(1) } </span>}>
                                                                        { listViewType !== 'closest' && (
                                                                            <Dropdown.Item onClick={() => handleNavigation('closest')}>
                                                                                <span style={{fontWeight: 500, fontSize: '13px', color: '#828282!important'}}> Closest </span>
                                                                            </Dropdown.Item>
                                                                        )}
                                                                        { listViewType !== 'recommended' && (
                                                                            <Dropdown.Item onClick={() => handleNavigation('recommended')} style={{fontWeight: 500, fontSize: '13px', color: '#828282'}}>
                                                                            Recommended
                                                                            </Dropdown.Item>
                                                                        )}
                                                                    </Dropdown> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                    <RecommendedListingDetails className="ml-2" providerCompanyId={providerCompanyId} staffs={staffs} setStaffs={setStaffs} job={job} hoveredStaff={hoveredStaff} setHoveredStaff={setHoveredStaff} setSelectedOfferId={setSelectedOfferId} setActiveTab={setActiveTab} />
                                                </div>
                                                <ListingDetailsMap staffs={staffs} job={job} hoveredStaff={hoveredStaff} />
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </>
                    }
                    {
                        staffs.length === 0 && Object.keys(job).length > 0 && <div className=""> No staffs found </div>
                    }
                    {loading && staffs.length === 0 && <div>Loading...</div>}
                </div>
            </div>
        </div>
    );
}

export default Listing;
