import React, { useEffect, useState, useRef } from "react";

import { useParams, useHistory } from "react-router-dom";
import DOMPurify from "dompurify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getCopilotMultimodalStream } from "../../../../../services/staffingService";
import gfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
// import conversationHistory from "./conversationHistory.json";
// import HandsOnTable from "./handsOnTable";

// import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import AssistantIcon from "@mui/icons-material/Assistant";
import { Editor } from "@tinymce/tinymce-react";
import { Breadcrumb } from "rsuite";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";

import Send from "../../../../_asset/img/send-2.svg";
// import Rectangle from "../../../../_asset/img/Rectangle.svg";
import { ReactComponent as Magicpen } from "../../../../_asset/img/magicpen.svg";
import Paperclip from "../../../../_asset/img/paperclip-2.png";
import { Drawer, Tabs, Toggle } from "rsuite";

import "../styles/askAI.css";


const schema = yup
  .object({
    prompt: yup
      .string()
      .required("Prompt is required")
      .max(3200, "Max length exceeded"),
  })
  .required();

const Copilot = () => {
  const [loading, setLoading] = useState(false);

  const [conversation, setConversation] = useState([]);

  const [prompt, setPrompt] = useState("");
  const [completion, setCompletion] = useState("");
  const [completionLoading, setCompletionLoading] = useState(false);
  const [completionError, setCompletionError] = useState(true);

  const [showFullscreen, setShowfullscreen] = useState(false);

  const [completionSettingsExists, setCompletionSettingsExists] = useState(false);
  const [fileId, setFileId] = useState("");
  const [sourceBlob, setSourceBlob] = useState("");
  const [asset, setAsset] = useState("");
  const [assetSaved, setAssetSaved] = useState(false);
  const [err, setErr] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastwords, setLastwords] = useState("");
  const [df, setDf] = useState([]);

  const chatContainerRef = useRef(null);
  const chatEndRef = useRef(null);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const editorRef = useRef(null);

  const hotRef = React.useRef(null);

  useEffect(() => {
    /*
    const fetchModelDetails = async () => {
      try {
        setLoading(true);
        let resp2 = conversationHistory;
        let convHistory = [];
        resp2.data.conv.forEach((item) => {
          item.choices.forEach((choice) => {
            convHistory.push(choice.message);
          });
        });
        setConversation([...convHistory]);
        setAsset(resp2.data.asset.content);
        setPrompt("resp_card.data.prompt");
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          history.push("/404");
        }
      }
    };
    */
    //fetchModelDetails();
  }, [history]);

  useEffect(() => {
    const codeElements = document.querySelectorAll(
      '.junior-analyst-chatBox .junior-analyst-msg p > pre > code[class^="language-"]'
    );
    codeElements.forEach((el) => {
      const langMatch = el.className.match(/language-(\w+)/);
      if (langMatch && langMatch[1]) {
        el.parentElement.setAttribute("data-lang", langMatch[1]);
      }
    });
  }, [conversation]);

  const onSubmit = async (data) => {
    console.log("submitted +++++++ prompt +++++ ", data);
    setErr("");
    setCompletionLoading(true);
    try {
      setConversation((prevConversation) => [
        ...prevConversation,
        // { role: 'user', content: formatRange(selectedRange[0]) , range: selectedRange , menu: menu, submenu:(submenu?submenu:prompt)}
        { role: "user", content: data.prompt },
      ]);
      const resp = await getCopilotMultimodalStream(data.prompt);

      /*
        if (!resp.ok || !resp.body) {

            console.log('resp in conversation history ++++ ', resp);
            setCompletionError(true);
            setConversation((prevConversation) => [
            ...prevConversation,
            { role: "assistant", content: resp.statusText, err: true },
            ]);
            reset();
            setCompletionLoading(false);
            throw resp.statusText;
        }
      */

     if (!resp.ok || !resp.body) {
        setCompletionError(true);
        setConversation((prevConversation) => [
            ...prevConversation,
            { role: "assistant", content: resp.data.errmessage, err: true },
        ]);
        setCompletionLoading(false);
        reset();
        throw resp.statusText;
       }

      const reader = resp.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;
      let result = "";

      while (loopRunner) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        let compl = decodedChunk;
        result += compl;

        setLastwords(result);
        reset();
      }
      setLastwords("");
      setCompletion(result);
      setPrompt(data.prompt);
      setCompletionLoading(false);
      setConversation((prevConversation) => [
        ...prevConversation,
        { role: "assistant", content: result },
      ]);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        setErr(ex.response.data);
      }
      setCompletionLoading(false);
      //setHighlightedRanges([])
    }
  };

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView();
    }
  }, [conversation, lastwords]);

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const handleFullscreen = (content) => {
    const serializedConversation = encodeURIComponent(JSON.stringify(content));
    // const serializedConversation = encodeURIComponent(JSON.stringify(conversation));
    // window.open(`/chatbox/fullscreen?conversation=${serializedConversation}`, '_blank');
    window.open(
      `/copilot/junior-analyst/response/full-screen?conversation=${serializedConversation}`,
      "_blank"
    );
  };

  return  <>
      <div className="tabs__content" id="scrollBar">
        <div className="no_data_found" style={{width: conversation.length > 0 ? '100%' :  '62%'}}>
          
          {!conversation.length && (
            <>
              <div className="magicpen_icon">
                <Magicpen />
              </div>
              <p className="description">
                Ask me anything about the service level agreement with this officer.
              </p>
            </>
          )}

          { conversation.length > 0 && (
            <div className="junior-analyst-chatBox">
              {conversation.map((item, index) => (
                <React.Fragment key={index}>
                  {item.role === "user" && (
                    <div className="junior-analyst-msg junior-analyst-msgadd mt-2">
                      {/* <Breadcrumb style={{ marginBottom: "5px" }}>
                        <Breadcrumb.Item> {item.menu}</Breadcrumb.Item>
                        <Breadcrumb.Item> {item.submenu}</Breadcrumb.Item>
                        <div className="mt-1">
                          <p>Range - {item.content}</p>
                          </div>
                        </Breadcrumb> */}
                            <p>{item.content}</p>
                        
                    </div>
                  )}
                  {item.role === "assistant" && !item.err && (
                    <div className="junior-analyst-msg junior-analyst-msgin">
                      <AssistantIcon
                        style={{
                          color: "#49527f",
                          fontSize: "35px",
                          marginRight: "14px",
                        }}
                      />
                      <div className="content">
                        <p>
                          <ReactMarkdown
                            children={item.content}
                            remarkPlugins={[gfm]}
                          />
                        </p>
                      </div>
                      <button
                        type="button"
                        /* onClick={()=>{setShowfullscreen(true)}}*/ onClick={() => {
                          handleFullscreen(item.content);
                        }}
                        className="show-on-spreadsheet button-one"
                        style={{ left: "7%" }}
                      >
                        {" "}
                        <FullscreenIcon
                          style={{ fontSize: "16px", color: "#293056" }}
                        />{" "}
                      </button>
                      <button
                        type="button"
                        className="show-on-spreadsheet button-two ml-2"
                        style={{ left: "17%" }}
                      >
                        {" "}
                        <ContentCopyIcon
                          style={{ fontSize: "14px", color: "#293056" }}
                        />{" "}
                      </button>
                    </div>
                  )}
                  {item.role === "assistant" && item.err && (
                    <div className="text-center mb-2">
                      <p className="errorTextForm">{item.content }</p>
                    </div>
                  )}
                </React.Fragment>
              ))}
              {lastwords && (
                <div className="junior-analyst-msg junior-analyst-msgin">
                  <AssistantIcon
                    style={{
                      color: "#1470B7",
                      fontSize: "40px",
                      marginRight: "14px",
                    }}
                  />
                  <div>
                    <p>
                      <ReactMarkdown
                        children={lastwords}
                        remarkPlugins={[gfm]}
                      />
                    </p>
                  </div>
                </div>
              )}
              <div ref={chatEndRef}></div>
            </div>
          )}
        </div>
        
        {
            !completionLoading && 

            <form onSubmit={handleSubmit(onSubmit)} className="mt-1">
            <div className="comment__content">
                {/* <div className="agreement">
                  <p>Service Level Agreement</p>
                  <Toggle />
                </div> */}
                <div className="send_comment">
                <textarea
                    type="text"
                    {...register("prompt")}
                    rows={3}
                    placeholder="Ask AI"
                ></textarea>
                {errors.prompt && (
                    <span className="errorTextForm mt-1">
                    {" "}
                    {errors.prompt.message}{" "}
                    </span>
                )}
                <div className="comment_btn">
                    {/* <img src={Paperclip} alt="" /> */}
                    <button className="btn ml-auto">Ask</button>
                </div>
                </div>
            </div>
            </form>
        }

        {
            completionLoading && <div className="text-center mt-4 align-items-center">
                <div class="spinner-grow spinner-grow-sm text-primary mr-3 " role="status"> <span class="sr-only"></span> </div>
                <div class="spinner-grow spinner-grow-sm text-primary mr-3" role="status"> <span class="sr-only"></span> </div>
                <div class="spinner-grow spinner-grow-sm text-primary" role="status"> <span class="sr-only"></span> </div>
            </div>
        }

      </div>
    </>
  
};

export default Copilot;
