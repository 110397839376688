import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from 'rsuite';
import './styles/staffing-main.css'; // Importing the CSS file
import policeman from "./icons/policeman.png";
import manager from "./icons/project-manager.png";
import toast from 'react-hot-toast';
import { createStaffAllocationCompany, getUserProviderManagers, getUserProviderResources } from '../../services/staffingService';
import AddIcon from '@mui/icons-material/Add';
import { useUser } from '../../context/UserContext';

const MainView = () => {
  const [providerCompanies, setProviderCompanies] = useState([]);
  const [providerResources, setProviderResources] = useState([]);
  const { userInfo, userInfoReady } = useUser();

  useEffect(() => {
    getProviderCompaniesAll();
  }, []);

  const getProviderCompaniesAll = async () => {
    try {
      const resp = await getUserProviderManagers();
      setProviderCompanies([...resp.data]);
      const resp2 = await getUserProviderResources();
      setProviderResources([...resp2.data]);
    } catch (error) {
      toast.error('Failed to fetch provider data');
      console.error('Error fetching provider data:', error);
    }
  };

  return (
    <div className="staffing-main">
      <div className="staffing-button-container">

        {/* Display Provider Companies */}
        {providerCompanies.length >= 1 && providerCompanies.map((providerCompany, index) => {
          return (
            <div className="staffing-button-block" key={index}>
              <img className="staffing-icon" src={manager} alt="Provider" />
              <h5 className="staffing-title">Dispatch Manager</h5>
              <p className="staffing-description">
                {/* Display the company name and formatted location */}
                {providerCompany.provider_company_id?.name || 'Company Name N/A'}
                <br />
                {providerCompany.provider_company_id?.formatted_location || 'Location N/A'}
              </p>
              <hr />
              <Link to={`/provider-company/${providerCompany.provider_company_id._id}/jobs`} className="staffing-button">
                <Avatar className="avatar" size="xs" circle src={userInfo.profilePictureUrl} />
                Manage Work Orders
              </Link>
            </div>
          );
        })}

        {/* Start New Company Link */}
        <div className="custom-support-section text-center">
          <div style={{ color: '#ffffff', width: '100px' }}>
            <Link to={`/provider-company/new`} style={{ color: 'inherit' }}>
              <strong>Start New +</strong>
            </Link>
          </div>
        </div>

        {/* Display Provider Resources */}
        {providerResources.length >= 1 && providerResources.map((providerResource, index) => {
          return (
            <div className="staffing-button-block" key={index}>
              <img className="staffing-icon" src={policeman} alt="Staff" />
              <h5 className="staffing-title">Officer</h5>
              <p className="staffing-description">
                {/* Display the company name and formatted location for resources */}
                {providerResource.provider_company_id?.name || 'Company Name N/A'}
                <br />
                {providerResource.provider_company_id?.formatted_location || 'Location N/A'}
              </p>
              <hr />
              <Link to={`/provider-resource/${providerResource.provider_company_id._id}/dashboard/work-order/open`} className="staffing-button">
                <Avatar className="avatar" size="xs" circle src={userInfo.profilePictureUrl} />
                Accept Assignments
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MainView;
