import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './RulesEngineDisputeTable.css';

const DynamicTable = ({ rows, setRows }) => {
    useEffect(() => {
        console.log("Initial rows:", rows); // Confirm initial rows and values
    }, [rows]);

    const handleAddRow = () => {
        setRows([
            ...rows,
            { statusCode: 5, definition: '', editable: true } // Default values for a new row
        ]);
    };

    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleFieldChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = field === 'statusCode' ? parseInt(value) : value;
        setRows(updatedRows);
    };

    // Function to get available status codes for each row's dropdown
    const getAvailableStatusCodes = (currentCode) => {
        const existingCodes = rows.map((row) => row.statusCode);
        return Array.from({ length: 20 }, (_, i) => i + 1).filter(
            (code) => !existingCodes.includes(code) || code === currentCode
        );
    };

    return (
        <div className="rules-engine-dispute-form">
            <table className="rules-engine-dispute-table">
                <thead>
                    <tr className="rules-engine-dispute-header-row">

                        <th className="rules-engine-dispute-header-cell">Definition</th>
                        <th className="rules-engine-dispute-header-cell">Status Code</th>
                        <th className="rules-engine-dispute-header-cell"></th>
                    </tr>
                </thead>
                <tbody className="rules-engine-dispute-body">
                    {rows.map((row, index) => (
                        <tr key={index} className="rules-engine-dispute-row">

                            <td className="rules-engine-dispute-cell">
                                <input
                                    type="text"
                                    style={{width:'400px'}}
                                    value={row.definition}
                                    onChange={(e) => handleFieldChange(index, 'definition', e.target.value)}
                                    readOnly={!row.editable}
                                    className="rules-engine-dispute-input"
                                />
                            </td>
                            <td  className="rules-engine-dispute-cell">
                                <select
                                    value={row.statusCode}
                                    onChange={(e) => handleFieldChange(index, 'statusCode', e.target.value)}
                                    disabled={!row.editable}
                                    className="rules-engine-dispute-select"
                                >
                                    {getAvailableStatusCodes(row.statusCode).map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td className="rules-engine-dispute-cell">
                                {row.editable && (
                                    <IconButton onClick={() => handleRemoveRow(index)} className="rules-engine-dispute-icon-button">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="rules-engine-dispute-add-row">
                <IconButton onClick={handleAddRow} className="rules-engine-dispute-add-icon">
                    <AddCircleOutlineIcon fontSize="small" />
                </IconButton>
            </div>
        </div>
    );
};

export default DynamicTable;
