import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

const DocumentDrop = ({ setDocumentFile }) => {
  const [file, setFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
      setDocumentFile(acceptedFiles[0]); // Set the document file to be used in the form submission
    }
  };

  const removeFile = () => {
    setFile(null);
    setDocumentFile(null); // Reset the document file
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "application/pdf",
  });

  return (
    <div
      {...getRootProps()}
      className={`document-drop-container ${isDragActive ? "drag-over" : ""}`}
    >
      <label className="title">Service Level Agreement</label>
      <input {...getInputProps()} />
      <div className="drop-zone">
        {file ? (
          <div className="file-info">
            <p>Uploaded: {file.name}</p>
            <button className="remove-button" onClick={removeFile}>
              &times;
            </button>
          </div>
        ) : (
          <p>Drag & drop a PDF document here, or click to select one</p>
        )}
      </div>
    </div>
  );
};

export default DocumentDrop;

