import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Avatar } from "rsuite";
import { logout } from "../../services/userService";
import { useUser } from "../../context/UserContext";
import { getUserProviderManagers } from "../../services/staffingService";

import { ReactComponent as Briefcase } from "../_asset/img/briefcase.svg";
import { ReactComponent as Chart } from "../_asset/img/chart.svg";
import { ReactComponent as ProfileUser } from "../_asset/img/profile-2user.svg";
import { ReactComponent as SecuritySafe } from "../_asset/img/security-safe.svg";
import { ReactComponent as People } from "../_asset/img/people.svg";
import { ReactComponent as Key } from "../_asset/img/key.svg";
import { ReactComponent as Setting } from "../_asset/img/setting-2.svg";
import { ReactComponent as Logout } from "../_asset/img/logout.svg";

const Sidebar = ({ isOpen }) => {
  const location = useLocation();
  const { userInfo } = useUser();
  const [providerCompanies, setProviderCompanies] = useState([]);
  const providerCompanyId = useParams().providerCompanyId;

  useEffect(() => {
    fetchProviderCompanies();
  }, []);

  const fetchProviderCompanies = async () => {
    const resp = await getUserProviderManagers();
    setProviderCompanies([...resp.data]);
  };

  const isActive = (path) => location.pathname.includes(path);

  const initiateLogout = async () => {
    const resp = await logout();
    window.location.href = resp.data.logoutUrl;
  };

  const getCurrentCompanyDetails = (providerCompanies, providerCompanyId) => {
    const currentCompany = providerCompanies.find(
      (providerCompany) =>
        providerCompany.provider_company_id._id === providerCompanyId
    );
    if (!currentCompany) return { name: "Select a Company", avatarInitial: "S" };

    const companyName = currentCompany.provider_company_id?.legal?.name || "N/A";
    const avatarInitial = companyName.charAt(0).toUpperCase();

    return { name: companyName, avatarInitial };
  };

  const { name, avatarInitial } = getCurrentCompanyDetails(
    providerCompanies,
    providerCompanyId
  );

  return (
    <div className={`sidebar ${isOpen ? "active" : ""}`}>
      <div
        className="sidebar-header"
        style={{ textAlign: "center", padding: "24px", borderBottom: "1px solid #E4E4E4" }}
      >
        <Link to="/home" className="profile" style={{ display: "flex", alignItems: "center", gap: "8px", width: "100%", textDecoration: "none" }}>
          <Avatar style={{ backgroundColor: "#CC457D", color: "#fff", border: "1px solid #E4E4E4" }}>
            {avatarInitial}
          </Avatar>
          <div className="user_details" style={{ transition: "all 0.3s" }}>
            <p className="name" style={{ fontWeight: 600, margin: 0, color: "#000000" , fontSize:"small"}}>{name}</p>
            <span className="roll" style={{ fontSize: "12px", fontWeight: 400, color: "#878787", textAlign: "left" }}>Click to switch organization</span>
          </div>
        </Link>
      </div>

      <div className="navbar_wrapper" id="scrollBar" style={{ padding: "24px", height: "calc(100vh - 94px)", display: "flex", flexDirection: "column", justifyContent: "space-between", overflowY: "auto" }}>
        <div className="navbar_inner" style={{ marginBottom: "24px" }}>
          <h4 className="title" style={{ fontSize: "12px", fontWeight: 500, marginBottom: "8px", color: "#878787", transition: "all 0.3s" }}>MAIN MENU</h4>
          <nav className="sidebar-nav" style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Link
              to={`/provider-company/${providerCompanyId}/jobs`}
              className={`${isActive("jobs") ? "active" : ""}`}
              style={{ color: "#68686A", padding: "12px", borderRadius: "8px", display: "flex", alignItems: "center", fontSize: "14px", gap: "8px", fontWeight: 500, textDecoration: "none" }}
            >
              <Briefcase />
              <span>Jobs</span>
            </Link>
            <Link
              to={`/provider-company/${providerCompanyId}/customers`}
              className={`${isActive("customers") ? "active" : ""}`}
              style={{ color: "#68686A", padding: "12px", borderRadius: "8px", display: "flex", alignItems: "center", fontSize: "14px", gap: "8px", fontWeight: 500, textDecoration: "none" }}
            >
              <ProfileUser />
              <span>Customers</span>
            </Link>
            <Link
              to={`/provider-company/${providerCompanyId}/officers`}
              className={`${isActive("officers") ? "active" : ""}`}
              style={{ color: "#68686A", padding: "12px", borderRadius: "8px", display: "flex", alignItems: "center", fontSize: "14px", gap: "8px", fontWeight: 500, textDecoration: "none" }}
            >
              <SecuritySafe />
              <span>Officers</span>
            </Link>
            <Link
              to={`/provider-company/${providerCompanyId}/signal`}
              className={`${isActive("signal") ? "active" : ""}`}
              style={{ color: "#68686A", padding: "12px", borderRadius: "8px", display: "flex", alignItems: "center", fontSize: "14px", gap: "8px", fontWeight: 500, textDecoration: "none" }}
            >
              <Chart />
              <span>Signals</span>
            </Link>
            <Link
              to={`/provider-company/${providerCompanyId}/team`}
              className={`${isActive("team") ? "active" : ""}`}
              style={{ color: "#68686A", padding: "12px", borderRadius: "8px", display: "flex", alignItems: "center", fontSize: "14px", gap: "8px", fontWeight: 500, textDecoration: "none" }}
            >
              <People />
              <span>Team</span>
            </Link>
            <Link
              to={`/provider-company/${providerCompanyId}/dashboard/account`}
              className={`${isActive("account") ? "active" : ""}`}
              style={{ color: "#68686A", padding: "12px", borderRadius: "8px", display: "flex", alignItems: "center", fontSize: "14px", gap: "8px", fontWeight: 500, textDecoration: "none" }}
            >
              <Key />
              <span>Account</span>
            </Link>
          </nav>
        </div>

        <div className="navbar_inner">
          <h4 className="title" style={{ fontSize: "12px", fontWeight: 500, marginBottom: "8px", color: "#878787", transition: "all 0.3s" }}>OTHERS</h4>
          <nav className="sidebar-nav" style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Link
              to={`/provider-company/${providerCompanyId}/settings`}
              className={`${isActive("settings") ? "active" : ""}`}
              style={{ color: "#68686A", padding: "12px", borderRadius: "8px", display: "flex", alignItems: "center", fontSize: "14px", gap: "8px", fontWeight: 500, textDecoration: "none" }}
            >
              <Setting />
              {/* <span>Settings</span> */}
              <span>Admin Console</span>
            </Link>
            <Link
              to={`/`}
              onClick={initiateLogout}
              className={`logout ${isActive("logout") ? "active" : ""}`}
              style={{ color: "#FF5C57", padding: "12px", borderRadius: "8px", display: "flex", alignItems: "center", fontSize: "14px", gap: "8px", fontWeight: 500, textDecoration: "none" }}
            >
              <Logout />
              <span>Logout</span>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
