import React, { useState, useEffect } from "react";
import Demographics from "./demographics/demography";
import Traffic from "./traffic";
import Events from "./events.city";
import DashboardSidebar from "../../../common/dashboardSidebar";
import Header from "../../../common/header";
import { SelectPicker , Button , Loader } from "rsuite";

import {
  getUSBoundaries,
  getCityDemographics,
} from "../../../../services/staffingService";

import "./signal.css";

const Signal = () => {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("demographics");
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [cities, setCities] = useState([]);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [demographics, setDemographics] = useState([]);
  const [loader, setLoader] = useState(false);

  // Function to switch tabs
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    fetchBoundaries();
  }, []);

  const fetchBoundaries = async () => {
    const resp = await getUSBoundaries();
    const _cities = resp.data.map((boundary) => ({
      label: boundary.city,
      value: boundary.city.toLowerCase().replace(/\s/g, "-"),
      boundary: boundary  
    }));
    setCities([..._cities]);

    if (_cities.length > 0) {
        setSelectedCity(_cities[0]); 
        const resp= await getCityDemographics(_cities[0].boundary.city_key , _cities[0].boundary.state_key );
        setDemographics([...resp.data]);
    }
};


const handleSelectCity = async (value, event) => {
    setLoader(true);
    const selectedCityObj = cities.find(city => city.value === value);
    setSelectedCity(selectedCityObj); 
    const resp = await getCityDemographics(selectedCityObj.boundary.city_key , selectedCityObj.boundary.state_key );
    setDemographics([...resp.data]);
    setShowSaveButton(true);
    setLoader(false);
};

/*
const handleSave = async () => {
    if (selectedCity) {
        const resp = await getCityDemographics(selectedCity.boundary.city_key , selectedCity.boundary.state_key );
        setDemographics([...resp.data]);
        setShowSaveButton(false); 
    }
};
*/

const handleClearManager = () => {
    setSelectedCity(null);
    setShowSaveButton(false); 
};

return (
    <div className="dashboard">
      <DashboardSidebar isOpen={showSideDrawer} />
      <div className="signal_page_wraper">
        <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />
        <div
          className={`staffing-new-assignments-body ${
            showSideDrawer ? "active_sideBar" : ""
          }`}
          id="scrollBar"
        >
        
          <div className="page_title">
            <div className="left_side">
              <h2>Signals</h2>
              <p>
                {" "}
                Get local updates for your city -
                {
                    cities.length && 
                    <SelectPicker
                    className="ml-2"
                    value={selectedCity?.value}  
                    data={cities}
                    onSelect={handleSelectCity}
                    // onClean={handleClearManager}
                    cleanable={false}
                    />
                }
              </p>
            </div>
            <div className="right_side">
              {/* <button className='create__btn'>+ Invite</button> */}
            </div>
          </div>

          <div className="signal_inner">
            <div className="tabs_content_wraper">
              {/* Tabs */}
              <div
                className={`tab_item ${
                  activeTab === "demographics" ? "active" : ""
                }`}
                onClick={() => handleTabClick("demographics")}
              >
                Demographics
              </div>
              <div
                className={`tab_item ${
                  activeTab === "traffic" ? "active" : ""
                }`}
                onClick={() => handleTabClick("traffic")}
              >
                Traffic Flow
              </div>
              <div
                className={`tab_item ${activeTab === "events" ? "active" : ""}`}
                onClick={() => handleTabClick("events")}
              >
                Events
              </div>
            </div>

     
            {!loader &&             
            <div className="tabs_inner_content">
              {activeTab === "demographics" && demographics.length >=1 &&  <Demographics demographics={demographics } />}
              {activeTab === "traffic" && (
                <div>
                  {" "}
                  <Traffic selectedCity={selectedCity}/>{" "}
                </div>
              )}
              {activeTab === "events" && (
                <div>
                  {" "}
                  <Events selectedCity={selectedCity} />{" "}
                </div>
              )}
            </div>
            
            }

            { loader &&  <Loader center content="loading" />}



          </div>
        </div>
      </div>
    </div>
  );
};

export default Signal;
