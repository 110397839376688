import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  addFileToCustomerCompany,
  removeFileFromCustomerCompany,
} from "../../../../../services/staffingService";

const DocumentsTab = ({
  companyData,
  providerCompanyId,
  customerCompanies,
  setCustomerCompanies,
}) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [documentType, setDocumentType] = useState(""); // New state for document type
  const [isSaved, setIsSaved] = useState(false); // New state to track if the file is saved

  // Document types for dropdown
  const documentTypes = ["Service Agreement", "Facility Layout", "Other"];

  // Helper to update parent customerCompanies state after changes
  const updateCustomerCompaniesState = (updatedCompanyData) => {
    const updatedCompanies = customerCompanies.map((company) =>
      company._id === updatedCompanyData._id ? updatedCompanyData : company
    );
    setCustomerCompanies(updatedCompanies);
  };


  const handleRemoveExistingFile = async (fileId) => {
    try {
      // Call backend to remove the file by its _id
      await removeFileFromCustomerCompany(
        providerCompanyId,
        companyData._id,
        fileId
      );

      const updatedCompanyData = {
        ...companyData,
        files: companyData.files.filter((file) => file._id !== fileId), // Only remove the file with the matching _id
      };

      updateCustomerCompaniesState(updatedCompanyData);
    } catch (error) {
      console.error("Error removing file:", error);
    }
  };

  const handleRemoveUploadedFile = () => {
    setUploadedFile(null);
    setDocumentType("");
  };

  // Dropzone configuration for file upload
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setUploadedFile(acceptedFiles[0]);
        setIsSaved(false);
      }
    },
    accept: "application/pdf",
  });

  // Function to handle saving the uploaded file
  const handleSave = async () => {
    if (uploadedFile && documentType) {
      try {
        await addFileToCustomerCompany(
          providerCompanyId,
          companyData._id,
          uploadedFile,
          documentType
        );

        const newFile = {
          fileType: documentType,
          fileName: uploadedFile.name,
          fileUrl: URL.createObjectURL(uploadedFile), // Replace with actual URL from API after upload
          uploadDate: new Date(),
        };

        const updatedCompanyData = {
          ...companyData,
          files: [...companyData.files, newFile],
        };

        // Update customerCompanies state in parent component
        updateCustomerCompaniesState(updatedCompanyData);
        // setUploadedFile(null);
        // setIsSaved(true);

        setUploadedFile(null);
        setDocumentType("");

        console.log("File successfully uploaded");
      } catch (error) {
        console.error("Error saving file:", error);
      }
    } else {
      console.log("No file or document type selected");
    }
  };

  return (
    <div className="tabs__content" id="scrollBar" style={{ padding: "20px" }}>
      <div>
        {companyData.files && companyData.files.length > 0 && (
          <div className="existing-files-list" style={{ marginBottom: "20px" }}>
            {/* {companyData.files.map((file, index) => (
              <div
                key={index}
                className="existing-file"
                style={{ marginBottom: "10px" }}
              >
                <a
                  href={file.fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#007BFF",
                    fontWeight: "bold",
                  }}
                >
                  {file.fileType} - {file.fileName}
                </a>
                <DeleteIcon
                  onClick={() => handleRemoveExistingFile(file.fileUrl)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    color: "#FF4D4F",
                    verticalAlign: "middle",
                  }}
                />
              </div>
            ))} */}

            {companyData.files.map((file) => (
              <div
                key={file._id}
                className="existing-file"
                style={{ marginBottom: "10px" }}
              >
                <a
                  href={file.fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#007BFF",
                    fontWeight: "bold",
                  }}
                >
                  {file.fileType} - {file.fileName}
                </a>
                <DeleteIcon
                  onClick={() => handleRemoveExistingFile(file._id)} // Use the file _id for deletion
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    color: "#FF4D4F",
                    verticalAlign: "middle",
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {companyData.files && companyData.files.length === 0 && (
          <p>No files uploaded yet.</p>
        )}
      </div>

      {/* Drag and drop section */}
      {!uploadedFile && !isSaved && (
        <div
          {...getRootProps()}
          className="dropzone mt-5"
          style={{
            border: "2px dashed #007BFF",
            borderRadius: "8px",
            padding: "40px",
            textAlign: "center",
            color: "#007BFF",
            backgroundColor: "#f9f9f9",
            cursor: "pointer",
            transition: "border 0.3s ease-in-out",
          }}
        >
          <input {...getInputProps()} />
          <p style={{ margin: "0", fontSize: "16px" }}>
            Drag and drop a document here, or click to select one.
          </p>
        </div>
      )}

      {uploadedFile && (
        <div
          style={{
            marginTop: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: "16px",
                color: "#333",
                fontWeight: "bold",
              }}
            >
              {uploadedFile.name}
            </p>
            <DeleteIcon
              onClick={handleRemoveUploadedFile}
              style={{
                cursor: "pointer",
                color: "#FF4D4F",
              }}
            />
          </div>

          {/* Dropdown for selecting document type */}
          <div style={{ marginTop: "10px" }}>
            <label
              htmlFor="documentType"
              style={{
                display: "block",
                fontSize: "16px",
                fontWeight: "500",
                marginBottom: "10px",
                color: "#333",
              }}
            >
              Select file type to save:
            </label>
            <select
              id="documentType"
              value={documentType}
              onChange={(e) => setDocumentType(e.target.value)}
              style={{
                width: "100%",
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                fontSize: "14px",
                color: "#333",
                backgroundColor: "#fff",
                outline: "none",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                appearance: "none",
                cursor: "pointer",
              }}
            >
              <option value="">--Select Type--</option>
              {documentTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {/* Save button */}
      {uploadedFile && documentType && (
        <button
          className="staffing-btn confirm-btn"
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#007BFF",
            border: "none",
            borderRadius: "8px",
            color: "#fff",
            cursor: "pointer",
            fontSize: "16px",
            transition: "background-color 0.3s ease-in-out",
          }}
          onClick={handleSave}
        >
          Save Document
        </button>
      )}
    </div>
  );
};

export default DocumentsTab;
