import { Drawer, SelectPicker, Steps } from 'rsuite';
import '../../../../styles/sideDrawer.css';
import { Fragment } from 'react';
import { Badge } from '@mui/material';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom';
import SendWhiteIcon from '../../../../../_asset/img/send-white_icon.svg';
import Document from '../../../../../_asset/img/docu.svg';
import Paperclip from '../../../../../_asset/img/paperclip-2.png';
import InfoCircle from '../../../../../_asset/img/info-circle.svg';
import TimerStart from '../../../../../_asset/img/timer-start.svg';
import { ReactComponent as Profile } from '../../../../../_asset/img/profile.svg';

const SideDrawer = ({ isOpen, setIsOpen, getByItem }) => {
    const { providerCompanyId } = useParams();
    const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
        item => ({ label: item, value: item })  
    );

    return (
        <Drawer open={isOpen} onClose={() => setIsOpen(false)} className="side_drawer">
            <Drawer.Body>
                <div className='drawer_content'>
                    <div className='left_bar'>
                        <h2 className='title'>Amazon</h2>
                        <div className='drawer_inner_content' id='scrollBar'>
                            <div className='sub_titles'>
                                <h4>DESCRIPTIONS</h4>
                                <p>Meet Stefan at reception</p>
                            </div>
                            <div className='time_amount'>
                                <h2 className='amount'>$54/hrs</h2>
                                <p className='description'>Max. Hourly Rate</p>
                            </div>
                            <div className='shifts_card'>
                                <h2 className='shifts_title'>Shifts</h2>
                                <table class="table border-0 m-0">
                                    <thead>
                                        <tr>
                                            <th>Shift Date</th>
                                            <th>Shift Times</th>
                                            <th>Staffs Required</th>
                                        </tr>
                                    </thead>
                                    <tbody id="data-body">
                                        <tr>
                                            <td>2024-09-18</td>
                                            <td>10:00 to 1:00 Hrs</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>2024-09-18</td>
                                            <td>10:00 to 1:00 Hrs</td>
                                            <td>2</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='shifts_card offer_card'>
                                <h2 className='shifts_title'>Offer Statistic</h2>
                                <div className='offer_items'>
                                    <div className='offer_item'>
                                        <p className='label'>Available</p>
                                        <p className='details'>18 Officers Within 50 Miles</p>
                                    </div>
                                    <div className='offer_item'>
                                        <p className='label'>Valid Offers</p>
                                        <p className='details'>Enable VPC Flow logs for al subnets in VPC Network</p>
                                    </div>
                                    <div className='offer_item'>
                                        <p className='label'>Offers</p>
                                        <div className='details'>
                                            <Steps current={0}>
                                                <Steps.Item title="Open" />
                                                <Steps.Item title="Accepted" />
                                                <Steps.Item title="Expired" />
                                            </Steps>
                                        </div>
                                    </div>
                                    <div className='offer_item'>
                                        <p className='label'>Risk Factor</p>
                                        <div className='details'>
                                            <div className='rist_step'>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='comment_content'>
                                <Steps current={1} vertical>
                                    <Steps.Item title={
                                        <div className='comment_title'>
                                            <h4 className='comment_heading'>Alok</h4>
                                            <ul className='other_details'>
                                                <li>created the issue</li>
                                                <li>2 days ago</li>
                                            </ul>
                                        </div>
                                    } description="" icon={<Profile />} />
                                    <Steps.Item title={
                                        <div className='comment_title'>
                                            <h4 className='comment_heading'>Manjunath</h4>
                                            <ul className='other_details'>
                                                <li>2 days ago</li>
                                            </ul>
                                        </div>
                                    } description={
                                        <Fragment>
                                            <p className='description'>Hi Team, <br />
                                                I am working on this ticket, will be back with the update. <br />
                                                FYI - I will share you with action plan before implementation.</p>
                                            <div>
                                                <Badge className='document'><img src={Document} /> cloud_compliance_updates.pdf</Badge>
                                            </div>
                                        </Fragment>
                                    } icon={<Profile />} />
                                    <Steps.Item title="" description="" icon={<Profile />} />
                                </Steps>
                            </div>
                            <div className='send_comment'>
                                <textarea rows={3} placeholder='Ask AI'></textarea>
                                <div className='comment_btn'>
                                    <img src={Paperclip} alt="" />
                                    <button className='btn'>Comment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='right_bar' id='scrollBar'>
                        <div>
                            <h2 className='title_assi'>Assignment Details</h2>
                            <div className='data_card'>
                                <p>Assignee Name:</p>
                                <h2>Manjunath <img src={InfoCircle} alt="" /></h2>
                                <label>Assignee To:</label>
                                <SelectPicker data={data} />
                            </div>
                            <h2 className='title_assi'>Customer Details</h2>
                            <div className='data_card'>
                                <div>
                                    <p>Job ID</p>
                                    <h2>#ID123432</h2>
                                </div>
                                <div>
                                    <p>Service Type:</p>
                                    <h2>Mobile Patrol</h2>
                                </div>
                            </div>
                            <h2 className='title_assi'>Other Informations</h2>
                            <div className='data_card'>
                                <div className='other_item'>
                                    <p>Impact:</p>
                                    <div className='right_col'>
                                        <Badge className='high badge_status'>High</Badge>
                                    </div>
                                </div>
                                <div className='other_item'>
                                    <p>Priority:</p>
                                    <div className='right_col'>
                                        <Badge className='low badge_status'>Low</Badge>
                                    </div>
                                </div>
                                <div className='other_item'>
                                    <p>Urgency:</p>
                                    <div className='right_col'>
                                        <Badge className='medium badge_status'>Medium</Badge>
                                    </div>
                                </div>
                                <div className='other_item'>
                                    <p>SLA:</p>
                                    <div className='right_col info'><img src={TimerStart} alt="" /> <span>2 Days</span></div>
                                </div>
                                <div className='other_item'>
                                    <p>Created on</p>
                                    <div className='right_col info'>14 July 2022, 08:12</div>
                                </div>
                                <div className='other_item'>
                                    <p>Created by</p>
                                    <div className='right_col info'><Profile /> <span>Alok</span></div>
                                </div>
                                <div className='other_item'>
                                    <p>Last Updated</p>
                                    <div className='right_col info'>15 July 2022, 17:54</div>
                                </div>
                                <div className='other_item'>
                                    <p>Last Updated by</p>
                                    <div className='right_col info'><Profile /><span>Pradeep</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='send_offer'>
                            <SelectPicker data={data} placement='topStart' placeholder='In process' />
                            <Link to={`/provider-company/${providerCompanyId}/jobs/${getByItem?._id}?view=recommended`} className='send_offer_btn'>
                                Send Offer                    
                                <span className='send_icon'>
                                    <span className='line'></span>
                                    <img src={SendWhiteIcon} alt="" />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </Drawer.Body>
        </Drawer>
    );
}

export default SideDrawer;