
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { addUserAsProviderManager, getTeamManagers } from '../../../../services/staffingService';
import { Redirect, Link, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import DashboardSidebar from "../../../common/dashboardSidebar";
import { useUser } from '../../../../context/UserContext';
import { useProviderCompany } from '../../../../context/ProviderCompanyContext';
import { Loader, Modal, SelectPicker } from 'rsuite';
import { AvatarGroup, Avatar, Badge, Button } from 'rsuite';
import { ReactComponent as MoreVertical } from '../../../_asset/img/more-vertical.svg'
import "./team.css";


import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Header from '../../../common/header';

const providerManagerSchema = yup.object({
    providerManagerEmail: yup.string()
        .required('Email is required for Provider Manager')
        .email('Must be a valid email address')
});

const data = ['Dispatch Manager' , 'Manager Managing Managers'].map(
    item => ({ label: item, value: item })
);

const Team = () => {
    const { userInfo, userInfoReady } = useUser();
    const { providerCompanyId } = useParams();
    const { providerCompanyData, fetchProviderCompanyData } = useProviderCompany();
    const [loading, setLoading] = useState(true);
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const [managerResponse, setManagerResponse] = useState('');
    const [managerError, setManagerError] = useState(false);
    const [open, setOpen] = useState(false);
    const [providerManagers, setProviderManagers] = useState(0);
    const [emails, setEmails] = useState([]);
    const [inputValue, setInputValue] = useState("");


    const fetchTeam = async () => {
        const resp = await getTeamManagers(providerCompanyId);
        setProviderManagers(resp.data.providerManagers);
    }

    const {
        register: registerManager,
        handleSubmit: handleSubmitManager,
        formState: { errors: errorsManager },
        reset: resetManager,
    } = useForm({
        resolver: yupResolver(providerManagerSchema),
    });

    const onSubmitProviderManager = async (data) => {
        try {
            const resp = await addUserAsProviderManager(providerCompanyId, data.providerManagerEmail);
            setManagerResponse(resp.data);
            setManagerError(false);
        } catch (error) {
            setManagerResponse(error.response.data || 'Failed to send invitation');
            setManagerError(true);
        }
    };

    useEffect(() => {
        if (providerCompanyId) {
            fetchProviderCompanyData(providerCompanyId);
            fetchTeam();
            // setProgress(calculateProgress(providerCompanyData, totalProviderManagers, totalProviderResources ));
            setLoading(false);

        }
    }, [providerCompanyId, fetchProviderCompanyData]);

    const handleClose = () => {
        setOpen(false);
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
      };
    
      const handleInputKeyDown = (e) => {
        if (e.key === "Enter" || e.key === "Tab") {
          e.preventDefault();
          const newEmail = inputValue.trim();
    
          if (validateEmail(newEmail) && !emails.includes(newEmail)) {
            setEmails([...emails, newEmail]);
            setInputValue("");
          }
        }
      };
    
      const validateEmail = (email) => {
        const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[cC][oO][mM]$/i;
        return re.test(String(email).toLowerCase());
      };
    
      const removeEmail = (emailToRemove) => {
        setEmails(emails.filter((email) => email !== emailToRemove));
      };


    return <div className="dashboard">
        <DashboardSidebar isOpen={showSideDrawer} />
        <div className='team_page'>
            <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />
            <div className="staffing-new-assignments-body">
                <div className='page_title'>
                    <div className='left_side'>
                        <h2>Team</h2>
                        <p>Members of your team</p>
                    </div>
                    <div className='right_side'>
                        <button className='create__btn' onClick={() => setOpen(true)}>+ Invite</button>
                    </div>
                </div>
                {/* ========= add provider manager ======= */}
                <div className="staffing-set-up-guide-task">
                    {providerManagers.length >= 1 &&
                        providerManagers.map((manager) => (
                            <div
                                className="team-provider-card"
                                key={manager.user_id._id}
                            >
                                <Avatar
                                    src={manager.user_id.profilePictureUrl}
                                    alt={`${manager.user_id.firstName} ${manager.user_id.lastName}`}
                                    className="team-avatar"
                                />
                                <div className="team-provider-details" style={{ flex: 1 }}>
                                <div className="team-provider-name" style={{ fontWeight: 'bold' }}>
                                    {manager.user_id.firstName && manager.user_id.lastName 
                                        ? `${manager.user_id.firstName} ${manager.user_id.lastName}` 
                                        : manager.user_id.email}
                                    </div>
                                    <div className="team-provider-role" style={{ fontSize: '12px', color: '#888' }}>
                                    {manager.user_id.firstName && manager.user_id.lastName 
                                        ? manager.user_id.email 
                                        : null}
                                </div>

                                </div>
                                <div className="team-provider-status" style={{ marginLeft: '16px', textAlign: 'right' }}>
                                     {/* <SelectPicker data={data} placeholder={"Member"} className='team-status' placement="bottomEnd"  /> */}
                                    <div className="team-options">
                                    Dispatch Manager
                                        <MoreVertical />
                                    </div> 
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>

        <Modal open={open} onClose={handleClose} className='create_customer_modal_wrapper' style={{ display: "flex", alignItems: "center", height: "calc(100% - 60px)" }}>
            <Modal.Header>
                <Modal.Title>Send Invite</Modal.Title>
                <p>Add dispatch managers to this account</p>
            </Modal.Header>
            <Modal.Body>
                    {/* 
                <div className="email_section">
                    
                    {emails?.map((email, index) => (
                        <div
                            key={index}
                            className="email_box"
                        >
                            {email}
                            <button
                                className="remove_btn"
                                onClick={() => removeEmail(email)}
                            >
                                x
                            </button>
                        </div>
                    ))}
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyDown}
                        placeholder="Enter email and address"
                        className="flex-grow border-none shadow-none outline-none"
                    /> 
                    
                </div>
                    */}

                    <form onSubmit={handleSubmitManager(onSubmitProviderManager)} className="form-group mt-3">
                        <div className="d-flex align-items-center mb-2">
                            <input
                                style={{
                                    width: '250px', fontSize: '14px', padding: '10px',
                                    borderRadius: '8px',
                                    border: '1px solid #E4E4E4'
                                }}
                                type="text"
                                className="form-control mr-2"
                                {...registerManager('providerManagerEmail')}
                                placeholder="Add manager email"
                            />
                            <button type="submit" className="btn staffing-button-form-add-user">+</button>
                        </div>
                        {managerResponse && (
                            <div className={`${managerError ? 'errorTextForm' : 'text-success'}`}>{managerResponse}</div>
                        )}
                        {errorsManager.providerManagerEmail && <div className="errorTextForm">{errorsManager.providerManagerEmail.message}</div>}
                    </form>


            </Modal.Body>
            <Modal.Footer>
                <div className='invite_as'>
                    <p>Invite as:</p>
                    <SelectPicker data={data} searchable={false} placeholder={"Dispatch Member"} />
                </div>
                {/* <button className='invite_btn'>Invite</button> */}
            </Modal.Footer>
        </Modal>

        {loading && <Loader backdrop center />}
    </div>

};

export default Team;