import React, { useRef , useEffect , memo } from 'react'

const StreetView = memo(({ job }) => {
    const GoogleMapRef = React.useRef(null);

    
    let position= { lat: job.geoLocation.coordinates[1] , lng: job.geoLocation.coordinates[0] }
    
    useEffect(() => {
      
      const map = new window.google.maps.Map(GoogleMapRef.current, {
        center: position,
        // zoom: 14
      },[]);
  
      const panorama = new window.google.maps.StreetViewPanorama(
        GoogleMapRef.current,
        {
          position: position,
          pov: { heading: 165, pitch: 0 },
          zoom: 1,
          addressControl: false,
          fullscreenControl: !false,
          showRoadLabels: false,
          disableDefaultUI: true,     // Disable all default UI elements
          linksControl: false,        // Disable navigation arrows
          panControl: false,          // Disable pan control
          zoomControl: false,         // Disable zoom control
          clickToGo: false,
          disableDefaultUI: true            // Prevent moving by clicking on the map
        }
      );

        panorama.setVisible(true);

        const streetViewService = new window.google.maps.StreetViewService();
        streetViewService.getPanorama(
        { location: position, radius: 50, source: window.google.maps.StreetViewSource.OUTDOOR },
        (data, status) => {
            if (status === window.google.maps.StreetViewStatus.OK) {
            panorama.setPano(data.location.pano);
            panorama.setPov({
                heading: 265,
                pitch: 0
            });
            panorama.setVisible(true);
            } else {
             console.error("Street View data not found for this location.");
            }
        }
        );
  
      map.setStreetView(panorama);
    }, [position]);
  
    return <div className='offer-googlestreetview'>
        <div ref={GoogleMapRef}></div>
    </div>;
  })
  
  export default StreetView;