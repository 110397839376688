import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import EditIcon from '@mui/icons-material/Edit';
import GavelIcon from '@mui/icons-material/Gavel';
import { useProviderCompany } from "../../../../context/ProviderCompanyContext";
import { updateProviderLegalInfo } from '../../../../services/staffingService';
import CloseIcon from '@mui/icons-material/Close';

// Autocomplete component
const Autocomplete = ({ onPlaceSelected, value, onClear }) => {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState(value || '');

    useEffect(() => {
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
            types: ['address'],
            fields: ['formatted_address', 'address_components', 'geometry'],
            componentRestrictions: { country: 'us' },
        });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) return;

            const addressComponents = place.address_components;
            const locationData = {
                formatted_address: place.formatted_address,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                city: '',
                state: '',
                zipcode: '',
                country: '',
            };

            // Extract address components
            addressComponents.forEach((component) => {
                const types = component.types;
                if (types.includes('locality')) locationData.city = component.long_name;
                if (types.includes('administrative_area_level_1')) locationData.state = component.short_name;
                if (types.includes('postal_code')) locationData.zipcode = component.long_name;
                if (types.includes('country')) locationData.country = component.long_name;
            });

            setInputValue(locationData.formatted_address); // Update input with formatted address
            onPlaceSelected(locationData);
        });
    }, [onPlaceSelected]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value); // Allow user to type in address
    };

    const handleClear = () => {
        setInputValue('');
        onClear();
    };

    return (
        <div className="autocomplete-container">
            <input
                type="text"
                ref={inputRef}
                className="form-control"
                placeholder="Enter your business address"
                value={inputValue} // Let the input control itself
                onChange={handleInputChange} // Allow user typing to trigger suggestions
            />
            {inputValue && (
                <button type="button" className="btn btn-link" onClick={handleClear}>
                    <CloseIcon className="staffing-cancel-icon" />
                </button>
            )}
        </div>
    );
};

// Validation schema using Yup
const legalSchema = yup.object().shape({
    businessName: yup.string().required('Business name is required'),
    supportEmail: yup.string().email('Invalid email address').required('Support email is required'),
    supportPhone: yup.string().required('Support phone is required'),
    vatId: yup.string().required('VAT/Tax ID is required'),
    businessAddress: yup.string().required('Business address is required')
});

const Legal = ({ providerCompanyId }) => {
    const [isEditing, setIsEditing] = useState(false);
    const { providerCompanyData, fetchProviderCompanyData } = useProviderCompany();
    const [selectedLocation, setSelectedLocation] = useState(null);

    const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(legalSchema),
        defaultValues: {
            businessName: '',
            supportEmail: '',
            supportPhone: '',
            vatId: '',
            businessAddress: '',
        },
    });

    useEffect(() => {
        if (providerCompanyData && providerCompanyData.legal) {
            reset({
                businessName: providerCompanyData.legal.name || '',
                supportEmail: providerCompanyData.legal.supportEmail || '',
                supportPhone: providerCompanyData.legal.supportPhone || '',
                vatId: providerCompanyData.legal.vatTaxId || '',
                businessAddress: providerCompanyData.legal.address || ''
            });
        }
    }, [providerCompanyData, reset]);

    const onSubmit = async (data) => {
        try {
            await updateProviderLegalInfo(providerCompanyId, {
                name: data.businessName,
                vatTaxId: data.vatId,
                supportEmail: data.supportEmail,
                supportPhone: data.supportPhone,
                address: data.businessAddress,
                location: selectedLocation
            });
            setIsEditing(false);
            fetchProviderCompanyData(providerCompanyId);
        } catch (error) {
            console.error('Failed to update legal information:', error);
        }
    };

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };

    const handlePlaceSelected = (locationData) => {
        setSelectedLocation(locationData);
        setValue('businessAddress', locationData.formatted_address);
    };

    const handleClearAddress = () => {
        setSelectedLocation(null);
        setValue('businessAddress', '');
    };

    return (
        <div className="d-flex align-items-start account_card">
            <GavelIcon className="checkboxSuccess" />
            <div>
                <h6 className="mb-1 staffing-set-up-guide-title">
                    Details
                    {!isEditing && (
                        <EditIcon
                            onClick={toggleEditMode}
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                        />
                    )}
                </h6>

                {isEditing ? (
                    <div className="configure-sidebar justify-content-center">
                        <form onSubmit={handleSubmit(onSubmit)} className="configure-form">
                            <div className="form-geoups mt-4">
                                <div className="form-group" style={{ width: '100%' }}>
                                    <label className="title">Business Name:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        {...register('businessName')}
                                    />
                                    <span className="errorTextForm">{errors.businessName?.message}</span>

                                    <label className="title mt-2">Support Email:</label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        {...register('supportEmail')}
                                    />
                                    <span className="errorTextForm">{errors.supportEmail?.message}</span>

                                    <label className="title mt-2">Support Phone:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        {...register('supportPhone')}
                                    />
                                    <span className="errorTextForm">{errors.supportPhone?.message}</span>

                                    <label className="title mt-2">VAT/Tax ID:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        {...register('vatId')}
                                    />
                                    <span className="errorTextForm">{errors.vatId?.message}</span>

                                    <label className="title mt-2">Business Address:</label>
                                    <Controller
                                        name="businessAddress"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Autocomplete
                                                onPlaceSelected={handlePlaceSelected}
                                                onClear={handleClearAddress}
                                                value={field.value}  // Pass the current selected address
                                            />
                                        )}
                                    />
                                    {errors.businessAddress && (
                                        <span className="errorTextForm">{errors.businessAddress.message}</span>
                                    )}

                                    <button className="staffing-btn confirm-btn" type="submit">
                                        Save
                                    </button>
                                    <button
                                        className="staffing-btn cancel-btn ml-2"
                                        type="button"
                                        onClick={() => setIsEditing(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div>
                        <p className="mb-2 staffing-set-up-guide-description" style={{ color: '#5f6368', fontSize: '14px' }}>
                            <span style={{ textDecoration: 'underline' }}>Business Name:</span> {providerCompanyData?.legal?.name} <br />
                            <span style={{ textDecoration: 'underline' }}>Support Email:</span> {providerCompanyData?.legal?.supportEmail} <br />
                            <span style={{ textDecoration: 'underline' }}>Support Phone:</span> {providerCompanyData?.legal?.supportPhone} <br />
                            <span style={{ textDecoration: 'underline' }}>VAT/Tax ID:</span> {providerCompanyData?.legal?.vatId} <br />
                            <span style={{ textDecoration: 'underline' }}>Business Address:</span> {providerCompanyData?.legal?.address} <br />
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Legal;
