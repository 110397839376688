import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  createStripeAccountLink,
  addUserAsProviderManager,
  inviteUserAsProviderResource,
  getProviderManagerAndResource,
  getProviderManagerAndResourceDetails,
} from "../../../../services/staffingService";
import {
  unlinkCalendar,
  nylasOAuth,
  zendeskOAuth,
  unlinkZendesk,
} from "../../../../services/staffingService";

import { Redirect, Link, useParams } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import DashboardSidebar from "../../../common/dashboardSidebar";
import { useUser } from "../../../../context/UserContext";
import { useProviderCompany } from "../../../../context/ProviderCompanyContext";
import { Loader } from "rsuite";
import { AvatarGroup, Avatar, Badge, Button } from "rsuite";
import GoogleCalendar from "../../icons/google-calendar.png";

import "./account.css";

// icons
import PaymentsIcon from "@mui/icons-material/Payments";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import ConstructionIcon from "@mui/icons-material/Construction";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../../../common/header";
const providerManagerSchema = yup.object({
  providerManagerEmail: yup
    .string()
    .required("Email is required for Provider Manager")
    .email("Must be a valid email address"),
});

const providerResourceSchema = yup.object({
  providerResourceEmail: yup
    .string()
    .required("Email is required for Provider Resource")
    .email("Must be a valid email address"),
});

const Account = () => {
  const { userInfo, userInfoReady } = useUser();

  const { providerCompanyId } = useParams();
  const { providerCompanyData, fetchProviderCompanyData } =
    useProviderCompany();
  const [loading, setLoading] = useState(true);
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [managerResponse, setManagerResponse] = useState("");
  const [managerError, setManagerError] = useState(false);

  const [resourceResponse, setResourceResponse] = useState("");
  const [resourceError, setResourceError] = useState(false);

  const [providerManagers, setProviderManagers] = useState(0);
  const [providerResources, setProviderResources] = useState(0);

  const tokenIsValid = isTokenValid(userInfo.integrations);
  const expiryDate = getTokenExpiryDate(userInfo.integrations);

  const [progress, setProgress] = useState(0);

  const getProviderManagerAndResourceDetailsData = async () => {
    const resp = await getProviderManagerAndResourceDetails(providerCompanyId);
    setProviderManagers(resp.data.providerManagers);
    setProviderResources(resp.data.providerResources);
  };

  const {
    register: registerManager,
    handleSubmit: handleSubmitManager,
    formState: { errors: errorsManager },
    reset: resetManager,
  } = useForm({
    resolver: yupResolver(providerManagerSchema),
  });

  const {
    register: registerResource,
    handleSubmit: handleSubmitResource,
    formState: { errors: errorsResource },
    reset: resetResource,
  } = useForm({
    resolver: yupResolver(providerResourceSchema),
  });

  const onSubmitProviderManager = async (data) => {
    try {
      const resp = await addUserAsProviderManager(
        providerCompanyId,
        data.providerManagerEmail
      );
      setManagerResponse(resp.data);
      setManagerError(false);
    } catch (error) {
      setResourceResponse(error.response.data || "Failed to send invitation");
      setResourceError(true);
    }
  };

  const onSubmitProviderResource = async (data) => {
    try {
      const resp = await inviteUserAsProviderResource(
        providerCompanyId,
        data.providerResourceEmail
      );
      setResourceResponse(resp.data);
      setResourceError(false);
    } catch (error) {
      setResourceResponse(error.response.data || "Failed to send invitation");
      setResourceError(true);
    }
  };

  useEffect(() => {
    if (providerCompanyId) {
      fetchProviderCompanyData(providerCompanyId);
      getProviderManagerAndResourceDetailsData();
      // setProgress(calculateProgress(providerCompanyData, totalProviderManagers, totalProviderResources ));
      setLoading(false);
    }
  }, [providerCompanyId, fetchProviderCompanyData]);



  const calculateProgress = (
    providerCompanyData,
    totalProviderManagers,
    totalProviderResources
  ) => {
    let progress = 20;
    return progress;
  };

  const handleLinkCalendar = async () => {
    const resp = await nylasOAuth();
    window.location.href = resp.data.authUrl;
  };

  const handleUnlinkCalendar = async (grantId) => {
    await unlinkCalendar(grantId);
    // refresh page?
    // fetchUser() ;
  };

  function isTokenValid(integrations) {
    if (!integrations || !integrations.nylas) {
      return false;
    }
    const { expiresIn, created } = integrations.nylas;
    const issueDate = new Date(created);
    const expiryDate = new Date(issueDate.getTime() + expiresIn * 1000);
    const now = new Date();

    return now < expiryDate;
  }

  function getTokenExpiryDate(integrations) {
    if (!integrations || !integrations.nylas) {
      return null;
    }

    const { expiresIn, created } = integrations.nylas;
    const issueDate = new Date(created);
    const expiryDate = new Date(issueDate.getTime() + expiresIn * 1000); // expiresIn is in seconds
    return expiryDate.toLocaleString(); // Formats date and time to local string
  }

  return (
    <>
      <div className="dashboard">
        <DashboardSidebar isOpen={showSideDrawer} />
        <div className="account_section">
          <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />
          <div className="staffing-new-assignments-body">
            {/* <div className="staff-assignments-cards-container"></div> */}
            {providerCompanyData && (
              <>
                <div>

                  <div className="align-items-start account_card">
                    <div className="d-flex">
                      <ConstructionIcon
                        style={{
                          color: "#13A391",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <h6 className="mb-1 staffing-set-up-guide-title">
                        Integrations
                      </h6>
                    </div>

                    <div>
                      <div className="account-integrations-calendar-container">
                        <img
                          className="account-integrations-calendar-icon"
                          src={GoogleCalendar}
                          alt="Google Calendar"
                        />
                        <div className="account-integrations-calendar-text">
                          <p className="account-integrations-calendar-label">
                            Google Calendar
                          </p>
                          {userInfo.integrations &&
                          userInfo.integrations.nylas ? (
                            <p className="account-integrations-email">
                              {userInfo.integrations.nylas.email} <br />
                              {tokenIsValid ? (
                                <>
                                  <span
                                    className="account-integrations-action"
                                    onClick={() =>
                                      handleUnlinkCalendar(
                                        userInfo.integrations.nylas.grantId
                                      )
                                    }
                                  >
                                    (Unlink)
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span
                                    className="account-integrations-action"
                                    onClick={handleLinkCalendar}
                                  >
                                    (Relink)
                                  </span>
                                  <span> - Token expired</span>
                                </>
                              )}
                            </p>
                          ) : (
                            <p
                              className="account-integrations-link"
                              onClick={handleLinkCalendar}
                            >
                              Link your calendar
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {loading && <Loader backdrop center />}
          </div>
        </div>
      </div>
    </>
  );
};
export default Account;
