import React, { useEffect, useState, useRef } from "react";

import {
  createNewJob,
  createCustomerCompany,
} from "../../../../services/staffingService";

import { Redirect, Link, useParams, useHistory } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CloseIcon from "@mui/icons-material/Close";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";

import { useUser } from "../../../../context/UserContext";
import { useProviderCompany } from "../../../../context/ProviderCompanyContext";
import { Loader } from "rsuite";

import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Message } from "rsuite";
import ShiftPicker from "./utils/DateTimePicker"; 

import 'react-phone-number-input/style.css';
import PhoneInput , { isValidPhoneNumber } from 'react-phone-number-input';
import moment from "moment";
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

import DashboardSidebar from "../../../common/dashboardSidebar";
import Header from "../../../common/header";



import DocumentDrop from "./documentDrop"; 

const schema = yup
  .object({
    companyLegalName: yup.string().required("Company Legal Name is required."),
    companyFriendlyName: yup.string().required("Friendly Name is required."),
    contactPersonName: yup.string().required("First Name is required."),
    contactEmail: yup
      .string()
      .email("Must be a valid email")
      .required("Email is required."),
    hourlyPayRate: yup
      .number()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .required("Hourly billing rate is required.")
      .positive("Hourly billing rate must be positive.")
      .min(45, "Hourly billing rate must be at least $45")
      .max(500, "Hourly billing rate cannot exceed $500"),
    companyWebsiteUrl: yup.string(),
    facilityType: yup.string().required("Facility Type is required."),
  })
  .required();

const NewCustomer = () => {

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      facilityType: ""
    } 
  });

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { providerCompanyId } = useParams();
  const [err, setErr] = useState("");
  const [errType, setErrType] = useState("");

  const [documentFile, setDocumentFile] = useState(null);
  const [showSideDrawer, setShowSideDrawer] = useState(!false);


  const onSubmit = async (data) => {
    try {
      setLoading(true);
  
      // Construct formData to include all form fields and the document file
      const formData = new FormData();
      formData.append("companyLegalName", data.companyLegalName);
      formData.append("companyFriendlyName", data.companyFriendlyName);
      formData.append("hourlyPayRate", data.hourlyPayRate);
      formData.append("companyWebsiteUrl", data.companyWebsiteUrl);
      formData.append("contactPersonName", data.contactPersonName);
      formData.append("contactEmail", data.contactEmail);
      formData.append("customerContact", data.customerContact);
      formData.append("facilityType", data.facilityType);
  
      if (documentFile) {
        formData.append("documentFile", documentFile);
      }
  
      const resp = await createCustomerCompany(providerCompanyId, formData);
      history.push(`/provider-company/${providerCompanyId}/customers`);
      // http://localhost:3000/provider-company/671fc68e660644a7f8d527e1/customers


    } catch (ex) {
      setLoading(false);
      if (ex.response && ex.response.status === 404) {
        setErr(ex.response.data);
        setErrType("advance");
      }
    }
  };
  



  return (
    <div className="dashboard">
      <DashboardSidebar isOpen={showSideDrawer} />
      <div className='dashboard_content'>
        <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} breadcrumb={`Customers > Create New Customer`}/>
        <div className='customer_page p-5' style={{overflow: 'overlay'}}>

          <div className="configure-sidebar justify-content-center">

            <form onSubmit={handleSubmit(onSubmit)} className="configure-form">
              {/* <div className="hr-with-text">
               <hr />
                <span><AddBusinessIcon style={{ color: '#13A391' }} /></span>
                <hr /> 
              </div> */}
              <div className="advanced-setting epochs">
                <div className="form-geoups">
                  <div className="form-group">
                    <label className="title">Company Legal Name </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("companyLegalName")}
                        className="form-control"
                        placeholder="Amazon Inc."
                      />
                      {errors.companyLegalName && (
                        <span className="errorTextForm">
                          {errors.companyLegalName.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="title">Friendly Name</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("companyFriendlyName")}
                        className="form-control"
                        placeholder="Amazon Warehouse"
                      />
                      {errors.companyFriendlyName && (
                        <span className="errorTextForm">
                          {errors.companyFriendlyName.message}
                        </span>
                      )}
                    </div>
                  </div>

                </div>
              </div>

              <div className="advanced-setting epochs">
                <div className="form-geoups">

                  <div className="form-group">
                    <label className="title">Max. Hourly Billing Rate ($)</label>
                    <div className="mt-1">
                      <input
                        type="number"
                        {...register("hourlyPayRate")}
                        className="form-control"
                        placeholder={48}
                      />
                      {errors.hourlyPayRate && (
                        <span className="errorTextForm">
                          {errors.hourlyPayRate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="title">Company Website URL</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("companyWebsiteUrl")}
                        className="form-control"
                        placeholder="https://www.amazon.com"
                      />
                      {errors.companyWebsiteUrl && (
                        <span className="errorTextForm">
                          {errors.companyWebsiteUrl.message}
                        </span>
                      )}
                    </div>
                  </div>

                </div>
              </div>

              <div className="form-geoups" style={{width:'100%'}}>      
                <div className="form-group">
                    <label className='title'>Facility Type </label>
                    <select
                      name="facilityType"
                      id="facilityType"
                      {...register("facilityType")}
                      className='form-control'
                    
                    >
                      <option value="store"> Store </option>
                      <option value="workplace"> Workplace </option>
                      <option value="warehouse"> Warehouse </option>
                      <option value="office"> Office </option>
                      <option value="factory"> Factory </option>
                      <option value="construction"> Construction Site </option>
                      <option value="other"> Other </option>

                    </select>
                    {errors.facilityType && (
                      <span className='errorTextForm'>
                        {errors.facilityType.message}
                      </span>
                    )}
                  </div>
             </div>

             <DocumentDrop setDocumentFile={setDocumentFile} />
                      
             <hr />

              
              <div className="advanced-setting epochs">
                <div className="form-geoups">

                    <div className="form-group">
                            <label className="title">Contact Person (Facility Manager, Receptionist)</label>
                            <div className="mt-1">
                            <input
                                type="text"
                                {...register("contactPersonName")}
                                className="form-control"
                                placeholder="John"
                            />
                            {errors.contactPersonName && (
                                <span className="errorTextForm">
                                {errors.contactPersonName.message}
                                </span>
                            )}
                            </div>
                        </div>

                    <div className="form-group">
                    <label className="title" >Email (Invoice, Enquiries)</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("contactEmail")}
                        className="form-control"
                        placeholder="john.doe@example.com"
                      />
                      {errors.contactEmail && (
                        <span className="errorTextForm">
                          {errors.contactEmail.message}
                        </span>
                      )}
                    </div>
                    </div>
                </div>

            </div>

            
            <div className="form-geoups">
                <div className="form-group phone_field">
                <label className="title">Phone</label>
                <div className="mt-1">
                  <PhoneInput
                    defaultCountry="US"
                    onChange={(value) => setValue("customerContact", value)}
                  />
                  {errors.customerContact && (
                    <span className="errorTextForm">
                      {errors.customerContact.message}
                    </span>
                  )}
                </div>
              </div>
            </div>


              <div className="text-right">
                {!err && (
                  <>
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit)} // Use the confirm submit handler
                      className="staffing-btn confirm-btn"
                    >
                      Add New Customer
                    </button>
                  </>
                )}
                {err && errType === "advance" && (
                  <Message
                    showIcon
                    type="error"
                    className="errorTextForm text-center"
                  >
                    <span dangerouslySetInnerHTML={{ __html: err }} />
                  </Message>
                )}
              </div>

            </form>
            {loading && <Loader backdrop center />}
          </div>
        </div>
      </div>
    </div>









  );
};

export default NewCustomer;