import React, { useEffect, useState } from "react";
import { getProviderResourceDetails } from "../../../../services/staffingService";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../context/UserContext";
import { Loader } from "rsuite";
import OfficerDashboardSidebar from "../officerDashboardSidebar";
import OfficerHeader from "../officerHeader";
import DetailsTab from "./details";
import PaymentsTab from "./payment";
import PreferencesTab from "./preferences";
import IntegrationsTab from "./integrations";
import DocumentsTab from "./documents";

const Account = () => {
  const { userInfo, userInfoReady } = useUser();
  const { providerCompanyId } = useParams();
  const [loading, setLoading] = useState(true);
  const [providerResource, setProviderResource] = useState({});
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState("details");

  const fetchProviderResourceDetails = async () => {
    const resp = await getProviderResourceDetails(providerCompanyId);
    setProviderResource(resp.data);
  };

  useEffect(() => {
    if (providerCompanyId) {
      fetchProviderResourceDetails();
      setLoading(false);
    }
  }, [providerCompanyId]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="dashboard officer_dashboard">
        <OfficerDashboardSidebar isOpen={showSideDrawer} />
        <div className="dashboard_content account_section">
          <OfficerHeader
            isOpen={showSideDrawer}
            setIsOpen={setShowSideDrawer}
          />
          <div className="staffing-new-assignments-body p-0" id="scrollBar">
            <div className="page_title">
              <div className="left_side">
                <h2>Account</h2>
                <p></p>
              </div>
            </div>
            <div className="main-content">
              <div className="tabs_content mb-5">
                <div className="tabs d-flex">
                  <div
                    className="_tab"
                    onClick={() => handleTabClick("details")}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      borderBottom:
                        activeTab === "details" ? "1px solid #404880" : "none",
                      color: activeTab === "details" ? "#404880" : "#878787",
                    }}
                  >
                    Details
                  </div>


                  <div
                    className="_tab"
                    onClick={() => handleTabClick("preferences")}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      borderBottom:
                        activeTab === "preferences"
                          ? "1px solid #404880"
                          : "none",
                      color:
                        activeTab === "preferences" ? "#404880" : "#878787",
                    }}
                  >
                    Preferences
                  </div>


                  <div
                    className="_tab"
                    onClick={() => handleTabClick("documents")}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      borderBottom:
                        activeTab === "documents"
                          ? "1px solid #404880"
                          : "none",
                      color:
                        activeTab === "documents" ? "#404880" : "#878787",
                    }}
                  >
                    Documents
                  </div>

                  <div
                    className="_tab"
                    onClick={() => handleTabClick("payment")}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      borderBottom:
                        activeTab === "payment" ? "1px solid #404880" : "none",
                      color: activeTab === "payment" ? "#404880" : "#878787",
                    }}
                  >
                    Payment
                  </div>
                  
                  <div
                    className="_tab"
                    onClick={() => handleTabClick("integrations")}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      borderBottom:
                        activeTab === "integrations"
                          ? "1px solid #404880"
                          : "none",
                      color:
                        activeTab === "integrations" ? "#404880" : "#878787",
                    }}
                  >
                    Integrations
                  </div>

                </div>

                <div className="tab_inner_content">
                  {activeTab === "details" && (
                    <DetailsTab providerCompanyId={providerCompanyId} providerResource={providerResource} setProviderResource={setProviderResource}  />
                  )}
                  {activeTab === "preferences" && (
                    <PreferencesTab providerCompanyId={providerCompanyId} providerResource={providerResource} setProviderResource={setProviderResource} />
                  )}
                  {activeTab === "payment" && (
                    <PaymentsTab
                      providerCompanyId={providerCompanyId}
                      providerResource={providerResource}
                      setProviderResource={setProviderResource}
                    />
                  )}
                  {activeTab === "integrations" && (
                    <IntegrationsTab
                      providerCompanyId={providerCompanyId}
                      providerResource={providerResource}
                      setProviderResource={setProviderResource}
                    />
                  )}
                  {activeTab === "documents" && (
                    <DocumentsTab
                      providerCompanyId={providerCompanyId}
                      providerResource={providerResource}
                      setProviderResource={setProviderResource}
                    />
                  )}
                </div>
              </div>

              {loading && <Loader backdrop center />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Account;
