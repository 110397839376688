import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import CloseIcon from "@mui/icons-material/Close";

const ShiftPicker = ({ shift, onShiftChange, onRemove }) => {
  const handleDateChange = (date) => {
    onShiftChange({ ...shift, date });
  };

  const handleStartTimeChange = (startTime) => {
    const combinedStartTime = moment(shift.date).set({
      hour: moment(startTime).hour(),
      minute: moment(startTime).minute(),
    });
    onShiftChange({ ...shift, startTime: combinedStartTime });
  };

  const handleEndTimeChange = (endTime) => {
    const combinedEndTime = moment(shift.date).set({
      hour: moment(endTime).hour(),
      minute: moment(endTime).minute(),
    });
    onShiftChange({ ...shift, endTime: combinedEndTime });
  };

  const handleStaffsRequiredChange = (e) => {
    onShiftChange({ ...shift, staffsRequired: parseInt(e.target.value, 10) || 0 });
  };

  return (
    <div className="staffing-shifttimer-date-time-picker">
      <div>
        <Datetime
          value={shift.date}
          onChange={handleDateChange}
          timeFormat={false}
          closeOnSelect
        />
      </div>
      <div>
        <Datetime
          value={shift.startTime ? moment(shift.startTime) : null}
          onChange={handleStartTimeChange}
          dateFormat={false}
          inputProps={{ placeholder: 'Start Time' }}
        />
      </div>
      <div>
        <Datetime
          value={shift.endTime ? moment(shift.endTime) : null}
          onChange={handleEndTimeChange}
          dateFormat={false}
          inputProps={{ placeholder: 'End Time' }}
        />
      </div>
      <div>
        <input
          type="number"
          className="form-control"
          placeholder="Staffs Required"
          value={shift.staffsRequired || ''}
          onChange={handleStaffsRequiredChange}
          // className="staffing-staffs-required-input"
        />
      </div>
      <button type="button" className="staffing-shifttimer-btn-link" onClick={onRemove}>
        <CloseIcon className="staffing-cancel-icon"/>
      </button>
    </div>
  );
};

export default ShiftPicker;
