import React, { useState, useEffect } from "react";
import DashboardSidebar from "../../../common/dashboardSidebar";
import Header from "../../../common/header";
import { ReactComponent as SendUp } from "../../../_asset/img/send_up.svg";

import { Button, Modal, SelectPicker, Toggle , Loader } from "rsuite";

import { useParams, useHistory } from "react-router-dom";
import { useUser } from "../../../../context/UserContext";
import { useProviderCompany } from "../../../../context/ProviderCompanyContext";
import { getCustomerCompanies } from "../../../../services/staffingService";

import Payments from "./payments";
import OfferConfig from "./offerConfigs/offerconfig" ;
import SSO from "./sso"; 
import APIVersion from "./apiversion"; 
import Legal from "./legal"; 

const Settings = () => {
  const { userInfo, userInfoReady } = useUser();
  const { providerCompanyId } = useParams();
  const { providerCompanyData, fetchProviderCompanyData } =
    useProviderCompany();
  const [customerCompanies, setCustomerCompanies] = useState([]);

  const [activeTab, setActiveTab] = useState("legal"); // Default to 'payments' tab
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (providerCompanyId) {
      fetchProviderCompanyData(providerCompanyId);
      setLoading(false);
    }
  }, []);

  // Function to switch tabs
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="dashboard">
      <DashboardSidebar isOpen={showSideDrawer} />
      <div className="dashboard_content">
       <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />

        <div className="customer_page">
          <div className="page_title">
            <div className="left_side">
              <h2>Admin Console</h2>
              <p>Configure setttings for your enterprise.</p>
            </div>
            
          </div>

          <div className="inner_content" id="scrollBar">

            <div className="tabs_content">
              <div className="tabs">
                {/* Tabs */}

                <div
                  className="_tab"
                  onClick={() => handleTabClick("legal")}
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom:
                      activeTab === "legal" ? "1px solid #404880" : "none",
                    color: activeTab === "legal" ? "#404880" : "#878787",
                  }}
                >
                  Legal & Compliance
                </div>

                <div
                  className="_tab"
                  onClick={() => handleTabClick("payments")}
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom:
                      activeTab === "payments" ? "1px solid #404880" : "none",
                    color: activeTab === "payments" ? "#404880" : "#878787",
                  }}
                >
                   Payments
                </div>

                <div
                  className="_tab"
                  onClick={() => handleTabClick("sso")}
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom:
                      activeTab === "sso" ? "1px solid #404880" : "none",
                    color: activeTab === "sso" ? "#404880" : "#878787",
                  }}
                >
                  SSO
                </div>

                <div
                  className="_tab"
                  onClick={() => handleTabClick("offer")}
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom:
                      activeTab === "offer" ? "1px solid #404880" : "none",
                    color: activeTab === "offer" ? "#404880" : "#878787",
                  }}
                >
                  Configurations
                </div>

                <div
                  className="_tab"
                  onClick={() => handleTabClick("api-version")}
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom:
                      activeTab === "api-version" ? "1px solid #404880" : "none",
                    color: activeTab === "api-version" ? "#404880" : "#878787",
                  }}
                >
                  API Version
                </div>
              </div>

              {/* Content */}
              <div className="tab_inner_content">
                    { 
                      !loading && 
                      <>
                          {activeTab === "legal" && (
                              <Legal providerCompanyId = { providerCompanyId } providerCompanyData={providerCompanyData}  />
                          )}
                          {activeTab === "payments" && (
                            <Payments />
                          )}
                          {activeTab === "sso" && (
                              <SSO providerCompanyId = { providerCompanyId } />
                          )}
                          {activeTab === "offer" && (
                            <OfferConfig providerCompanyId = { providerCompanyId } providerCompanyData={providerCompanyData}/>
                          )}
                          {activeTab === "api-version" && (
                              <APIVersion/>
                          )}
                      </>
                    }
                    {
                      loading && 
                      <Loader />
                    }
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
