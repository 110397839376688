import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete'; 
import { addFileToProviderResourceByTeam, removeFileFromProviderResourceByTeam } from '../../../../../services/staffingService';

const DocumentsTab = ({ officerData, providerCompanyId, officers, setOfficers }) => {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [documentType, setDocumentType] = useState(""); // State for document type
    const [isSaved, setIsSaved] = useState(false);

    // Document types for dropdown
    const documentTypes = ["Contract", "Service Agreement", "License" , "Certificate" , "Other"];

    // Helper to update officer data in parent component's state
    const updateOfficerDataState = (updatedOfficerData) => {
        const updatedOfficers = officers.map((officer) =>
            officer._id === updatedOfficerData._id ? { ...updatedOfficerData, files: updatedOfficerData.files || [] } : officer // Ensure files is always an array
        );
        setOfficers(updatedOfficers);
    };
    

    const handleRemoveExistingFile = async (fileId) => {
        try {
            await removeFileFromProviderResourceByTeam(providerCompanyId, officerData._id, fileId);
    
            // Ensure `files` is always an array
            const updatedOfficerData = {
                ...officerData,
                files: (officerData.files || []).filter((file) => file._id !== fileId), // Safely handle undefined `files`
            };
    
            // Update officerData state in the parent component
            updateOfficerDataState(updatedOfficerData);
            console.log(`File removed successfully: ${fileId}`);
        } catch (error) {
            console.error("Error removing file:", error);
        }
    };
    

    // Dropzone configuration for file upload
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setUploadedFile(acceptedFiles[0]);
                setIsSaved(false);
            }
        },
        accept: 'application/pdf',
    });

    const handleSave = async () => {
        if (uploadedFile && documentType) {
            try {
                await addFileToProviderResourceByTeam(providerCompanyId, officerData._id, uploadedFile);
    
                const newFile = {
                    _id: Date.now(), // Temporary _id; replace with backend-generated _id after the actual API response
                    fileType: documentType,
                    fileName: uploadedFile.name,
                    fileUrl: URL.createObjectURL(uploadedFile), // Mock URL, replace with actual URL from API
                    uploadDate: new Date(),
                };
    
                // Ensure `files` array is always an array, even if it's initially undefined
                const updatedOfficerData = {
                    ...officerData,
                    files: [...(officerData.files || []), newFile], // Ensure files is an array
                };
    
                // Update officerData in parent state
                updateOfficerDataState(updatedOfficerData);
                //setIsSaved(true);
                setUploadedFile(null);
        setDocumentType("");
                console.log("File successfully uploaded");
            } catch (error) {
                console.error("Error saving file:", error);
            }
        } else {
            console.log("No file or document type selected");
        }
    };
    

    return (
        <div className="tabs__content" id="scrollBar" style={{ padding: '20px' }}>
            <input {...getInputProps()} />

            {/* Existing documents section */}
            <div>
                {officerData.files && officerData.files.length > 0 ? (
                    <div className="existing-files-list" style={{ marginBottom: '20px' }}>
                        {officerData.files.map((file) => (
                            <div key={file._id} className="existing-file" style={{ marginBottom: '10px' }}>
                                <a
                                    href={file.fileUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#007BFF',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {file.fileType} - {file.fileName}
                                </a>
                                <DeleteIcon
                                    onClick={() => handleRemoveExistingFile(file._id)} // Use the file _id for deletion
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: '10px',
                                        color: '#FF4D4F',
                                        verticalAlign: 'middle',
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No documents found.</p>
                )}
            </div>

            {/* Drag and drop section */}
            {!uploadedFile && !isSaved && (
                <div
                    {...getRootProps()}
                    className="dropzone mt-5"
                    style={{
                        border: '2px dashed #007BFF',
                        borderRadius: '8px',
                        padding: '40px',
                        textAlign: 'center',
                        color: '#007BFF',
                        backgroundColor: '#f9f9f9',
                        cursor: 'pointer',
                        transition: 'border 0.3s ease-in-out',
                    }}
                >
                    <input {...getInputProps()} />
                    <p style={{ margin: '0', fontSize: '16px' }}>
                        Drag and drop a document here, or click to select one.
                    </p>
                </div>
            )}

            {/* Show uploaded file info, document type dropdown, and remove icon */}
            {uploadedFile && (
                <div style={{ marginTop: '20px', border: '1px solid #ddd', borderRadius: '8px', padding: '20px' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <p style={{ margin: 0, fontSize: '16px', color: '#333', fontWeight: 'bold' }}>
                            {uploadedFile.name}
                        </p>
                        <DeleteIcon
                            onClick={() => setUploadedFile(null)}
                            style={{
                                cursor: 'pointer',
                                color: '#FF4D4F',
                            }}
                        />
                    </div>

                    {/* Dropdown for selecting document type */}
                    <div style={{ marginTop: '10px' }}>
                        <label
                            htmlFor="documentType"
                            style={{
                                display: 'block',
                                fontSize: '16px',
                                fontWeight: '500',
                                marginBottom: '10px',
                                color: '#333',
                            }}
                        >
                            Select file type to save:
                        </label>
                        <select
                            id="documentType"
                            value={documentType}
                            onChange={(e) => setDocumentType(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '12px',
                                borderRadius: '8px',
                                border: '1px solid #ccc',
                                fontSize: '14px',
                                color: '#333',
                                backgroundColor: '#fff',
                                outline: 'none',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                appearance: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            <option value="">--Select Type--</option>
                            {documentTypes.map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

            {/* Save button */}
            {uploadedFile && documentType && (
                <button
                    className="staffing-btn confirm-btn"
                    style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        backgroundColor: '#007BFF',
                        border: 'none',
                        borderRadius: '8px',
                        color: '#fff',
                        cursor: 'pointer',
                        fontSize: '16px',
                        transition: 'background-color 0.3s ease-in-out',
                    }}
                    onClick={handleSave}
                >
                    Save Document
                </button>
            )}
        </div>
    );
};

export default DocumentsTab;
