import React, { Component } from 'react';
// import './svgs.css'

class CheckCircleIcon extends React.Component {
    render() {
        return (
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="16" height="16" rx="8" fill="#3e4784" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3536 6.14645C11.5488 6.34171 11.5488 6.65829 11.3536 6.85355L7.35355 10.8536C7.15829 11.0488 6.84171 11.0488 6.64645 10.8536L4.64645 8.85355C4.45118 8.65829 4.45118 8.34171 4.64645 8.14645C4.84171 7.95118 5.15829 7.95118 5.35355 8.14645L7 9.79289L10.6464 6.14645C10.8417 5.95118 11.1583 5.95118 11.3536 6.14645Z" fill="white" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        )
    }
}

export default CheckCircleIcon;