import { Badge } from "@mui/material";
import '../../../../styles/unassignedGridView.css';
import { Link } from "@mui/icons-material";
import JobStreetView from "../../offers/status/jobStreetView";
import Send from '../../../../../_asset/img/send-2.svg';
import { useState } from "react";
import { useEffect } from "react";
import SideDrawer from "./sideDrawer";
import { Progress } from 'rsuite';

const UnassignedGridView = ({data , getDeadlineText}) => {

    const [open, setOpen] = useState(false);
    const [getByItem, setGetByItem] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null); 


    useEffect(() => {
        if(!open){
            setGetByItem(null);
        }
    }, [open]);

    return (
        <div className="unassigne_content grid_View">
            {
                data.length > 0 && data.map((job, index) => {
                  // const { totalShifts, uniqueDays } = job.shiftInfo;
                  const deadlineText = getDeadlineText(job.shifts);  

                  const percent = job.statistics.overallCoverage;
                  const status = percent === 100 ? 'success' : null;
                  const color = percent === 100 ? '#52c41a' : '#3385ff';

                  return (
                    <>
                    <div key={job._id} className="offer-staffing-street-view-container">
                      <div className="offer-staffing_img">
                      <JobStreetView job={job} />
                      <span className="share_icon" onClick={() => {
                                                    setOpen(true);
                                                    setSelectedJob(job);
                                                }}>
                          <img src={Send} alt="" />
                      </span>
                      </div>
                      <div className="staff-order-body">
                      <div className="staff-order-header">
                        <div className="staff-order-name">

                          {/* will be link to customer page  */}
                          {job.customer_company_id.companyFriendlyName}
                        </div>
                      <div className="staff-order-address">{job.formattedAddress}</div>
                      </div>
                      <hr className="border_hr" />
                      <div className="types_list">
                        <div className="type">
                            <p className="title">Fulfillment Deadline</p>
                            <p className="value">{deadlineText}</p>
                        </div>
                        <div className="type">
                            <p className="title">Active Offers</p>
                            <p className="value">{job.statistics.validOffersCount}</p>
                        </div>
                        <div className="type">
                            <p className="title">Shift Coverage</p>
                            {/* <p className="value"></p> */}
                            <p className="rate_col" style={{ width: 40 }} >
                                            <Progress.Circle percent={percent.toFixed(0)} strokeColor={color}  />
                                        </p>
                        </div>
                      </div>
                      {/* <div className="badge_tab">
                            <Badge>
                                #{job.service.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </Badge>
                        </div> */}
                      {/* <hr /> */}
                      </div>
                    </div>
                    {/* <SideDrawer job={job} isOpen={open} setIsOpen={setOpen} getByItem={getByItem} /> */}

                    {selectedJob && selectedJob._id === job._id && open && (
                                        <SideDrawer
                                            job={job}
                                            isOpen={open}
                                            setIsOpen={(state) => {
                                                setOpen(state);
                                                if (!state) setSelectedJob(null); // Close the drawer and reset selected job
                                            }}
                                            getByItem={selectedJob}
                                        />
                                    )}

                   </>
                  );
                })                
              }
        </div>
    )
};

export default UnassignedGridView;