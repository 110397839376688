import React from 'react';
import Recommendations from './recommendations';
import TimeKeeping from './timekeeping';
import Disputes from './disputes';

const OfferConfig = ({ providerCompanyId, providerCompanyData }) => {
    

    return (
        <div>
            <Recommendations providerCompanyId = { providerCompanyId } providerCompanyData={providerCompanyData}  />
            <hr />
            <TimeKeeping providerCompanyId = { providerCompanyId } providerCompanyData={providerCompanyData} />
            <hr />
            <Disputes providerCompanyId = { providerCompanyId } providerCompanyData={providerCompanyData} />
        </div>
    );
};

export default OfferConfig;
