import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import toast from 'react-hot-toast';

import '../styles/staffing-main.css'; // Updated CSS file

import manager from '../icons/project-manager.png';
import { createStaffAllocationCompany } from '../../../services/staffingService';

const Autocomplete = ({ onPlaceSelected, value, onClear }) => {
  const inputRef = React.useRef(null);
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
      types: ['address'],
      fields: ['formatted_address', 'address_components', 'geometry'],
      componentRestrictions: { country: 'us' },
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;

      const addressComponents = place.address_components;
      const locationData = {
        formatted_address: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        city: '',
        state: '',
        zipcode: '',
        country: '',
      };

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes('locality')) locationData.city = component.long_name;
        if (types.includes('administrative_area_level_1')) locationData.state = component.short_name;
        if (types.includes('postal_code')) locationData.zipcode = component.long_name;
        if (types.includes('country')) locationData.country = component.long_name;
      });

      setInputValue(locationData.formatted_address);
      onPlaceSelected(locationData);
    });
  }, [onPlaceSelected]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleClear = () => {
    setInputValue('');
    onClear();
  };

  return (
    <div className="staff-allocation-company-create-autocomplete-container">
      <input
        type="text"
        ref={inputRef}
        className="staff-allocation-company-create-form-control"
        placeholder="Enter your business address"
        value={inputValue}
        onChange={handleInputChange}
      />
      {inputValue && (
        <button type="button" className="btn btn-link" onClick={handleClear}>
          <CloseIcon className="staff-allocation-company-create-staffing-cancel-icon" />
        </button>
      )}
    </div>
  );
};

// Validation schema using Yup
const schema = yup.object().shape({
  businessName: yup.string().required('Business name is required'),
  supportEmail: yup.string().email('Invalid email address').required('Support email is required'),
  supportPhone: yup.string().required('Support phone is required'),
  vatId: yup.string().required('VAT/Tax ID is required'),
  businessAddress: yup.string().required('Business address is required'),
});

const StaffAllocationCompanyNew = () => {
  const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const [selectedLocation, setSelectedLocation] = useState(null);

  const onSubmit = async (data) => {
    const companyData = {
        businessName: data.businessName,             // From the form
        supportEmail: data.supportEmail,             // From the form
        supportPhone: data.supportPhone,             // From the form
        vatId: data.vatId,                           // From the form
        businessAddress: data.businessAddress,       // From the form
        location: selectedLocation || {}             // Captured from Google Places Autocomplete
    };

    try {
        const response = await createStaffAllocationCompany(companyData);
        if (response.status === 200) {
            toast.success('Company created successfully!');
            window.location.href = `/provider-company/${response.data.providerCompanyId}/jobs`;
            // Redirect or perform other actions based on success
        }
    } catch (error) {
        toast.error('An error occurred while creating the company.');
        console.error('Error:', error);
    }
};

  const handlePlaceSelected = (locationData) => {
    setSelectedLocation(locationData);
    setValue('businessAddress', locationData.formatted_address);
  };

  const handleClearAddress = () => {
    setSelectedLocation(null);
    setValue('businessAddress', '');
  };

  return (
    <div className="staff-allocation-company-create-staffing-main">
      <div className="staff-allocation-company-create-staffing-card">
        <img className="staff-allocation-company-create-staffing-icon" src={manager} alt="Provider" />
        <h5 className="staff-allocation-company-create-staffing-title">Officer.app</h5>
        <p className="staff-allocation-company-create-staffing-description">
          Efficiently assign officers at facilities to meet client needs.
        </p>
        <hr className="staff-allocation-company-create-divider" />

        {/* Form starts here */}
        <form onSubmit={handleSubmit(onSubmit)} className="staff-allocation-company-create-staffing-form">
          {/* Company Legal Name */}
          <div className="staff-allocation-company-create-form-group mb-3">
            <label>Business Name:</label>
            <input
              type="text"
              className="staff-allocation-company-create-form-control"
              {...register('businessName')}
              placeholder="Enter business name"
            />
            {errors.businessName && <div className="text-danger mt-1">{errors.businessName.message}</div>}
          </div>

          {/* Support Phone and Support Email on the same row */}
          <div className="staff-allocation-company-create-form-row mb-3">
            <div className="staff-allocation-company-create-form-group">
              <label>Support Phone:</label>
              <input
                type="text"
                className="staff-allocation-company-create-form-control"
                {...register('supportPhone')}
                placeholder="Enter support phone"
              />
              {errors.supportPhone && <div className="text-danger mt-1">{errors.supportPhone.message}</div>}
            </div>

            <div className="staff-allocation-company-create-form-group">
              <label>Support Email:</label>
              <input
                type="email"
                className="staff-allocation-company-create-form-control"
                {...register('supportEmail')}
                placeholder="Enter support email"
              />
              {errors.supportEmail && <div className="text-danger mt-1">{errors.supportEmail.message}</div>}
            </div>
          </div>

          {/* VAT/Tax ID */}
          <div className="staff-allocation-company-create-form-group mb-3">
            <label>VAT/Tax ID:</label>
            <input
              type="text"
              className="staff-allocation-company-create-form-control"
              {...register('vatId')}
              placeholder="Enter VAT/Tax ID"
            />
            {errors.vatId && <div className="text-danger mt-1">{errors.vatId.message}</div>}
          </div>

          {/* Business Address */}
          <div className="staff-allocation-company-create-form-group mb-3">
            <label>Business Address:</label>
            <Controller
              name="businessAddress"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Autocomplete
                  onPlaceSelected={handlePlaceSelected}
                  onClear={handleClearAddress}
                  value={field.value}
                />
              )}
            />
            {errors.businessAddress && <div className="text-danger mt-1">{errors.businessAddress.message}</div>}
          </div>

          <button type="submit" className="btn staff-allocation-company-create-staffing-btn">Go</button>
        </form>
      </div>



      <div class="custom-support-section text-center">
            <div style={{color:'#ffffff' , width:'100px' }} >
            <Link to={`/home`} style={{ color: 'inherit' }}>
          <HomeIcon />
        </Link>
            </div> 
         </div>

    </div>
  );
};

export default StaffAllocationCompanyNew;
