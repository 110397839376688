import React, { useState , useRef, useEffect } from 'react';
import "./securitsStaffResitration.css" ; 
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';

  const SecurityStaffRegistration = () => {
  
    React.useEffect(() => { 
    },[])
    
    const startRegistration = () => {
      // show pop up
      alert("Authentication is not enabled for Demo App");
    }

    return <> 

        <div className="dashboard">
         <div className="staffing-new-assignments-body">
         <div className="security-staff-registration-container">
              <header className="security-staff-registration-header">
                <div className="security-staff-registration-header-content">
                  <h1 className="security-staff-registration-title">Get ready for duty</h1>
                  <p className="security-staff-registration-subtitle">
                    Star Security Services is the California's leading security platform. <br/>
                    Join our team of security staff to help keep people safe.
                  </p>
                  <button className="security-staff-registration-button" onClick={startRegistration} >BECOME A SECURITY STAFF</button>
                </div>
              </header>
              <section className="security-staff-registration-features">
                <div className="security-staff-registration-feature">
                  {/* <i className="security-staff-registration-icon calendar-icon"></i> */}
                  <CalendarMonthIcon className="security-staff-registration-icon calendar-icon" style={{fontSize:'45px'}}/>
                  <h3 className="mt-2">Work when you want</h3>
                  <p>You can be a part of the Star Security Services team anytime—you pick your own schedule. Work as little or as much as you want.</p>
                </div>
                <div className="security-staff-registration-feature">
                  {/* <i className="security-staff-registration-icon money-icon"></i> */}
                  <MonetizationOnIcon className="security-staff-registration-icon money-icon" style={{fontSize:'45px'}}/>
                  <h3 className="mt-2">Earn more with each shift</h3>
                  <p>Shift rates increase with time and complexity. Cash out whenever you want and get transfers to your account.</p>
                </div>
                <div className="security-staff-registration-feature">
                  {/* <i className="security-staff-registration-icon ar-app-icon"></i> */}
                  <AppShortcutIcon className="security-staff-registration-icon ar-app-icon" style={{fontSize:'45px'}}/>
                  <h3 className="mt-2">Easy to use mobile app</h3>
                  <p>Ready, set, download. Get shift updates, directions, and 24/7 support. Don’t have a smartphone? No problem.</p>
                </div>
              </section>
            </div>
          </div>
        </div>

    </>

}
export default SecurityStaffRegistration ;