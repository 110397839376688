const APIVersion = () => {
  return (
    <p
      className="mb-2 staffing-set-up-guide-description"
      style={{ color: "#5f6368", fontSize: "14px" }}
    >
      Current Version - v0. <br/>
      Release - 01-JAN-2025. <br/>
      <p className="mt-2">

      No new versions available.
      </p>
    </p>
  );
};

export default APIVersion;
