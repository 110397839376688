import { Badge } from "@mui/material";
import '../../../../styles/unassignedGridView.css';
import { Link } from "@mui/icons-material";
import JobStreetView from "../../offers/status/jobStreetView";
import Send from '../../../../../_asset/img/send-2.svg';
import { useState } from "react";
import { useEffect } from "react";
import SideDrawer from "./sideDrawer";

const UnassignedGridView = ({data}) => {
    const [open, setOpen] = useState(false);
    const [getByItem, setGetByItem] = useState(null);

    useEffect(() => {
        if(!open){
            setGetByItem(null);
        }
    }, [open]);

    return (
        <div className="unassigne_content grid_View">
            {
                data.length > 0 && data.map((job, index) => {
                  const { totalShifts, uniqueDays } = job.shiftInfo;

                  const shiftsText = `${totalShifts} shift${totalShifts !== 1 ? 's' : ''}`;
                  const daysText = `${uniqueDays} day${uniqueDays !== 1 ? 's' : ''}`;

                  const jobDescription = `${shiftsText} over ${daysText}`;

                  return (
                    <div key={job._id} className="offer-staffing-street-view-container">
                      <div className="offer-staffing_img">
                      <JobStreetView job={job} />
                      <span className="share_icon" onClick={() => {setOpen(true); setGetByItem(job)}}>
                          <img src={Send} alt="" />
                      </span>
                      </div>
                      <div className="staff-order-body">
                      <div className="staff-order-header">
                        <div className="staff-order-name">

                          {/* will be link to customer page  */}
                          {job.customer_company_id.companyFriendlyName}
                        </div>
                      <div className="staff-order-address">{job.formattedAddress}</div>
                      </div>
                      <hr className="border_hr" />
                      <div className="types_list">
                        <div className="type">
                            <p className="title">Required</p>
                            <p className="value">1 Staff</p>
                        </div>
                        <div className="type">
                            <p className="title">Duration</p>
                            <p className="value">{jobDescription}</p>
                        </div>
                        <div className="type">
                            <p className="title">Acceptance rate</p>
                            <p className="value">56%</p>
                        </div>
                      </div>
                      <div className="badge_tab">
                            <Badge>
                                #{job.service.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </Badge>
                        </div>
                      {/* <hr /> */}
                      </div>
                    </div>
                  );
                })
              }

            <SideDrawer isOpen={open} setIsOpen={setOpen} getByItem={getByItem} />
        </div>
    )
};

export default UnassignedGridView;