import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "../../styles/scheduler.css";

import googleCalendar from "../../icons/google-calendar.png";
import whatsApp from "../../icons/whatsapp.png";

const Scheduler = ({ isOpen, onRequestClose, className, overlayClassName, ariaHideApp, sendOffer, staff, job }) => {
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (staff.assignedShifts && staff.assignedShifts.length > 0) {
            setSelectedDate(new Date(staff.assignedShifts[0].date));
        }
    }, [staff.assignedShifts]);

    const options = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric"
    };

    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            return !staff.assignedShifts.some(shift => {
                const shiftDate = new Date(shift.date);
                return shiftDate.getFullYear() === date.getFullYear() &&
                    shiftDate.getMonth() === date.getMonth() &&
                    shiftDate.getDate() === date.getDate();
            });
        }
        return false;
    };

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const shiftDates = staff.assignedShifts.map(shift => new Date(shift.date).toDateString());
            if (shiftDates.includes(date.toDateString())) {
                return 'highlight';
            }
        }
        return null;
    };

    const calculateShiftHours = (startTime, endTime) => {
        const startDateTime = new Date(startTime);
        const endDateTime = new Date(endTime);
        const hours = (endDateTime - startDateTime) / (1000 * 60 * 60);
        return hours;
    };

    const formatShiftHours = (startTime, endTime) => {
        const formattedStartTime = `${new Date(startTime).toLocaleTimeString()} Hrs`;
        const formattedEndTime = `${new Date(endTime).toLocaleTimeString()} Hrs`;
        return `${formattedStartTime} - ${formattedEndTime}`;
    };

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const getShiftsForSelectedDate = () => {
        return staff.assignedShifts.filter(shift => {
            const shiftDate = new Date(shift.date);
            return shiftDate.toDateString() === selectedDate.toDateString();
        });
    };

    return (
        <Modal isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={className}
            overlayClassName={overlayClassName}
            ariaHideApp={ariaHideApp}
        >
            {Object.keys(job).length >= 1 &&
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            {/* Left Column Starts */}
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                    </svg>
                                    <div className='fw-bold px-2'>{staff.firstName} {staff.lastName}</div>
                                </div>

                                <div className='d-flex align-items-center mb-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                    </svg>
                                    <div className='px-2'> {job.formattedAddress} </div>
                                </div>

                                <div className='d-flex align-items-center mb-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock-history" viewBox="0 0 16 16">
                                        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                                        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                    <div className='px-2'> {calculateShiftHours(staff.assignedShifts[0].startTime, staff.assignedShifts[0].endTime)} Hrs </div>
                                </div>
                            </div>
                            <hr />
                            <Calendar
                                onChange={handleDateChange}
                                value={selectedDate}
                                tileDisabled={tileDisabled}
                                tileClassName={tileClassName}
                            />
                        </div>

                        <div className='col align-items-center'>
                            {/* Right Column Starts */}
                            <div className='d-flex align-items-center mt-5 ml-5'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                </svg>
                                <div className='fw-bold px-2'>
                                    {selectedDate && selectedDate.toLocaleDateString("en-US", options)}
                                </div>
                            </div>

                            <div style={{ marginTop: "168px " }}>
                                {selectedDate && getShiftsForSelectedDate().map(shift => (
                                    <div key={shift._id} className='time-slot text-center p-2'>
                                        {formatShiftHours(shift.startTime, shift.endTime)}
                                    </div>
                                ))}
                            </div>
                            {/* Right Column Ends */}
                        </div>
                    </div>

                    <hr />

                    <div className='d-flex justify-content-between pb-1'>
                        <div className="mt-2" style={{ fontSize: 'smaller', color: "#5d627c" }}> *Send the first offer. This will also update Zendesk Ticket #{job.zendeskId}.  </div>
                        <button onClick={() => { sendOffer(staff, job) }} type="button" className="btn text-white fw-bold" style={{ background: "#5571d1" }}>Send </button>
                    </div>
                </div>
            }
        </Modal>
    );
};

export default Scheduler;
