import http from "./http";
import jwtDecode from "jwt-decode";
import apis  from "../config/apis.json";


const api = 
    process.env.REACT_APP_ENVIRON === "development"
    ? apis.dev
    : process.env.REACT_APP_ENVIRON === "production"
    ? apis.prod
    : apis.test;
    

export function register(view){
    const response= http.post(`${api}/user/register/`, {view : view}); 
    return response ; 
}


export async function login() {
    const response = await http.post(`${api}/user/login`);
    return response ; 
}

/*
export function googleAuth(dt){
    return http.post(`${api}/user/google/auth`, {
        profile: dt.profile, 
        oneTap: dt.oneTap
    });
}

export function getVerifyConfirmation( tokenStr ){
    const resp = http.post(`${api}/user/confirm/link/verify` , {
        confirmationCode: tokenStr
    });
    return resp ;
}

export function resendConfirmationLink(email){
    return http.post(`${api}/user/confirm/link/resend`, {
        email: email 
    }); 
}

export function getResetPwdLink( email ){
    const resp = http.post(`${api}/user/pwd/reset/link` , {
      email: email
    });
    
    return resp ;
}

export function ResetPwdLinkConfirmation(email, token ){
    const resp = http.post(`${api}/user/pwd/reset/link/verify` , {
      email: email.toLowerCase(), 
      token: token
    });

    return resp ;
}

export function updatePwd(id, email, pwd ){
    const resp = http.put(`${api}/user/pwd/update` , {
      userId: id, 
      email:email.toLowerCase() ,  
      pwd: pwd
    });
    return resp ;
}

export function updateDetails(details ){
    const { firstname , lastname, jobtitle, companyname } = details ; 
    const resp = http.put(`${api}/user/details` , {
        firstname: firstname , 
        lastname: lastname , 
        jobtitle: jobtitle , 
        companyname: companyname 
    });
    return resp ;
}

export function loginWithJwt (jwt) {
    return localStorage.setItem("x-auth-refineai", jwt);
}

export function getCurrentUser() {
    try {
      const jwt = localStorage.getItem('x-auth-refineai');
      return jwtDecode(jwt);
    } catch (ex) {
      return null;
    }
}

export function addUser(newUser, requestedBy){
    return http.post(`${api}/user/add`, { newUser: newUser, requestedBy: requestedBy });
 }
*/

export function allUsers(){
    return http.get(`${api}/user/all`)
}

export async function getUserInfo() {
    const response = await http.get(`${api}/user/info`);
    return response ; 
}


export async function logout() {
    return await http.get(`${api}/user/logout`);
}
