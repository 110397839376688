import React from 'react';
import './style.css'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import 'chart.js/auto';
import ApartmentChart from './ApartmentChart';

ChartJS.register(ArcElement);

const ApartMent = ({ dts }) => {

    return (
        <div className='Apartment-wrapper'>
            <div className='Apartment-content'>
                <div className='Apartment-title'>
                    <h6> Household Unit Structure Type </h6>
                </div>
                <ApartmentChart dts={dts[0].housingType} />
            </div>
        </div>
    )
}

export default ApartMent