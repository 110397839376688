import React, { useState, useEffect } from "react";

import VerifiedOfficers from "./verifiedOfficers";
import DashboardSidebar from "../../../common/dashboardSidebar";
import Header from "../../../common/header";
import { ReactComponent as SendUp } from "../../../_asset/img/send_up.svg";
import { ReactComponent as SearchIcon } from "../../../_asset/img/search-normal.svg";
import "./styles/officer.css";
import { Button, Modal, SelectPicker, Toggle } from "rsuite";
import InviteOfficer from "./officerinvite";
import { Link, useParams, useHistory } from "react-router-dom";
import { useUser } from "../../../../context/UserContext";
import { useProviderCompany } from "../../../../context/ProviderCompanyContext";
import {
  getCustomerCompanies,
  getTeamResources,
  inviteUserAsProviderResource,
} from "../../../../services/staffingService";

const Officers = () => {
  const { userInfo, userInfoReady } = useUser();
  const { providerCompanyId } = useParams();
  const { providerCompanyData, fetchProviderCompanyData } =
    useProviderCompany();
  const [officers, setOfficers] = useState([]);
  const [filteredOfficers, setFilteredOfficers] = useState([]);
  const [areFiltersActive, setAreFiltersActive] = useState(false); // State to track if filters are active

  const [filters, setFilters] = useState({
    searchText: "",
    officerName: null,
    address: null,
    contactPersonName: null,
    email: null,
    hasSLA: false, // This will filter if the company has an SLA
  });

  // State to manage active tab
  const [activeTab, setActiveTab] = useState("verified-officers");
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchOfficers();
  }, [providerCompanyId]);

  const fetchOfficers = async () => {
    const resp = await getTeamResources(providerCompanyId);
    setOfficers([...resp.data.providerResources]);
    setFilteredOfficers([...resp.data.providerResources]);
  };

  // Function to switch tabs
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleClose = () => {
    console.log("close officer invite tab");
    setOpen(false);
  };

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
    //resetFilters();
  };

  const getUniqueOptions = (key) => {
    const keys = key.split(",");
    return Array.from(
      new Set(
        officers.map((item) => {
          if (keys.length === 2) {
            return `${item[keys[0]]} ${item[keys[1]]}`; // Combine firstName and lastName
          }
          const nestedKeys = key.split("."); // Support nested keys
          return nestedKeys.reduce((acc, curr) => acc && acc[curr], item); // Access nested properties
        })
      )
    ).map((value) => ({
      label: value || "N/A",
      value: value || "N/A",
    }));
  };


  const applyFilters = () => {
    const filtered = officers.filter((item) => {
      // Check if search text matches officer's name or email
      const matchesSearchText =
        filters.searchText === "" || // If searchText is empty, match all officers
        `${item.user_id?.firstName || ""} ${item.user_id?.lastName || ""}`
          .toLowerCase()
          .includes(filters.searchText.toLowerCase()) || // Check firstName + lastName
        item.user_id?.email
          ?.toLowerCase()
          .includes(filters.searchText.toLowerCase()); // Check email if user_id exists

      // Match officer name (firstName + lastName)
      const matchesOfficerName =
        !filters.officerName ||
        `${item.user_id?.firstName || ""} ${item.user_id?.lastName || ""}`.toLowerCase() ===
          filters.officerName.toLowerCase();

      // Match city from address
      
      const matchesCity =
        !filters.city ||
        item.address?.city?.toLowerCase() === filters.city.toLowerCase();

      // Match state from address
      const matchesState =
        !filters.state ||
        item.address?.state?.toLowerCase() === filters.state.toLowerCase();

      // Match email (nested in user_id.email)
      const matchesEmail =
        !filters.email || item.user_id?.email === filters.email;

      return (
        matchesSearchText &&
        matchesOfficerName &&
        matchesCity &&
        matchesState &&
        matchesEmail
      );
    });

    setFilteredOfficers(filtered); // Update the filtered officers
    const activeFilters = checkIfFiltersAreActive(); // Check if filters are active
    setAreFiltersActive(activeFilters); // Update state if filters are active
  };

  const checkIfFiltersAreActive = () => {
    return (
      filters.searchText !== "" || 
      filters.officerName !== null || 
      filters.city !== null || 
      filters.state !== null || 
      filters.email !== null || 
      filters.hasSLA !== false 
    );
  };

  const resetFilters = () => {
    setFilters({
      searchText: "", 
      officerName: null, 
      city: null, 
      state: null, 
      email: null, 
      hasSLA: false,
    });
    
    setFilteredOfficers(officers); // Reset to original data
    setAreFiltersActive(false); // Reset the active filters state
  };

  return (
    <div className="dashboard">
      <DashboardSidebar isOpen={showSideDrawer} />
      <div className="dashboard_content">
        <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />

        <div className="customer_page">
          <div className="page_title">
            <div className="left_side">
              <h2>Officers</h2>
              <p>Manage all of the officers that you have</p>
            </div>
            <div className="right_side">
              <a href="/partner-success" target="_blank">
                <button className="count_of_customer_btn">
                  <span className="count_of_customer">184</span> Officers Around
                  You
                  <SendUp />
                </button>
              </a>

              <button
                className="create_customer_btn"
                onClick={() => setOpen(true)}
              >
                + Invite Officer
              </button> 
            </div>
          </div>

          <div className="inner_content" id="scrollBar">
            <div className="filter_content">
              <div className="heading">
                <h2 className="title">Filter</h2>
                {/* <span className='sub_title' onClick={resetFilters} >Reset</span> */}
              </div>
              <div className="search_box">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search officers"
                  value={filters.searchText} // Controlled input tied to filters state
                  onChange={(e) =>
                    handleFilterChange("searchText", e.target.value)
                  }
                />
              </div>

              <div className="folter_items">
                {/* <SelectPicker
                  data={getUniqueOptions("user_id.firstName, user_id.lastName")} 
                  placeholder="Officer Name"
                  value={filters.officerName} 
                  cleanable={true} 
                  // onClean={() => handleFilterChange("officerName", null)}
                  onClean={resetFilters} 
                  onChange={(value) => handleFilterChange("officerName", value)}
                /> */}
                <SelectPicker
                  data={getUniqueOptions("user_id.email")}
                  placeholder="Email"
                  value={filters.email}
                  cleanable={true}
                //   onClean={() => handleFilterChange("email", null)}
                  onClean={resetFilters}
                  onChange={(value) => handleFilterChange("email", value)}
                />

                <SelectPicker
                  data={getUniqueOptions("address.city")} 
                  placeholder="City"
                  value={filters.city} 
                  cleanable={true} 
                //   onClean={() => handleFilterChange("city", null)} 
                  // disabled={!!filters.city}
                  onClean={resetFilters} 
                  onChange={(value) => handleFilterChange("city", value)}
                />

                <SelectPicker
                  data={getUniqueOptions("address.state")} 
                  placeholder="State"
                  value={filters.state} 
                  cleanable={true} 
                //   onClean={() => handleFilterChange("state", null)} 
                  onClean={resetFilters} 
                  onChange={(value) => handleFilterChange("state", value)}
                />
              </div>
              <div className="toggle_section">
                <div className="toggle_lable">
                  <Toggle
                    checked={filters.hasSLA}
                    onChange={(value) => handleFilterChange("hasSLA", value)} 
                  />
                  <span className="label">Has SLA</span>
                </div>
              </div>
              <div>
                {!areFiltersActive && (
                  <button className="apply_btn" onClick={applyFilters}>
                    Apply
                  </button>
                )}
                {/* { selectedFilters && <span className='sub_title' onClick={resetFilters} >Reset</span> } */}
                {areFiltersActive && (
                  <div className="heading">
                    <span className="sub_title" onClick={resetFilters}>
                      {" "}
                      Reset Filters
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="tabs_content">
              <div className="tabs">
                {/* Tabs */}
                <div
                  className="_tab"
                  onClick={() => handleTabClick("verified-officers")}
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom:
                      activeTab === "verified-officers"
                        ? "1px solid #404880"
                        : "none",
                    color:
                      activeTab === "verified-officers" ? "#404880" : "#878787",
                  }}
                >
                  Verified Officers
                  <span
                    style={{
                      borderRadius: "99px",
                      background: activeTab ? "#404880" : "#FFFFFF",
                      padding: "4px 8px",
                      color: activeTab ? "#FFF" : "#404880",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {" "}
                    {filteredOfficers.length}{" "}
                  </span>
                </div>
                <div
                  className="_tab"
                  onClick={() => handleTabClick("unverified-officers")}
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom:
                      activeTab === "unverified-officers"
                        ? "1px solid #404880"
                        : "none",
                    color:
                      activeTab === "unverified-officers"
                        ? "#404880"
                        : "#878787",
                  }}
                >
                  Unverified Officers
                  <span
                    style={{
                      borderRadius: "99px",
                      background: activeTab ? "#404880" : "#FFFFFF",
                      padding: "4px 8px",
                      color: activeTab ? "#FFF" : "#404880",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    0
                  </span>
                </div>
              </div>

              {/* Content */}
              <div className="tab_inner_content">
                {activeTab === "verified-officers" && (
                  <VerifiedOfficers
                    officers={officers}
                    filteredOfficers={filteredOfficers}
                    setOfficers={setOfficers}
                    setFilteredOfficers={setFilteredOfficers}
                    providerCompanyId={providerCompanyId}
                  />
                )}
                {activeTab === "unverified-officers" && (
                  <div className="no_data">
                    <p className="provider-company-customer-no-companies p-0 m-0">
                      display unverified officers here{" "}
                    </p>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <InviteOfficer open={open} setOpen={setOpen}  handleClose={handleClose} providerCompanyId={providerCompanyId} />


    </div>
  );
};

export default Officers;
