import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./notifications.css";

const Notifications = ({ notifs = [], setNotifs = () => {}, n = 0, setN = () => {} }) => {
  
  const handleRead = () => {
    const resp = { data: notifs.map((notif) => ({ ...notif, readBy: [{ user: "dummyUserId", read: true }] })) };
    setN(0);
    setNotifs([...resp.data]);

    const countUnreadNotifications = (notifications) => {
      const userId = "dummyUserId";
      let unreadCount = 0;

      notifications.forEach((notif) => {
        const isRead = notif.readBy.some((r) => r.user === userId && r.read);
        if (!notif.read || !isRead) {
          unreadCount++;
        }
      });
      return unreadCount;
    };

    setN(countUnreadNotifications(resp.data));
  };

  return (
    <div className="staffing-notifications-dropdown">
      <div className="staffing-notifications-header">
        <h2>Notifications</h2>
        {n > 0 && <button className="staffing-notifications-new">{n} New</button>}
      </div>
      <div className="staffing-notifications-list">
        {notifs.length === 0 ? (
          <p className="staffing-notifications-empty">No notifications, but the day is young!</p>
        ) : (
          notifs.map((notif, index) => {
            const hasBeenRead = notif.readBy?.some((readByUser) => readByUser.user === "dummyUserId");
            const isRead = hasBeenRead ? true : false;

            return (
              <li key={index} className={`staffing-notifications-item ${isRead ? "read" : "unread"}`}>
                <Link to={notif.link || "#"} className="staffing-notifications-link">
                  <div className="staffing-notifications-content">
                    <h3>{notif.message}</h3>
                    <p className="staffing-notifications-time">{moment(notif.createdAt).fromNow()}</p>
                  </div>
                </Link>
              </li>
            );
          })
        )}
      </div>
      {notifs.length > 0 && (
        <div className="staffing-notifications-footer">
          <button onClick={handleRead} className="staffing-notifications-mark-read">Mark All as Read</button>
        </div>
      )}
    </div>
  );
};

export default Notifications;
