import React from 'react';
import { Grid } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';

const CompanyInfoForm = ({ customerCompany }) => {
    return (
        <Grid container spacing={1} alignItems="center" className='card p-2' style={{background: "#FFF", boxShadow: "-8px 0px 40px 0px rgba(0, 0, 0, 0.05)"}}>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <BusinessIcon sx={{ mr: 1, color: '#1976d2', fontSize: 'medium' }} />
                <span style={{ fontSize: '1rem', lineHeight: '1.5', fontWeight: '500' }}>
                    {customerCompany.companyLegalName}
                </span>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonIcon sx={{ mr: 1, color: '#ff9800', fontSize: 'small' }} />
                <span style={{ fontSize: '0.875rem', lineHeight: '1.43', color: 'rgba(0, 0, 0, 0.6)' }}>
                    {customerCompany.contact.contactPersonName}
                </span>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <PhoneIcon sx={{ mr: 1, color: '#4caf50', fontSize: 'small' }} />
                <span style={{ fontSize: '0.875rem', lineHeight: '1.43', color: 'rgba(0, 0, 0, 0.6)' }}>
                    {customerCompany.contact.phone}
                </span>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon sx={{ mr: 1, color: '#f44336', fontSize: 'small' }} />
                <span style={{ fontSize: '0.875rem', lineHeight: '1.43', color: 'rgba(0, 0, 0, 0.6)' }}>
                    {customerCompany.contact.email}
                </span>
            </Grid>
        </Grid>
    );
};

export default CompanyInfoForm;
