import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import CloseIcon from '@mui/icons-material/Close';
import { Loader, Message , RangeSlider } from 'rsuite';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import Select from 'react-select';
import { updateResourceDetails } from '../../../../services/staffingService';
import { useParams , useHistory } from 'react-router-dom';

const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
};

const schema = yup.object({
    firstName: yup.string().required('First Name is required.'),
    lastName: yup.string().required('Last Name is required.'),
    phoneNumber: yup.string(), // .required('Phone Number is required.'),
    dateOfBirth: yup.date()
        .required('Date of Birth is required.')
        .test('age', 'You must be at least 18 years old.', (value) => {
            return value ? calculateAge(value) >= 18 : false;
        })
        .transform((value, originalValue) => (originalValue === '' ? undefined : value)),
    servicesOffered: yup.array().of(yup.string()).min(1, 'At least one service must be selected.').required('Services Offered are required.'),
    minHourlyRate: yup.number()
        .min(25, "Minimum hourly rate must be at least $25.")
        .max(100, "Minimum hourly rate cannot exceed $100.")
        .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        .required('Minimum hourly rate is required.'),
    maxDistance: yup.number()
        .min(10, "maximum distance must be at least 10 miles.")
        .max(100, "maximum distance cannot exceed 100 miles.")
        .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        .required('Maximum distance is required.'),
    shiftDays: yup.array().of(yup.string()).min(1, 'At least one shift day must be selected.').required('Preferred shift days are required.'),
    shiftTimes: yup.array().of(yup.string()).min(1, 'At least one shift time must be selected.').required('Preferred shift times are required.'),
    location: yup.string().required("Your location is required."),
    emergencyContactPersonName: yup.string().required("Emergency Contact Person Name is required."),
    emergencyContactPersonPhoneNumber: yup.string() //.required("Emergency Contact Person Phone Number is required."), 

  }).required();

// location: yup.string().required("Your location is required."),

const shiftDayOptions = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' },
];

const shiftTimeOptions = [
    { value: 'Morning', label: 'Morning' },
    { value: 'Afternoon', label: 'Afternoon' },
    { value: 'Evening', label: 'Evening' },
    { value: 'Night', label: 'Night' },
];

const serviceList = [
    "standing_post",
    "mobile_patrol",
    "workplace_security",
    "event_security",
    "executive_protection",
    "emergency"
];
  
const serviceOfferedOptions = serviceList.map(service => ({
    value: service,
    label: service.replace(/_/g, ' ')
}));
  

const AddProviderResourceDetails  = () => {

  const { register, handleSubmit, control, setValue, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const {providerCompanyId} = useParams();
  const history = useHistory(); 

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [errType, setErrType] = useState('');
  const Autocomplete = ({ onPlaceSelected }) => {
    const inputRef = useRef(null);
    useEffect(() => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: "us" },
        }
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        onPlaceSelected(place);
      });
    }, [onPlaceSelected]);
    // return <input type="text" ref={inputRef} className="form-control" />;
    return (
      <div className="autocomplete-container">
        <input type="text" ref={inputRef} className="form-control" />
        <LocationSearchingIcon className="location-icon" />
      </div>
    );
  };

  const onPlaceSelected = (place) => {
    const addressComponents = place.address_components;
    const locationData = {
      formatted_address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      state: "",
      city: "",
      zipcode: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("administrative_area_level_1")) {
        locationData.state = component.long_name;
      }
      if (types.includes("locality")) {
        locationData.city = component.long_name;
      }
      if (types.includes("postal_code")) {
        locationData.zipcode = component.long_name;
      }
    });

    setSelectedLocation(locationData);
    setValue("location", locationData.formatted_address);
  };

  const handleCancel = () => {
    setSelectedLocation(null);
    setValue("location", "");
  };

  const onSubmit = async (data) => {

    try {
      setLoading(true);
      const newResource = {...data, ...selectedLocation };
      const resp = await updateResourceDetails(providerCompanyId, newResource);
      // Redirect or perform additional actions after successful creation
      history.push(`/provider-resource/${resp.data.provider_company_id}/dashboard/account`) ;
    } catch (ex) {
      setLoading(false);
      if (ex.response && ex.response.status === 404) {
        setErr(ex.response.data);
        setErrType('advance');
      }
    }
  };

  return (
    <div className="dashboard">
        <div className="container staffing-set-up-guide-container mt-3">
        <div className="staffing-set-up-guide-card card shadow-sm">
        <div className="card-body">
            {/* pick the component from here  */}
            <div className="configure-sidebar justify-content-center">
            
            <form onSubmit={handleSubmit(onSubmit)} className="configure-form">
                <div className="hr-with-text">
                    <hr />
                        <span><LocalPoliceIcon style={{color:'#13A391'}} /></span>
                    <hr />
                </div>

                <div className="advanced-setting epochs">

                    <div className="form-geoups ">
                        <div className="form-group">
                            <label className="title">First Name</label>
                            <input
                            type="text"
                            {...register('firstName')}
                            className="form-control"
                            placeholder="John"
                            />
                            {errors.firstName && <span className="errorTextForm">{errors.firstName.message}</span>}
                        </div>
                        <div className="form-group">
                            <label className="title">Last Name</label>
                            <input
                            type="text"
                            {...register('lastName')}
                            className="form-control"
                            placeholder="Doe"
                            />
                            {errors.lastName && <span className="errorTextForm">{errors.lastName.message}</span>}
                        </div>

                    </div>

                <div className="form-geoups">
                    <div className="form-group" style={{width:'40%'}}>
                        <label className="title">Date of Birth</label>
                        <input
                        type="date"
                        {...register('dateOfBirth')}
                        className="form-control"
                        />
                        {errors.dateOfBirth && <span className="errorTextForm">{errors.dateOfBirth.message}</span>}
                    </div>
                    <div className="form-group" style={{width:'60%'}}>
                        <label className="title">Phone Number</label>
                        <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field }) => (
                            <PhoneInput
                            defaultCountry="US"
                            value={field.value}
                            onChange={field.onChange}
                            />
                        )}
                        />
                        {errors.phoneNumber && <span className="errorTextForm">{errors.phoneNumber.message}</span>}
                    </div>
                </div>

                <div className="form-geoups" >
                    <div className="form-group" style={{ width: "100%" }}>
                        <label className="title">Address</label>
                        {selectedLocation ? (
                            <div className="selected-location">
                            <span className="">{selectedLocation.formatted_address}</span>
                            <button
                                type="button"
                                className="btn btn-link"
                                onClick={handleCancel}
                            >
                                {" "}
                                <CloseIcon className="staffing-cancel-icon" />{" "}
                            </button>
                            {/* <CloseIcon onClick={handleCancel} style={{fontSize:'18px'}} />  */}
                            </div>
                        ) : (
                            <Controller
                            name="location"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Autocomplete
                                onPlaceSelected={(place) => {
                                    field.onChange(place.formatted_address);
                                    onPlaceSelected(place);
                                }}
                                />
                            )}
                            />
                        )}
                        {errors.location && <span className="errorTextForm">{errors.location.message}</span>}
                    </div>
                </div>

                <div className="form-geoups">
                    <div className="form-group" style={{width:'100%'}}>
                        <label className="title">Services Offered</label>
                        <Controller
                        name="servicesOffered"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <Select
                            isMulti
                            options={serviceOfferedOptions}
                            value={field.value.map(service => ({
                                value: service,
                                label: serviceOfferedOptions.find(option => option.value === service)?.label
                            }))}
                            onChange={(selected) => field.onChange(selected.map(option => option.value))}
                            />
                        )}
                        />
                        {errors.servicesOffered && <span className="errorTextForm">{errors.servicesOffered.message}</span>}
                    </div>
                </div>

                {/* Shift Days Picker */}
                <div className="form-geoups">

                    <div className="form-group">
                      <label className="title">Preferred Shift Days</label>
                      <Controller
                        name="shiftDays"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <Select
                            isMulti
                            options={shiftDayOptions}
                            value={field.value.map(day => ({ value: day, label: day }))}
                            onChange={(selected) => field.onChange(selected ? selected.map(option => option.value) : [])}
                            onBlur={() => field.onBlur()} // Trigger blur event to show error
                          />
                        )}
                      />
                      {errors.shiftDays && <span className="errorTextForm">{errors.shiftDays.message}</span>}
                    </div>

                    <div className="form-group">
                      <label className="title">Preferred Shift Times</label>
                      <Controller
                        name="shiftTimes"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <Select
                            isMulti
                            options={shiftTimeOptions}
                            value={field.value.map(time => ({ value: time, label: time }))}
                            onChange={(selected) => field.onChange(selected ? selected.map(option => option.value) : [])}
                          />
                        )}
                      />
                      {errors.shiftTimes && <span className="errorTextForm">{errors.shiftTimes.message}</span>}
                    </div>
                </div>

                <div className="form-geoups">
                    <div className="form-group">
                    <label className="title"> Min. Hourly Pay Rate ($) </label>
                    <div className="mt-1">
                        <input
                        type="number"
                        {...register("minHourlyRate")}
                        className="form-control"
                        placeholder={48}
                        />

                        {errors.minHourlyRate && (
                        <span className="errorTextForm">
                            {errors.minHourlyRate.message}
                        </span>
                        )}
                    </div>
                    </div>

                    <div className="form-group">
                    <label className="title">Max. Distance In Miles </label>
                    <input
                        type="number"
                        {...register("maxDistance")}
                        className="form-control"
                        // defaultValue={1}
                        placeholder={25}
                    />
                    {errors.maxDistance && (
                        <span className="errorTextForm">
                        {errors.maxDistance.message}
                        </span>
                    )}
                    </div>
                </div>

                {/* <div className="hr-with-text">
                    <hr />
                        <span><MedicalServicesIcon style={{color:'#13A391'}} /></span>
                    <hr />
                </div> */}
                <hr />

                <div className="form-geoups">
                    <div className="form-group">
                        <label className="title">Emergency Contact Person </label>
                        <div className="mt-1">
                            <input
                            type="text"
                            {...register("emergencyContactPersonName")}
                            className="form-control"
                            placeholder="Full Name"
                            />
                            {errors.emergencyContactPersonName && (
                            <span className="errorTextForm">
                                {errors.emergencyContactPersonName.message}
                            </span>
                            )}
                        </div>
                    </div>

                    <div className="form-group">
                    <label className="title">Emergency Contact </label>
                    <div className="mt-1">
                        <PhoneInput
                        defaultCountry="US"
                        onChange={(value) => setValue("emergencyContactPersonPhoneNumber", value)}
                        // className="form-control"
                        />
                        {errors.emergencyContactPersonPhoneNumber && (
                        <span className="errorTextForm">
                            {errors.emergencyContactPersonPhoneNumber.message}
                        </span>
                        )}
                    </div>
                    </div>
                </div>

                <div className="text-center">
                    <button
                    type="submit"
                    className="staffing-btn confirm-btn"
                    >
                    Confirm
                    </button>
                </div>

                </div>
            </form>

            {loading && <Loader backdrop center />}
            {err && errType === 'advance' && (
                <Message
                showIcon
                type="error"
                className="errorTextForm text-center"
                >
                {err}
                </Message>
            )}
            </div>
            {/* until here  */}
        </div>
        </div>
        </div>
    </div> 
  );
};

export default AddProviderResourceDetails ;
