// PaymentsTab.js
import React, { useState } from 'react';
import PaymentsIcon from '@mui/icons-material/Payments';
import { createStripeAccountProviderResource, completeStripeOnboardingProviderResource } from '../../../../services/staffingService';
import { Link } from 'react-router-dom';
import { Loader } from 'rsuite';

const PaymentsTab = ({ providerCompanyId, providerResource }) => {
    const [loading, setLoading] = useState(false);

    // Function to initiate Stripe account creation
    const createNewStripeAccount = async () => {
        setLoading(true);
        try {
            const resp = await createStripeAccountProviderResource(providerCompanyId);
            window.location.href = resp.data.accountLink;
        } catch (error) {
            setLoading(false);
            console.error("Failed to create Stripe account link:", error);
        }
    };

    // Function to complete Stripe onboarding
    const completeStripeOnboarding = async () => {
        setLoading(true);
        try {
            const resp = await completeStripeOnboardingProviderResource(providerCompanyId);
            window.location.href = resp.data.accountLink;
        } catch (error) {
            setLoading(false);
            console.error("Failed to complete Stripe onboarding:", error);
        }
    };

    return (
        <div className="p-4">
            {/* Stripe account not created */}
            {(!providerResource.stripeAccount || !providerResource.stripeAccount.id) && (
                <div className="d-flex align-items-start account_card">
                    <PaymentsIcon className="checkboxUnfinished" />
                    <div>
                        <h6 className="mb-1 staffing-set-up-guide-title">Create Your Stripe Account</h6>
                        <p style={{ color: '#5f6368', fontSize: '14px' }}>
                            Set up your Stripe account to start managing payments and transactions securely.
                        </p>
                        <button onClick={createNewStripeAccount} className="staffing-set-up-guide-link">Create Account</button>
                    </div>
                </div>
            )}
            {/* Stripe account created but not verified */}
            {providerResource.stripeAccount && !providerResource.stripeAccount.details_submitted && (
                <div className="d-flex align-items-start account_card">
                    <PaymentsIcon className="checkboxUnfinished" />
                    <div>
                        <h6 className="mb-1 staffing-set-up-guide-title">Complete Your Stripe Setup</h6>
                        <p style={{ color: '#5f6368', fontSize: '14px' }}>
                            Your account is not yet fully verified by Stripe to receive job offers.
                        </p>
                        <button onClick={completeStripeOnboarding} className="staffing-set-up-guide-link">Complete Verification</button>
                    </div>
                </div>
            )}
            {/* Stripe account fully verified */}
            {providerResource.stripeAccount?.charges_enabled &&
            providerResource.stripeAccount.details_submitted &&
            providerResource.stripeAccount.payouts_enabled && (
                <div className="d-flex align-items-start account_card">
                    <PaymentsIcon className="checkboxSuccess" />
                    <div>
                        <h6 className="mb-1 staffing-set-up-guide-title">Congratulations! Your Stripe account is verified and ready.</h6>
                        <p style={{ color: '#5f6368', fontSize: '14px' }}>
                            Your account is fully verified and you can start transactions.
                        </p>
                        <Link to="https://dashboard.stripe.com/dashboard">
                            <button className="staffing-set-up-guide-link">Go to Dashboard</button>
                        </Link>
                    </div>
                </div>
            )}
            {/* Loading spinner */}
            {loading && <Loader backdrop center />}
        </div>
    );
};

export default PaymentsTab;
