const PartnerSuccess = () => {
    return (
        <div className="mt-5 p-5 bg-light border rounded">
            <p className="font-weight-bold">
                RefineAI's Partner Success Module
            </p>
            <p className="mb-3">
                An extensive, curated database designed to support the success of our partners by providing access to:
            </p>
            <ul className="mb-3">
                <li>Prospective customers.</li>
                <li>A network of officers and defense veterans</li>
            </ul>
            <p>
                This module is currently under development and is expected to be available by the end of Q1 2025.
            </p>
        </div>
    );
}


export default PartnerSuccess;
