import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TimerIcon from '@mui/icons-material/Timer';
import EditIcon from '@mui/icons-material/Edit'; // Import Edit icon from MUI
import { useProviderCompany } from "../../../../../context/ProviderCompanyContext";
import { updateProviderTimeKeepingPreferences } from '../../../../../services/staffingService';

const timeKeepingSchema = yup.object().shape({
    checkInStart: yup.number().min(0).max(60).required('Check-in start is required'),
    checkInEnd: yup.number().min(0).max(60).required('Check-in end is required'),
    shiftLength: yup.number().min(1).required('Minimum shift length is required'),
    checkoutValidUntil: yup.number().min(0).max(60).required('Checkout valid until is required'), // New field
});

const TimeKeeping = ({providerCompanyId}) => {
    const { providerCompanyData, fetchProviderCompanyData } = useProviderCompany(); // Use providerCompanyData from context
    const [isEditing, setIsEditing] = useState(false);

    // React Hook Form setup with yup resolver
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(timeKeepingSchema),
        defaultValues: {
            checkInStart: 0, // Initial default values will be reset with actual data
            checkInEnd: 0,
            shiftLength: 0,
            checkoutValidUntil: 0, // New default value
        },
    });

    // Populate the form when providerCompanyData changes
    useEffect(() => {
        if (providerCompanyData && providerCompanyData.preferences?.timeKeeping) {
            reset({
                checkInStart: providerCompanyData?.preferences.timeKeeping.checkInStartsAtMinutesBefore || 0,
                checkInEnd: providerCompanyData?.preferences.timeKeeping.checkInEndsAtMinutesAfter || 0,
                shiftLength: providerCompanyData?.preferences.timeKeeping.minimumShiftLengthHours || 0,
                checkoutValidUntil: providerCompanyData?.preferences.timeKeeping.checkoutValidUntilMinutesAfter || 0, // New field for checkout
            });
        }
    }, [providerCompanyData, reset]);

    // Handle form submission
    const onSubmit = async (data) => {
        try {
            const timeKeepingData = {
                checkInStartsAtMinutesBefore: data.checkInStart,
                checkInEndsAtMinutesAfter: data.checkInEnd,
                minimumShiftLengthHours: data.shiftLength,
                checkoutValidUntilMinutesAfter: data.checkoutValidUntil, // New field
            };

            // Update backend with new time keeping preferences
            await updateProviderTimeKeepingPreferences(providerCompanyData._id, timeKeepingData);

            // Fetch updated provider company data to refresh the context
            fetchProviderCompanyData(providerCompanyId);

            // Switch back to view mode
            setIsEditing(false);
        } catch (error) {
            console.error("Failed to update time keeping preferences:", error);
        }
    };

    // Toggle edit mode
    const toggleEditMode = () => {
        setIsEditing(!isEditing);
        if (!isEditing && providerCompanyData?.preferences?.timeKeeping) {
            // Prepopulate the form when switching to edit mode
            setValue('checkInStart', providerCompanyData.preferences.timeKeeping.checkInStartsAtMinutesBefore || 0);
            setValue('checkInEnd', providerCompanyData.preferences.timeKeeping.checkInEndsAtMinutesAfter || 0);
            setValue('shiftLength', providerCompanyData.preferences.timeKeeping.minimumShiftLengthHours || 0);
            setValue('checkoutValidUntil', providerCompanyData.preferences.timeKeeping.checkoutValidUntilMinutesAfter || 0); // New field
        }
    };

    return (
        <div className="d-flex align-items-start account_card">
            <TimerIcon className="checkboxSuccess" />
            <div>
                {/* Title with Edit button */}
                <h6 className="mb-1 staffing-set-up-guide-title">
                    Time Keeping
                    {!isEditing && (
                        <EditIcon
                            onClick={toggleEditMode}
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                        />
                    )}
                </h6>

                {/* Display mode or Edit mode */}
                {isEditing ? (
                    <div className="configure-sidebar justify-content-center">
                        <form onSubmit={handleSubmit(onSubmit)} className="configure-form">
                            <div className="advanced-setting epochs">
                                <div className="form-geoups mt-4">
                                    <div className="form-group" style={{ width: '100%' }}>
                                        <label className="title">Check-in starts at (minutes before):</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            {...register('checkInStart')}
                                        />
                                        <span className="errorTextForm">{errors.checkInStart?.message}</span>

                                        <label className="title mt-2">Check-in ends at (minutes after):</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            {...register('checkInEnd')}
                                        />
                                        <span className="errorTextForm">{errors.checkInEnd?.message}</span>

                                        {/* New checkout valid field */}
                                        <label className="title mt-2">Checkout valid until (minutes after shift):</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            {...register('checkoutValidUntil')}
                                        />
                                        <span className="errorTextForm">{errors.checkoutValidUntil?.message}</span>

                                        <label className="title mt-2">Minimum shift length (hours):</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            {...register('shiftLength')}
                                        />
                                        <span className="errorTextForm">{errors.shiftLength?.message}</span>



                                        <button className="staffing-btn confirm-btn" type="submit">
                                            Save Time Keeping
                                        </button>
                                        <button
                                            className="staffing-btn cancel-btn ml-2"
                                            type="button"
                                            onClick={() => setIsEditing(false)} // Cancel button functionality
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div>
                        {/* View mode: Display current values */}
                        <p
                            className="mb-2 staffing-set-up-guide-description"
                            style={{ color: '#5f6368', fontSize: '14px' }}
                        >
                            Check-in starts at {providerCompanyData?.preferences?.timeKeeping?.checkInStartsAtMinutesBefore || 0} minutes before, ends {providerCompanyData?.preferences?.timeKeeping?.checkInEndsAtMinutesAfter || 0} minutes after.
                        </p>
                        <p
                            className="mb-2 staffing-set-up-guide-description"
                            style={{ color: '#5f6368', fontSize: '14px' }}
                        >
                            Check-out valid until {providerCompanyData?.preferences?.timeKeeping?.checkoutValidUntilMinutesAfter || 0} minutes after shift.
                        </p>
                        <p
                            className="mb-2 staffing-set-up-guide-description"
                            style={{ color: '#5f6368', fontSize: '14px' }}
                        >
                            Minimum shift length is {providerCompanyData?.preferences?.timeKeeping?.minimumShiftLengthHours || 0} hours.
                        </p>

                    </div>
                )}
            </div>
        </div>
    );
};

export default TimeKeeping;
