import '../../../../styles/unassignedListView.css';
import { Badge } from "@mui/material";
import { useEffect, useState } from "react";
import SideDrawer from "./sideDrawer";
import Send from '../../../../../_asset/img/send-2.svg';
import JobStreetView from "../../offers/status/jobStreetView";

const UnassignedListView = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [getByItem, setGetByItem] = useState(null);

    useEffect(() => {
        if(!open){
            setGetByItem(null);
        }
    }, [open]);
    

    return (
        <div className="unassigne_content">
            <table class="table border-0">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Required</th>
                        <th>Start</th>
                        <th>Acceptance Rate</th>
                        <th>Tag</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody id="data-body">
                    {data.length > 0 && data.map((item, index) => {
                        const { totalShifts, uniqueDays } = item.shiftInfo;

                        const shiftsText = `${totalShifts} shift${totalShifts !== 1 ? 's' : ''}`;
                        const daysText = `${uniqueDays} day${uniqueDays !== 1 ? 's' : ''}`;

                        const jobDescription = `${shiftsText} over ${daysText}`;
                        return (
                            <tr key={index}>
                                <td>
                                    <div className="user_details">
                                        <div className="profile">
                                            <JobStreetView job={item} />
                                        </div>
                                        <div className="details">
                                            <h4 className="name">{item.customer_company_id.companyFriendlyName}</h4>
                                            <p className="address">{item.formattedAddress}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="required_col">1 staff</p>
                                </td>
                                <td>
                                    <p className="start_col">{jobDescription}</p>
                                </td>
                                <td>
                                    <p className="rate_col">56%</p>
                                </td>
                                <td className="badge_tab">
                                    <Badge>
                                        #{item.service.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </Badge>
                                </td>
                                <td>
                                    <span className="share_icon" onClick={() => {setOpen(true); setGetByItem(item)}}>
                                        <img src={Send} alt="" />
                                    </span>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <SideDrawer isOpen={open} setIsOpen={setOpen} getByItem={getByItem} />
        </div>
    )
};

export default UnassignedListView;