import "../../styles/calendarModal.css"; 
import React from 'react';
import Modal from 'react-modal';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const ShiftCalendar = ({ job, offer, calendarIsOpen, closeShiftCalendar }) => {

    const events = offer.shifts.map(shift => ({
        title: `Shift @ ${job.formattedAddress}`,
        start: new Date(shift.checkIn.timeWindow.start),
        end: new Date(shift.checkOut.timeWindow.end),
        desc: `Shift ID: ${shift._id}`
    }));

    const defaultDate = events.length > 0 ? events[0].start : new Date();

    return (
        <Modal
            isOpen={calendarIsOpen}
            onRequestClose={closeShiftCalendar}
            className="shift-calendar-modal"
            overlayClassName="shift-calendar-modal-overlay"
            ariaHideApp={false}
        >
            <div className='calendar-agenda-modal-container'>
                <div className='calendar-agenda-modal-header'>
                    <button onClick={closeShiftCalendar} style={{ float: 'right', cursor: 'pointer' }}>Close</button>
                    {/* 
                    <div className="text-center mb-4">
                        <h3 style={{ color: '#3D4784' }}>Shift Schedule</h3>
                    </div> 
                    */}
                </div>
                <div className='calendar-agenda-modal-body'>
                    <Calendar
                        className="mt-2 p-2"
                        defaultView='week'
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        defaultDate={defaultDate}
                        style={{ height: '100%' }} 
                        messages={{ noEventsInRange: 'There are no shifts in this range.' }}
                    />
                </div>
                <div className='calendar-agenda-modal-footer'>
                    <button className="rejection-modal-btn">Add to Calendar</button>
                </div>
            </div>
        </Modal>
    );
};

export default ShiftCalendar;
