import React, { useRef, useEffect } from 'react';
import '../../styles/listingmap.css';

function RouteMap({ source, destination }) {
  const GoogleMapRef = useRef(null);

  const position = { lat: source.coordinates[1], lng: source.coordinates[0] };
  const destinationPosition = { lat: destination.coordinates[1], lng: destination.coordinates[0] };

  useEffect(() => {
    const map = new window.google.maps.Map(GoogleMapRef.current, {
      center: position,
      zoom: 13,
      disableDefaultUI: true, 
      zoomControl: false
    });

    // Directions Service
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: '#db2677',
      },
      suppressMarkers: true
    });
    directionsRenderer.setMap(map);

    const request = {
      origin: position,
      destination: destinationPosition,
      travelMode: 'DRIVING',
    };

    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(result);
        
        // Extract midpoint for the popup window
        const route = result.routes[0].overview_path;
        const midpoint = route[Math.floor(route.length / 2)];
        
        // Distance Matrix Service
        const distanceService = new window.google.maps.DistanceMatrixService();
        distanceService.getDistanceMatrix(
          {
            origins: [position],
            destinations: [destinationPosition],
            travelMode: 'DRIVING',
          },
          (response, status) => {
            if (status === 'OK') {
              const distanceText = response.rows[0].elements[0].distance.text;
              const duration = response.rows[0].elements[0].duration.text;
              
              // Convert distance to miles if necessary
              const distanceInMeters = response.rows[0].elements[0].distance.value;
              const distanceInMiles = (distanceInMeters / 1609.34).toFixed(2); // Convert meters to miles

              // Display the distance and duration on the map
              const infoWindow = new window.google.maps.InfoWindow({
                content: `
                <div class="custom-info-window">
                It's about ${duration} to get there <br/> 
                by car.
              </div>
                        `,
                position: midpoint
              });
              infoWindow.open(map);
            }
          }
        );
      }
    });

    new window.google.maps.Marker({
      position,
      map,
      title: 'Source',
      icon: {
        url: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1717535422/policeman-blue.png',
        scaledSize: new window.google.maps.Size(40, 40),
      },
    });

    new window.google.maps.Marker({
      position: destinationPosition,
      map,
      title: 'Destination',
      icon: {
        url: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1717535422/residential-blue.png', 
        scaledSize: new window.google.maps.Size(40, 40),
      },
    });
    
  }, [position, destinationPosition]);

  return (
    <div className="route-map">
      <div ref={GoogleMapRef} style={{ height: '420px', width: '100%' , borderRadius: '8px' }}></div>
    </div>
  );
}

export default RouteMap;
