import React, { useEffect, useState, useRef } from "react";
import CheckCircleIcon from "./utils/CheckCircleIcon";
import CompanyInformation from "./utils/companyDetails";

import {
  createNewJob,
  getCustomerCompany,
} from "../../../../services/staffingService";
import { Redirect, Link, useParams, useHistory } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CloseIcon from "@mui/icons-material/Close";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";

import { useUser } from "../../../../context/UserContext";
import { useProviderCompany } from "../../../../context/ProviderCompanyContext";
import { Loader, Modal } from "rsuite";

import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Message, TagGroup, Tag } from "rsuite";
import ShiftPicker from "./utils/DateTimePicker";

import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import moment from "moment";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";


import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DashboardSidebar from "../../../common/dashboardSidebar";
import Header from "../../../common/header";
import { ReactComponent as SendUp } from '../../../_asset/img/send_up.svg';
import NewCustomer from "./customerform";

function createStreetViewLink(lat, lng) {
  return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`;
}

const schema = yup
  .object({
    serviceType: yup.string().required("Service Type is required."),
    hourlyPayRate: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      )
      .required("Hourly offer price is required.")
      .positive("Hourly offer price must be positive.")
      .min(7, "Hourly offer price must be at least $7")
      .max(250, "Hourly offer price cannot exceed $250"),
    location: yup.string().required("Job location is required."),
    shifts: yup
      .array()
      .of(
        yup.object().shape({
          date: yup.date().required("Date is required"),
          startTime: yup
            .date()
            .required("Start time is required")
            .test(
              "is-future",
              "Shift start time cannot be in the past",
              function (value) {
                const { date } = this.parent;
                const startTime = moment(value).set({
                  year: moment(date).year(),
                  month: moment(date).month(),
                  date: moment(date).date(),
                });
                return startTime.isSameOrAfter(moment(), "minute");
              }
            ),
          endTime: yup
            .date()
            .required("End time is required")
            .test(
              "is-future",
              "Shift end time cannot be in the past",
              function (value) {
                const { date } = this.parent;
                const endTime = moment(value).set({
                  year: moment(date).year(),
                  month: moment(date).month(),
                  date: moment(date).date(),
                });
                return endTime.isSameOrAfter(moment(), "minute");
              }
            )
            .test(
              "is-after-start",
              "Shift end time must be after shift start time",
              function (value) {
                const { startTime } = this.parent;
                return moment(value).isAfter(moment(startTime), "minute");
              }
            ),
          /* ======= only for testing ========= 
            .test(
              "is-minimum-duration",
              "Shift must be at least 4 hours",
              function (value) {
                const { startTime } = this.parent;
                return moment(value).diff(moment(startTime), "hours") >= 4;
              }
            ),
            */
          staffsRequired: yup
            .number()
            .required("Staffs required is required")
            .min(1, "At least 1 staff is required"),
        })
      )
      .min(1, "At least one shift is required"),
    memo: yup
      .string()
      .max(255, "Memo cannot exceed 255 characters")
      .matches(/^[a-zA-Z0-9 .,!?]*$/, "Memo contains invalid characters")
      .nullable(),
  })
  .required();


  const NewOrder = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [serviceType, setServiceType] = useState("1");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [oneclickModel, setOneclickModel] = useState("ada");
  const history = useHistory();
  const { providerCompanyId, customerCompanyId } = useParams();
  const [err, setErr] = useState("");
  const [errType, setErrType] = useState("");
  const [posts, setPosts] = useState([]); // State to track added markers (posts)

  const [customerCompany, setCustomerCompany] = useState({});
  const mapRef = useRef(null);
  const postsMarkersRef = useRef([]);
  const selectedMarkerRef = useRef(null);
  const [showSideDrawer, setShowSideDrawer] = useState(!false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getCustomerCompanyById();
  }, []);

  const getCustomerCompanyById = async () => {
    const resp = await getCustomerCompany(providerCompanyId, customerCompanyId);
    setCustomerCompany(resp.data.customerCompany);

  };

  const handleChange = (e) => {
    setServiceType(e.target.value);
  };

  const Autocomplete = ({ onPlaceSelected }) => {
    const inputRef = useRef(null);

    useEffect(() => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: "us" },
        }
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        onPlaceSelected(place);
      });
    }, [onPlaceSelected]);

    // return <input type="text" ref={inputRef} className="form-control" />;
    return (
      <div className="autocomplete-container">
        <input type="text" ref={inputRef} className="form-control" />
        <LocationSearchingIcon className="location-icon" />
      </div>
    );
  };

  const onPlaceSelected = (place) => {
    const addressComponents = place.address_components;
    const locationData = {
      formatted_address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      state: "",
      city: "",
      zipcode: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("administrative_area_level_1")) {
        locationData.state = component.long_name;
      }
      if (types.includes("locality")) {
        locationData.city = component.long_name;
      }
      if (types.includes("postal_code")) {
        locationData.zipcode = component.long_name;
      }
    });

    setSelectedLocation(locationData);
    setValue("location", locationData.formatted_address);
  };

  useEffect(() => {
    if (window.google && mapRef.current) {
      if (!mapRef.current.map) {
        mapRef.current.map = new window.google.maps.Map(mapRef.current, {
          center: { lat: 42.3662986, lng: -71.06216222263835 },
          zoom: 14,
          tilt: 0,
          gestureHandling: "greedy",
          mapTypeControl: true
        });
      }

      const map = mapRef.current.map;

      // Create the floating input div (hidden by default)
      const inputDiv = document.createElement("div");
      inputDiv.style.position = "absolute";
      inputDiv.style.zIndex = "1000";
      inputDiv.style.display = "none";
      inputDiv.innerHTML = `
        <div style="background-color: white; padding: 15px; border-radius: 8px; box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); width: 250px; font-family: Arial, sans-serif; font-size: 12px;">
          <div style="font-weight: bold; margin-bottom: 10px;">Add Post</div>
          <input type="text" id="markerNote" placeholder="Enter post name" style="width: 100%; padding: 8px; border-radius: 5px; border: 1px solid #ccc; font-size: 12px;" />
          <div style="margin-top: 10px; display: flex; justify-content: flex-end; gap: 10px;">
            <button id="closeMarkerNote" style="background-color: #f0f0f0; color: #555; border: none; padding: 6px 12px; border-radius: 5px; cursor: pointer; font-size: 12px;">
              Cancel
            </button>
            <button id="saveMarkerNote" style="background-color: #DB2677; color: white; border: none; padding: 6px 12px; border-radius: 5px; cursor: pointer; font-size: 12px;">
              Save
            </button>
          </div>
        </div>
      `;
      document.body.appendChild(inputDiv);

      // Add the listener for the right-click (contextmenu) event
      const addMarkerOnContextmenu = map.addListener("contextmenu", (event) => {
        // event.preventDefault();  // Prevent the default right-click context menu
        event.domEvent.preventDefault()

        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const location = { lat, lng };

        // Show the input div at the clicked position
        inputDiv.style.display = "block";
        inputDiv.style.left = `${event.pixel.x}px`;
        inputDiv.style.top = `${event.pixel.y}px`;

        // Focus on the input field
        const input = document.getElementById("markerNote");
        input.focus();

        // Save marker note
        document.getElementById("saveMarkerNote").onclick = function () {
          const note = input.value;
          if (note) {
            setPosts((prevPosts) => [
              ...prevPosts,
              { position: location, note },
            ]);

            // Add marker to the map
            const marker = new window.google.maps.Marker({
              position: location,
              map,
              title: "Post Marker",
            });

            postsMarkersRef.current.push(marker); // Store marker reference

            const infoWindowContent = `
              <div style="padding: 10px; font-family: Arial, sans-serif; background-color: #fff; border-radius: 8px; box-shadow: 0px 0px 10px rgba(0,0,0,0.1);">
                <strong>Post:</strong> <br/> 
                <span style="color: #007bff;">${note}</span>
              </div>
            `;
            const infoWindow = new window.google.maps.InfoWindow({
              content: infoWindowContent,
            });

            marker.addListener("click", () => {
              infoWindow.open(map, marker);
            });
          }

          // Hide and reset the input box
          inputDiv.style.display = "none";
          input.value = "";
        };

        // Add event listener for the close button
        document.getElementById("closeMarkerNote").onclick = function () {
          // Hide the input box without saving
          inputDiv.style.display = "none";
          input.value = "";
        };
      });

      // Handle the case for clearing posts and other map interactions
      if (selectedLocation) {
        const { latitude, longitude } = selectedLocation;
        map.setCenter({ lat: latitude, lng: longitude });

        const marker = new window.google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map,
          title: "Selected Location",
          icon: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        });

        selectedMarkerRef.current = marker;  // Store the selected marker reference

        const infoWindow = new window.google.maps.InfoWindow({
          content: `<div><strong>Selected Location</strong></div>`,
        });

        marker.addListener("click", () => {
          infoWindow.open(map, marker);
        });
      }

      posts.forEach((post) => {
        const marker = new window.google.maps.Marker({
          position: post.position,
          map,
          title: "Post",
        });

        postsMarkersRef.current.push(marker);

        const infoWindowContent = `
          <div style="padding: 10px; font-family: Arial, sans-serif; background-color: #fff; border-radius: 8px; box-shadow: 0px 0px 10px rgba(0,0,0,0.1);">
            <strong>Post:</strong> <br/> 
            <span style="color: #007bff;">${post.note}</span>
          </div>
        `;
        const infoWindow = new window.google.maps.InfoWindow({
          content: infoWindowContent,
        });

        marker.addListener("click", () => {
          infoWindow.open(map, marker);
        });
      });

      // Clean up the listener when the component is unmounted
      return () => {
        window.google.maps.event.removeListener(addMarkerOnContextmenu);
        document.body.removeChild(inputDiv);  // Remove the input div on unmount
      };
    }
  }, [selectedLocation, posts]);


  const handleCancel = () => {
    // Clear selected location and form values
    setSelectedLocation(null);
    setValue("location", "");

    // Clear posts
    setPosts([]);

    // Remove all post markers from the map
    postsMarkersRef.current.forEach((marker) => marker.setMap(null));
    postsMarkersRef.current = []; // Clear the reference array

    // Remove the selected location marker, if exists
    if (selectedMarkerRef.current) {
      selectedMarkerRef.current.setMap(null);
      selectedMarkerRef.current = null; // Clear the reference
    }
  };

  const addShift = () => {
    const shifts = getValues("shifts");
    setValue("shifts", [
      ...shifts,
      { date: null, startTime: null, endTime: null },
    ]);
  };

  const removeShift = (index) => {
    const shifts = getValues("shifts");
    shifts.splice(index, 1);
    setValue(
      "shifts",
      shifts.length > 0
        ? shifts
        : [{ date: null, startTime: null, endTime: null }]
    );
  };

  const handleShiftChange = (index, updatedShift) => {
    const shifts = getValues("shifts");
    shifts[index] = updatedShift;
    setValue("shifts", shifts);
  };

  const defaultShifts = [
    {
      date: null,
      startTime: null,
      endTime: null,
    },
  ];

  const serviceList = [
    "standing_post",
    "mobile_patrol",
    "workplace_security",
    "event_security",
    "executive_protection",
    "emergency",
  ];

  const getValueAtPosition = (position) => {
    const index = parseInt(position, 10) - 1;
    return index >= 0 && index < serviceList.length
      ? serviceList[index]
      : "Invalid position";
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const newWorkOrder = {
        ...data,
        status: "published",
        ...selectedLocation,
        service: getValueAtPosition(data.serviceType),
        posts,
      };
      let resp = await createNewJob(
        newWorkOrder,
        providerCompanyId,
        customerCompanyId
      );
      history.push(
        `/provider-company/${resp.data.providerCompanyId}/jobs`
      );
    } catch (ex) {
      setLoading(false);
      if (ex.response && ex.response.status === 404) {
        setErr(ex.response.data);
        setErrType("advance");
      }
    }
  };

  const onSubmitDraft = async (data) => {
    const newCustomerOrder = {
      ...data,
      status: "draft", // Status for Save As Draft button
      ...selectedLocation,
      service: getValueAtPosition(data.serviceType),
    };
    console.log(newCustomerOrder);
    // Your existing submission logic for drafts
  };

  const generateLabels = (min, max, step) => {
    let labels = [];
    for (let i = min; i <= max; i += step) {
      labels.push(i);
    }
    return labels;
  };

  const handleClose = () => {
    setOpen(false);
}

  return (
    <div className="dashboard">
      <DashboardSidebar isOpen={showSideDrawer} />
      <div className='dashboard_content'>
        <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} breadcrumb={`${customerCompany.companyLegalName} > New Work Order`}/>
        <div className='customer_page'>
          {/* <div className='page_title'>
            <div className='left_side'>
              <h2>Customers</h2>
              <p>Manage all of the customers that you have</p>
            </div>
            <div className='right_side'>
              <button className='count_of_customer_btn'><span className='count_of_customer'>84</span> 584 Customers Around You
                <SendUp /></button>
              <button className='create_customer_btn' onClick={() => setOpen(true)}>+ Create New Customer</button>
            </div>
          </div> */}
          <div className="customer_inner_content">
            {/* Map Container */}
            <div
              ref={mapRef}
              style={{
                height: "100%",
                width: "calc(100% - 750px)", // Map takes 50% width
                position: "relative",
                padding: "10px",
                zIndex: 1, // Lower z-index for map
                // overflow: "hidden !important",
              }}
            ></div>

            <div
              className="dashboard"
              style={{
                position: "relative",
                zIndex: 2, // Form on top of map
                background: "white", // Semi-transparent white background for form
                height: "calc(100vh - 89px)",
                overflowY: "auto",
                minHeight: "unset",
                width: "750px", // Optional: Adjust form width if needed
                margin: "auto",
                padding: "20px",
              }}
              id="scrollBar"
            >
              <div className="order-form-container">
                <div className="order-form-container-card p-4">
                  <div className="configure-sidebar justify-content-center">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="configure-form"
                    >
                      {/* <div class="hr-with-text mt-0">
                        <hr />
                        <span>
                          <LocalPoliceIcon style={{ color: "#13A391" }} />
                        </span>
                        <hr />
                      </div> */}
                      <div className="advanced-setting">
                        <div className="advanced-tab">
                          <div
                            className={`form-group ${serviceType === "1" ? "active" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              {...register("serviceType")}
                              defaultChecked
                              value="1"
                              onChange={(e) => handleChange(e)}
                              id="Advanced1"
                            />
                            {serviceType === "1" && <CheckCircleIcon />}
                            <label htmlFor="Advanced1">Standing Post </label>
                          </div>

                          <div
                            className={`form-group ${serviceType === "2" ? "active" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              {...register("serviceType")}
                              value="2"
                              onChange={(e) => handleChange(e)}
                              id="Advanced2"
                            />
                            {serviceType === "2" && <CheckCircleIcon />}
                            <label htmlFor="Advanced2"> Mobile Patrol </label>
                          </div>

                          <div
                            className={`form-group ${serviceType === "3" ? "active" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              {...register("serviceType")}
                              value="3"
                              onChange={(e) => handleChange(e)}
                              id="Advanced2"
                            />
                            {serviceType === "3" && <CheckCircleIcon />}
                            <label htmlFor="Advanced2"> Workplace Security</label>
                          </div>
                        </div>

                        <div className="advanced-tab mt-1">
                          <div
                            className={`form-group ${serviceType === "4" ? "active" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              {...register("serviceType")}
                              value="4"
                              onChange={(e) => handleChange(e)}
                              id="Advanced2"
                            />
                            {serviceType === "4" && <CheckCircleIcon />}
                            <label htmlFor="Advanced2"> Event Security </label>
                          </div>

                          <div
                            className={`form-group ${serviceType === "5" ? "active" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              {...register("serviceType")}
                              value="5"
                              onChange={(e) => handleChange(e)}
                              id="Advanced2"
                            />
                            {serviceType === "5" && <CheckCircleIcon />}
                            <label htmlFor="Advanced2"> Executive Protection </label>
                          </div>

                          <div
                            className={`form-group ${serviceType === "6" ? "active" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              {...register("serviceType")}
                              value="6"
                              onChange={(e) => handleChange(e)}
                              id="Advanced2"
                            />
                            {serviceType === "6" && <CheckCircleIcon />}
                            <label htmlFor="Advanced2"> Emergency</label>
                          </div>
                        </div>
                      </div>

                      <div className="advanced-setting epochs">
                        <div className="form-geoups" style={{ width: "100%" }}>
                          <div className="form-group" style={{ width: "100%" }}>
                            <label className="title">Location</label>
                            {selectedLocation ? (
                              <div className="selected-location">
                                <span className="">
                                  {selectedLocation.formatted_address}
                                </span>
                                <button
                                  type="button"
                                  className="btn btn-link"
                                  onClick={handleCancel}
                                >
                                  {" "}
                                  <CloseIcon className="staffing-cancel-icon" />{" "}
                                </button>
                                {/* <CloseIcon onClick={handleCancel} style={{fontSize:'18px'}} />  */}
                              </div>
                            ) : (
                              <Controller
                                name="location"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <Autocomplete
                                    onPlaceSelected={(place) => {
                                      field.onChange(place.formatted_address);
                                      onPlaceSelected(place);
                                    }}
                                  />
                                )}
                              />
                            )}
                            {errors.location && (
                              <span className="errorTextForm">
                                {errors.location.message}
                              </span>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="title">
                              {" "}
                              Max. Hourly Pay Rate ($){" "}
                            </label>
                            <div className="">
                              <input
                                type="number"
                                {...register("hourlyPayRate")}
                                className="form-control"
                                placeholder={48}
                              />

                              {errors.hourlyPayRate && (
                                <span className="errorTextForm">
                                  {errors.hourlyPayRate.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="foem-groups" style={{ width: "100%" }}>
                        <div className="form-group">
                          <label className="title">Posts</label>
                          <div className="d-flex form-control">
                            {posts.length < 1 && (
                              <span style={{ color: "#6C757D" }}>
                                Right-click on the map to select a post location.
                              </span>
                            )}
                            {posts.length >= 1 &&
                              posts.map((post, index) => (
                                <TagGroup>
                                  <Tag key={index} className="mr-1" color="#DB2677">
                                    <a
                                      href={createStreetViewLink(
                                        post.position.lat,
                                        post.position.lng
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {post.note}
                                    </a>
                                  </Tag>
                                </TagGroup>
                              ))}
                          </div>
                          {posts.length >= 1 && (
                            <span style={{ color: "#6C757D", fontSize: "12px" }}>
                              * remove selected location to clear posts
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="foem-groups" style={{ width: "100%" }}>
                        <div className="form-group">
                          <label className="title">Shifts</label>
                          <Controller
                            name="shifts"
                            control={control}
                            defaultValue={
                              defaultShifts.length
                                ? defaultShifts
                                : [
                                  {
                                    date: null,
                                    startTime: null,
                                    endTime: null,
                                    staffsRequired: 0,
                                  },
                                ]
                            } // Ensure at least one empty shift
                            render={({ field }) => (
                              <>
                                {field.value.map((shift, index) => (
                                  <div
                                    key={index}
                                    className="staffing-shifttimer-shift"
                                  >
                                    <ShiftPicker
                                      shift={shift}
                                      onShiftChange={(updatedShift) =>
                                        handleShiftChange(index, updatedShift)
                                      }
                                      onRemove={() => removeShift(index)}
                                    />
                                    {errors.shifts && errors.shifts[index] && (
                                      <div className="errorTextForm">
                                        {errors.shifts[index].date && (
                                          <p>{errors.shifts[index].date.message}</p>
                                        )}
                                        {errors.shifts[index].startTime && (
                                          <p>
                                            {errors.shifts[index].startTime.message}
                                          </p>
                                        )}
                                        {errors.shifts[index].endTime && (
                                          <p>
                                            {errors.shifts[index].endTime.message}
                                          </p>
                                        )}
                                        {errors.shifts[index].staffsRequired && (
                                          <p>
                                            {
                                              errors.shifts[index].staffsRequired
                                                .message
                                            }
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ))}
                                <div>
                                  <button
                                    type="button"
                                    className="staffing-shifttimer-btn-link"
                                    onClick={addShift}
                                  >
                                    Add Shift
                                  </button>
                                </div>
                              </>
                            )}
                          />
                        </div>
                        {errors.shifts &&
                          typeof errors.shifts.message === "string" && (
                            <span className="errorTextForm">
                              {errors.shifts.message}
                            </span>
                          )}
                      </div>

                      <div className="form-geoups">
                        <div className="form-group">
                          <label className="title">Memo</label>
                          <div className="mt-1">
                            <textarea
                              {...register("memo")}
                              className="form-control"
                              placeholder="Additional notes or instructions"
                              rows={4}
                            />
                            {errors.memo && (
                              <span className="errorTextForm">
                                {errors.memo.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <hr className="hr" />
                      {Object.keys(customerCompany).length > 0 && (
                        <CompanyInformation customerCompany={customerCompany} />
                      )}

                      <div className="text-right mt-4">
                        {!err && (
                          <>
                            {/* <button
                              type="submit"
                              onClick={handleSubmit(onSubmitDraft)} // Use the draft submit handler
                              className="staffing-btn cancel-btn"
                            >
                              Save As Draft
                            </button> */}
                            <button
                              type="submit"
                              onClick={handleSubmit(onSubmit)} // Use the confirm submit handler
                              className="staffing-btn confirm-btn"
                            >
                              Post Job
                            </button>
                          </>
                        )}
                        {err && errType === "advance" && (
                          <Message
                            showIcon
                            type="error"
                            className="errorTextForm text-center"
                          >
                            <span dangerouslySetInnerHTML={{ __html: err }} />
                          </Message>
                        )}
                      </div>
                    </form>
                    {loading && <Loader backdrop center />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={handleClose} className="create_customer_modal_wrapper">
        <Modal.Header>
          <Modal.Title>Create customer</Modal.Title>
        </Modal.Header>
        <Modal.Body id='scrollBar' style={{ height: '573px', padding: '0', margin: '0' }}>
          <NewCustomer />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewOrder;
