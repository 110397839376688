import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Button } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import CakeIcon from "@mui/icons-material/Cake";
import EmailIcon from "@mui/icons-material/Email";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import { updateProviderResourceDetailsByOfficer } from "../../../../services/staffingService";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const detailsSchema = yup.object().shape({
  location: yup.string().required("Your location is required."),
  phoneNumber: yup.string().required("Phone number is required"),
  dateOfBirth: yup.date().required("Date of birth is required"),
  emergencyContactName: yup.string().required("Emergency contact name is required"),
  emergencyContactPhone: yup.string().required("Emergency contact phone is required"),
});

const DetailsTab = ({ providerResource, providerCompanyId, setProviderResource }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const formatDateForInput = (date) => {
    return date ? new Date(date).toISOString().split("T")[0] : "";
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(detailsSchema),
    defaultValues: {
      location: providerResource.formattedLocation,
      phoneNumber: providerResource.phoneNumber,
      dateOfBirth: formatDateForInput(providerResource.dateOfBirth),
      emergencyContactName: providerResource.emergencyContact?.name,
      emergencyContactPhone: providerResource.emergencyContact?.phone,
    },
  });

  useEffect(() => {
    if (providerResource) {
      setValue("location", providerResource.formattedLocation);
      setValue("phoneNumber", providerResource.phoneNumber);
      setValue("dateOfBirth", formatDateForInput(providerResource.dateOfBirth));
      setValue("emergencyContactName", providerResource.emergencyContact?.name);
      setValue("emergencyContactPhone", providerResource.emergencyContact?.phone);
    }
  }, [providerResource, setValue]);

  const toggleEditMode = () => setIsEditing(!isEditing);

  const onPlaceSelected = (place) => {
    const locationData = {
      formatted_address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      state: "",
      city: "",
      zipcode: "",
    };
    place.address_components.forEach((component) => {
      const types = component.types;
      if (types.includes("administrative_area_level_1")) locationData.state = component.long_name;
      if (types.includes("locality")) locationData.city = component.long_name;
      if (types.includes("postal_code")) locationData.zipcode = component.long_name;
    });
    setSelectedLocation(locationData);
    setValue("location", locationData.formatted_address);
  };

  const Autocomplete = ({ value, onPlaceSelected }) => {
    const inputRef = useRef(null);
    useEffect(() => {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        componentRestrictions: { country: "us" },
      });
      autocomplete.addListener("place_changed", () => onPlaceSelected(autocomplete.getPlace()));
    }, [onPlaceSelected]);
    return (
      <div className="autocomplete-container">
        <input type="text" ref={inputRef} className="form-control" defaultValue={value} />
        <LocationSearchingIcon className="location-icon" />
      </div>
    );
  };

  const handleCancel = () => {
    setSelectedLocation(null);
    setValue("location", providerResource.formattedLocation);
  };

  const onSubmit = async (data) => {
    try {


      await updateProviderResourceDetailsByOfficer(providerCompanyId, providerResource._id, {
        selectedLocation,
        data,
      });
      
      setProviderResource((prevResource) => ({
        ...prevResource,
        formattedLocation: selectedLocation?.formatted_address || prevResource.formattedLocation,
        city: selectedLocation?.city || prevResource.city,
        latitude: selectedLocation?.latitude || prevResource.latitude,
        longitude: selectedLocation?.longitude || prevResource.longitude,
        state: selectedLocation?.state || prevResource.state,
        zipcode: selectedLocation?.zipcode || prevResource.zipcode,
        phoneNumber: data.phoneNumber,
        dateOfBirth: data.dateOfBirth,
        emergencyContact: {
          name: data.emergencyContactName,
          phone: data.emergencyContactPhone,
        },
      }));

      toggleEditMode();
    } catch (error) {
      console.error("Failed to update details:", error);
    }
  };

  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    if (today.getMonth() < birth.getMonth() || (today.getMonth() === birth.getMonth() && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <div>
      {!isEditing ? (
        <div className="prepopulated-values-data p-3">
          <p>  <AccountCircleIcon className="icon" />  {providerResource.user_id?.firstName} {providerResource.user_id?.lastName}</p>
          <p><HomeIcon className="icon" /> Address: {providerResource.formattedLocation}</p>
          <p><PhoneIcon className="icon" /> Phone: {providerResource.phoneNumber || "N/A"}</p>
          <p>
            <CakeIcon className="icon" /> Date of Birth:{" "}
            {new Date(providerResource.dateOfBirth).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            ({calculateAge(providerResource.dateOfBirth)} years old)
          </p>
          <p><EmailIcon className="icon" /> Email: {providerResource.user_id?.email || "N/A"}</p>
          <p>
            <ContactEmergencyIcon className="icon" /> Emergency Contact:{" "}
            {providerResource.emergencyContact
              ? `${providerResource.emergencyContact.name} (${providerResource.emergencyContact.phone || "Phone not available"})`
              : "N/A"}
          </p>
          {/* <p><strong>Documents:</strong></p>
          <ul>
            {providerResource.files && providerResource.files.length > 0 ? (
              providerResource.files.map((file) => (
                <li key={file._id}>
                  <a href={file.fileUrl} target="_blank" rel="noopener noreferrer">{file.fileName}</a>
                </li>
              ))
            ) : (
              <li>No documents available</li>
            )}
          </ul> */}
          <Button startIcon={<EditIcon />} onClick={toggleEditMode} className="edit-button">Edit</Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label className="title">Address</label>
            {selectedLocation ? (
              <div className="selected-location">
                <span>{selectedLocation.formatted_address}</span>
                <button type="button" onClick={handleCancel}><CloseIcon /></button>
              </div>
            ) : (
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    value={field.value || providerResource.formattedLocation}
                    onPlaceSelected={(place) => {
                      field.onChange(place.formatted_address);
                      onPlaceSelected(place);
                    }}
                  />
                )}
              />
            )}
            {errors.location && <span className="errorTextForm">{errors.location.message}</span>}
          </div>

          <div className="form-group">
            <label className="title">Phone</label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <PhoneInput defaultCountry="US" value={field.value} onChange={field.onChange} />
              )}
            />
            {errors.phoneNumber && <span className="errorTextForm">{errors.phoneNumber.message}</span>}
          </div>

          <div className="form-group">
            <label className="title">Date of Birth</label>
            <input
              type="date"
              {...register("dateOfBirth")}
              className="form-control"
            />
            {errors.dateOfBirth && <span className="errorTextForm">{errors.dateOfBirth.message}</span>}
          </div>

          <div className="form-group">
            <label className="title">Emergency Contact Name</label>
            <input type="text" {...register("emergencyContactName")} className="form-control" />
            {errors.emergencyContactName && <span className="errorTextForm">{errors.emergencyContactName.message}</span>}
          </div>

          <div className="form-group">
            <label className="title">Emergency Contact Phone</label>
            <Controller
              name="emergencyContactPhone"
              control={control}
              render={({ field }) => (
                <PhoneInput defaultCountry="US" value={field.value} onChange={field.onChange} />
              )}
            />
            {errors.emergencyContactPhone && <span className="errorTextForm">{errors.emergencyContactPhone.message}</span>}
          </div>

          <button type="submit" className="staffing-btn confirm-btn">Save</button>
          <button type="button" className="staffing-btn cancel-btn ml-2" onClick={toggleEditMode}>Cancel</button>
        </form>
      )}
    </div>
  );
};

export default DetailsTab;
