import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EditIcon from '@mui/icons-material/Edit'; // Import the Edit icon
import { useProviderCompany } from "../../../../../context/ProviderCompanyContext"; // Import the context
import { updateProviderOffersPreferences } from '../../../../../services/staffingService'; // Import the update service

import OfferDisputeRules from './rules/offerdisputerules'; // Import the OfferDisputeRules component

// Define validation schema using Yup
const offersSchema = yup.object().shape({
    offerExpiry: yup.number().min(1).max(24).required('Offer expiry is required'),
});

const OffersDisputes = ({ providerCompanyId }) => {
    const { providerCompanyData, fetchProviderCompanyData } = useProviderCompany(); // Use providerCompanyData from context
    const [isEditing, setIsEditing] = useState(false);
    
    // Initialize disputeRules as an array
    const [disputeRules, setDisputeRules] = useState([
        { entity: 'shift', statusCode: 1, definition: 'No-show for scheduled shift', editable: false },
        { entity: 'shift', statusCode: 2, definition: 'Late arrival to scheduled shift', editable: false },
        { entity: 'shift', statusCode: 3, definition: 'Missing checkout at shift end', editable: false },
        { entity: 'shift', statusCode: 4, definition: 'Late check-in for shift', editable: false },
        { entity: 'shift', statusCode: 5, definition: 'Check-in or check-out outside geofence area', editable: false },
        { entity: 'shift', statusCode: 6, definition: 'Inappropriate behavior during shift', editable: false }
    ]);
    

    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(offersSchema),
        defaultValues: {
            offerExpiry: 0,
        },
    });

    // Populate the form when providerCompanyData changes
    useEffect(() => {
        if (providerCompanyData && providerCompanyData.preferences?.offers) {
            reset({
                offerExpiry: providerCompanyData.preferences.offers.officerResponseTimeHours || 0,
            });
        }
    }, [providerCompanyData, reset]);

    // Handle form submission
    const onSubmit = async (data) => {
        try {
            const offerData = {
                officerResponseTimeHours: data.offerExpiry,
                disputeRules // Log or send dispute rules to backend
            };

            // Log dispute rules for now
            console.log("Dispute Rules:", disputeRules);

            // Update the backend with new offers preferences
            await updateProviderOffersPreferences(providerCompanyData._id, offerData);
            fetchProviderCompanyData(providerCompanyId);
            setIsEditing(false);
        } catch (error) {
            console.error("Failed to update offers preferences:", error);
        }
    };

    // Toggle edit mode and populate fields
    const toggleEditMode = () => {
        setIsEditing(!isEditing);
        if (!isEditing && providerCompanyData?.preferences?.offers) {
            setValue('offerExpiry', providerCompanyData.preferences.offers.officerResponseTimeHours || 0);
        }
    };

    return (
        <div className="d-flex align-items-start account_card">
            <LocalOfferIcon className="checkboxSuccess" />
            <div>
                <h6 className="mb-1 staffing-set-up-guide-title">
                    Offers & Disputes
                    {!isEditing && (
                        <EditIcon
                            onClick={toggleEditMode}
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                        />
                    )}
                </h6>

                {isEditing ? (
                    <div className="configure-sidebar justify-content-center">
                        <form onSubmit={handleSubmit(onSubmit)} className="configure-form">
                            <div className="advanced-setting epochs">
                                <div className="form-geoups mt-4">
                                    <div className="form-group" style={{ width: '100%' }}>
                                        <label className="title">
                                            Officer response time to new offers (expiry in hours):
                                        </label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            {...register('offerExpiry')}
                                        />
                                        <span className="errorTextForm">{errors.offerExpiry?.message}</span>
                                    </div>
                                </div>
                                <hr />
                                <div className="form-geoups">
                                    <div className="form-group" style={{ width: '100%' }}>
                                        <label className="title"> Configure dispute offers status codes: </label>    
                                        <OfferDisputeRules rows={disputeRules} setRows={setDisputeRules} /> 
                                    </div>
                                </div>

                                <button className="staffing-btn confirm-btn" type="submit">
                                    Save
                                </button>
                                <button
                                    className="staffing-btn cancel-btn ml-2"
                                    type="button"
                                    onClick={() => setIsEditing(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div>
                        <p className="mb-2 staffing-set-up-guide-description" style={{ color: '#5f6368', fontSize: '14px' }}>
                            Officer response time to new offers before it expires: {providerCompanyData?.preferences?.offers?.officerResponseTimeHours || 0} hours.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OffersDisputes;
