import React from 'react';
import './style.css'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import EducationChart from './educationChart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Education = ({ dts }) => {
  return (
    <div className='Education-wrapper'>
      <div className='Education-content'>
        <div className='Education-title'>
          Education
        </div>
        <EducationChart dts={dts[0].education} />
      </div>
    </div>
  )
}

export default Education