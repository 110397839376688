import React from 'react';
import './style.css'
// import ApexCharts from './Chart'
import RacesChart from './RacesChart';


const Races = ({ dts }) => {
  const series = [57.15, 33.54, 47.16, 65.58, 80.42, 81.23, 49.31]
  const options = {
    chart: {
      height: 280,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: 90,
        hollow: {
          size: "30%",
        },

        dataLabels: {
          name: {
            fontSize: '14px',
          },
          value: {
            fontSize: '14px',
          },
          total: {
            show: true,
            label: Object.entries(dts[0].races).sort((x, y) => y[1] - x[1])[0][0],
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return Object.entries(dts[0].races).sort((x, y) => y[1] - x[1])[0][1]
            }
          }
        }

      }
    },
    //labels: ['White', 'Black', 'African', 'Asian', 'Nativbe', 'Some', 'Two'],
  }


  return (
    <div className='Races-wrapper'>
      <div className='Races-block'>
        <div className='Races-content'>
          <div className='Races-title'>
            <h6 className="fontstyleaxiform"> Race/Ethnicity </h6>
          </div>
        </div>
        <div className='Races-description'>
          <RacesChart options={options} dts={dts[0].races} />
        </div>
      </div>
    </div>
  )
}

export default Races