import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import EditIcon from "@mui/icons-material/Edit"; // Import Edit icon from MUI
import { useProviderCompany } from "../../../../../context/ProviderCompanyContext";
import { updateProviderRecommendationPreferences } from '../../../../../services/staffingService';

const officerSchema = yup.object().shape({
  distance: yup.number().min(0).required("Distance is required"),
});

const OfficerRecommendations = ({providerCompanyId}) => {
  const { providerCompanyData, fetchProviderCompanyData } = useProviderCompany();
  
  // State to handle whether we are in editing mode or not
  const [isEditing, setIsEditing] = useState(false);
  const [ranking, setRanking] = useState([]);

  // React Hook Form setup with yup resolver
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(officerSchema),
    defaultValues: {
      distance: 0, // Initial default value, will be updated from providerCompanyData
    },
  });

  // UseEffect to update form when providerCompanyData changes
  useEffect(() => {
    if (providerCompanyData && providerCompanyData.preferences?.recommendations) {
      // Populate the distance from providerCompanyData
      reset({
        distance: providerCompanyData.preferences?.recommendations.showOfficersWithinMiles || 0,
      });

      // Initialize ranking based on providerCompanyData or default values
      setRanking([
        { id: "1", content: "Highest margin" },
        { id: "2", content: "With same customer" },
        { id: "3", content: "Good reviews" }
      ]);
    }
  }, [providerCompanyData, reset]);

  // Move up in ranking
  const moveUp = (index) => {
    if (index > 0) {
      const newRanking = [...ranking];
      [newRanking[index - 1], newRanking[index]] = [
        newRanking[index],
        newRanking[index - 1],
      ];
      setRanking(newRanking);
    }
  };

  // Move down in ranking
  const moveDown = (index) => {
    if (index < ranking.length - 1) {
      const newRanking = [...ranking];
      [newRanking[index + 1], newRanking[index]] = [
        newRanking[index],
        newRanking[index + 1],
      ];
      setRanking(newRanking);
    }
  };

  // Function to handle form submission
  const onSubmit = async (data) => {
    try {
      const recommendationData = {
        showOfficersWithinMiles: data.distance,
        highestMargin: ranking.findIndex(item => item.content === "Highest margin") + 1,
        withSameCustomer: ranking.findIndex(item => item.content === "With same customer") + 1,
        goodReviews: ranking.findIndex(item => item.content === "Good reviews") + 1,
      };

      // Update the backend with the new recommendation data
      await updateProviderRecommendationPreferences(providerCompanyData._id, recommendationData);

      // Fetch updated provider company data to refresh context
      fetchProviderCompanyData(providerCompanyId);

      // Switch back to view mode
      setIsEditing(false);

    } catch (error) {
      console.error("Failed to update officer recommendations:", error);
    }
  };

  // Toggle edit mode
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
    if (!isEditing && providerCompanyData?.preferences?.recommendations) {
      // Prepopulate the form when switching to edit mode
      setValue("distance", providerCompanyData.preferences.recommendations.showOfficersWithinMiles || 0);
    }
  };

  // RankItem component to display each ranking item
  const RankItem = ({ item, index, moveUp, moveDown, totalItems }) => {
    return (
      <li
        style={{
          padding: "8px",
          margin: "4px 0",
          backgroundColor: "#f4f4f4",
          border: "1px solid #ccc",
        }}
      >
        <span>{item.content}</span>
        <button
          onClick={() => moveUp(index)}
          disabled={index === 0}
          style={{ margin: "0 4px" }}
        >
          ↑
        </button>
        <button
          onClick={() => moveDown(index)}
          disabled={index === totalItems - 1}
        >
          ↓
        </button>
      </li>
    );
  };

  return (
    <div className="d-flex align-items-start account_card">
      <LocalPoliceIcon className="checkboxSuccess" />
      <div>
        {/* Title with Edit button */}
        <h6 className="mb-1 staffing-set-up-guide-title">
          Officer Recommendations
          {!isEditing && (
            <EditIcon
              onClick={toggleEditMode}
              style={{ cursor: "pointer", marginLeft: "10px" }}
            />
          )}
        </h6>

        {/* Display mode or Edit mode */}
        {isEditing ? (
          <div className="configure-sidebar justify-content-center">
            <form onSubmit={handleSubmit(onSubmit)} className="configure-form">
              <div className="advanced-setting epochs">
                <div className="form-geoups mt-4">
                  <div className="form-group" style={{ width: "100%" }}>
                    <label className="title">
                      Show officers within (miles):
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      {...register("distance")}
                    />
                    <span className="errorTextForm">
                      {errors.distance?.message}
                    </span>

                    <label className="title mt-2">Adjust Ranking:</label>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {ranking.map((item, index) => (
                        <RankItem
                          key={item.id}
                          index={index}
                          item={item}
                          moveUp={moveUp}
                          moveDown={moveDown}
                          totalItems={ranking.length}
                        />
                      ))}
                    </ul>

                    <button className="staffing-btn confirm-btn" type="submit">
                      Save
                    </button>
                    <button
                      className="staffing-btn cancel-btn ml-2"
                      type="button"
                      onClick={() => setIsEditing(false)} // Cancel button functionality
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <p
              className="mb-2 staffing-set-up-guide-description"
              style={{ color: "#5f6368", fontSize: "14px" }}
            >
              Show officer recommendations within {providerCompanyData?.preferences?.recommendations?.showOfficersWithinMiles || 0} miles
            </p>
            <p
              className="mb-2 staffing-set-up-guide-description"
              style={{ color: "#5f6368", fontSize: "14px" }}
            >
              Recommendations Sections Ranking -
              {ranking.map((item) => item.content).join(", ")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfficerRecommendations;
