import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getCityEvents } from '../../../../services/staffingService';

const EventDisplay = ({selectedCity}) => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await getCityEvents(selectedCity.boundary.city, selectedCity.boundary.state_code);
                setEvents(response.data._embedded.events);
                setLoading(false);
            } catch (err) {
    
                setError('No events found for a selected city scheduled in the next 3 days.');
                setLoading(false);
            }
        };
        // log
        fetchEvents();
    }, [selectedCity]);

    if (loading) return <p>Loading events...</p>;
    if (error) return <p> {error}</p>;

    return (
        <div style={{ padding: '20px' }}>
      {events?.map((event) => (
        <div key={event.id} className="signal-events-card">
          {/* Event Title */}
          <h3 className="signal-events-title">{event?.name}</h3>

          {/* Venue Information */}
          {event?._embedded && event?._embedded?.venues && event?._embedded?.venues?.length > 0 && (
            <p className="signal-events-location">
              {event?._embedded?.venues[0]?.name} - {event?._embedded?.venues[0]?.city?.name}, {event?._embedded?.venues[0]?.state?.name}
            </p>
          )}

          {/* Date Information */}
          <p className="signal-events-date">
            {new Date(event?.dates?.start?.localDate).toLocaleDateString()} {event?.dates?.start?.localTime}
          </p>

          {/* Event Classification */}
          <p className="signal-events-classification">
            {event?.classifications[0]?.segment?.name || 'N/A'}
          </p>

          {/* Additional Info */}
          {/* <div className="signal-events-details">
            <p className="signal-events-attendance">
              100 <span>Predicted Attendance</span>
            </p>
            <p className="signal-events-spend">
              $2,046 <span>Predicted Event Spend (USD)</span>
            </p>
          </div> */}

          {/* More Details Button */}
          <div className="signal-events-more-details">
            <a
              href={event.url}
              target="_blank"
              rel="noopener noreferrer"
              className="signal-events-details-btn"
            >
              + More Details
            </a>
          </div>
          
        </div>
      ))}
    </div>
    );
};

export default EventDisplay;
