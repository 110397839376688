// import "../../styles/calendarModal.css"; 
import React from 'react';
import Modal from 'react-modal';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const ShiftCalendar = ({ shiftPlan, calendarIsOpen, closeShiftCalendar }) => {

    const colors = ['#FFB6C1', '#ADD8E6', '#90EE90', '#FFD700', '#FFA07A', '#DDA0DD'];

    const events = shiftPlan.flatMap((shift, index) => 
        shift.offers.map((offer, offerIndex) => ({
            title: `${offer.providerName}`,
            start: new Date(shift.startTime),
            end: new Date(shift.endTime),
            resource: { color: colors[offerIndex % colors.length] }
        }))
    );

    const eventStyleGetter = (event) => {
        return {
            style: {
                backgroundColor: event.resource.color,
                borderRadius: '5px',
                opacity: 0.8,
                color: 'black',
                border: '0px',
                display: 'block'
            }
        };
    };

    const defaultDate = events.length > 0 ? events[0].start : new Date();

    return (
        <Modal
            isOpen={calendarIsOpen}
            onRequestClose={closeShiftCalendar}
            className="shift-calendar-modal"
            overlayClassName="shift-calendar-modal-overlay"
            ariaHideApp={false}
        >
            <div className='calendar-agenda-modal-container'>
                <div className='calendar-agenda-modal-header'>
                    <button onClick={closeShiftCalendar} style={{ float: 'right', cursor: 'pointer' }}>Close</button>
                    
                    <div className="text-center mb-4">
                        <h3 style={{ color: '#3D4784' }}>Shift Plan </h3>
                    </div> 
                    
                </div>
                <div className='calendar-agenda-modal-body'>
                    <Calendar
                        className=" p-2"
                        defaultView='week'
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        defaultDate={defaultDate}
                        style={{ height: '100%' }} 
                        eventPropGetter={eventStyleGetter}  // Custom styling for events
                        showMultiDayTimes={false}  // Hides the time on the event
                        messages={{ noEventsInRange: 'There are no shifts in this range.' }}
                    />
                </div>
                <div className='calendar-agenda-modal-footer'>
                    <button className="rejection-modal-btn">Add to Calendar</button>
                </div>
            </div>
        </Modal>
    );
};

export default ShiftCalendar;