import React , { useEffect , useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserProvider , useUser } from '../../context/UserContext'; 
import { ProviderCompanyProvider  } from '../../context/ProviderCompanyContext'; 
import { register , login } from "../../services/userService";
import { Loader } from 'rsuite';

const ProtectedRouteComponent = ({ component: Component, ...rest }) => {
  const { userInfoReady, userInfo } = useUser();
  const [loadingAuthUrl, setLoadingAuthUrl] = useState(false);


  const providerCompanyId = rest.match.params.providerCompanyId;

  useEffect(() => {
    if (!userInfoReady) return;

    if (!userInfo) {
      const initiateLogin = async () => {
        try {
          setLoadingAuthUrl(true);
          const resp = await login();
          window.location.href = resp.data.authUrl;
        } catch (error) {
          console.error('Login initiation failed', error);
        } finally {
          setLoadingAuthUrl(false);
        }
      };

      initiateLogin();
    }
  }, [userInfoReady, userInfo]);

  if (!userInfoReady || loadingAuthUrl) {
    return <Loader backdrop content="loading..." vertical />;
  }

  if (!userInfo) {
    return null; // Return null to avoid rendering anything while redirecting
  }

  return <Component {...rest} />;
};

const ProtectedRoute = ({ component, ...rest }) => (
  // <UserProvider>
  //     <Route {...rest} render={props => <ProtectedRouteComponent component={component} {...props} />} />
  // </UserProvider>
  <UserProvider>
    <ProviderCompanyProvider>
      <Route {...rest} render={props => <ProtectedRouteComponent component={component} {...props} />} />
    </ProviderCompanyProvider>
  </UserProvider>
);

export default ProtectedRoute;