import React, { useState } from "react";
import Modal from 'react-modal';
import { postRejectOffer } from "../../../../services/staffingService"
import "../../styles/rejectionModal.css"

const RejectionModal = ({ isOpen, onRequestClose, className, overlayClassName, ariaHideApp , offerId , openOffers , setOpenOffers , acceptedOffers , setAcceptedOffers , providerCompanyId  }) => {
    const [selectedReason, setSelectedReason] = useState('');
    const [error, setError] = useState('');


    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleSubmit = async () => {
        if (!selectedReason) {
            setError('Please select a reason.');
            return;
        }
        const resp = await postRejectOffer(providerCompanyId, offerId, selectedReason);
        setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open'} )) ;
        setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted'} )) ;
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={`${className} rejection-modal`}
            overlayClassName={overlayClassName}
            ariaHideApp={ariaHideApp}
        >
            <div className='rejection-modal-container'>
                <div className='rejection-modal-content'>
                    <div className="text-center mb-4"> <h3 style={{color:'#3D4784'}}> Rejection Reason</h3> </div>
                    <div className='rejection-modal-body mt-2'>
                        <form>
                            <div className='form-check rejection-modal-form-check'>
                                <input
                                    className='form-check-input rejection-modal-form-check-input'
                                    type='radio'
                                    name='reason'
                                    id='reason1'
                                    value='Offer price is too low'
                                    onChange={handleReasonChange}
                                />
                                <label className='form-check-label rejection-modal-form-check-label' htmlFor='reason1'>
                                    Offer price is too low
                                </label>
                            </div>
                            <div className='form-check rejection-modal-form-check'>
                                <input
                                    className='form-check-input rejection-modal-form-check-input'
                                    type='radio'
                                    name='reason'
                                    id='reason2'
                                    value='Not available'
                                    onChange={handleReasonChange}
                                />
                                <label className='form-check-label rejection-modal-form-check-label' htmlFor='reason2'>
                                    Not available
                                </label>
                            </div>
                            <div className='form-check rejection-modal-form-check'>
                                <input
                                    className='form-check-input rejection-modal-form-check-input'
                                    type='radio'
                                    name='reason'
                                    id='reason3'
                                    value='Shift site is too far'
                                    onChange={handleReasonChange}
                                />
                                <label className='form-check-label rejection-modal-form-check-label' htmlFor='reason3'>
                                    Shift site is too far
                                </label>
                            </div>
                            <div className='form-check rejection-modal-form-check'>
                                <input
                                    className='form-check-input rejection-modal-form-check-input'
                                    type='radio'
                                    name='reason'
                                    id='reason4'
                                    value='Other'
                                    onChange={handleReasonChange}
                                />
                                <label className='form-check-label rejection-modal-form-check-label' htmlFor='reason4'>
                                    Other
                                </label>
                            </div>
                            {error && <div className='rejection-modal-error'>{error}</div>}
                            <button type='button' className='rejection-modal-btn' onClick={handleSubmit}>
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RejectionModal;
