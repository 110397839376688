import React, { useState } from 'react';
import '../../styles/dashboard.css';

import DashboardSidebar from "./dashboardSidebar"

// https://dribbble.com/shots/19587301-Cloud-Kitchen-Aggregator-POS
import GoogleCalendar from "../../icons/google-calendar.png";
import WhatsApp from "../../icons/whatsapp.png";
import OfficerDashboardSidebar from '../officerDashboardSidebar';
import OfficerHeader from '../officerHeader';



const Performance = () => {
  const [showSideDrawer, setShowSideDrawer] = useState(false);


  return (
    <div className="dashboard officer_dashboard">
      <OfficerDashboardSidebar isOpen={showSideDrawer} />
      <div className='dashboard_content'>
        <OfficerHeader isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />

        <div className="staffing-new-assignments-body" id='scrollBar'>
          <div className='page_title'>
            <div className='left_side'>
              <h2>Performance</h2>
              <p></p>
            </div>
          </div>
          <div className="main-content">
            <div className="integration-container">
              <div className="integration-item">
                {/* <img className="integration-icon" src={GoogleCalendar} alt="" /> */}
                <p className="integration-name"> CSAT (Customer Satisfaction Score) </p>
                <p className="integration-status">How satisfied are you with the service provided?</p>
                <p className="integration-name"> 4/5 </p>
                <p className="integration-status">18 responses</p>
                {/* <p onClick={handleAssignment} > Activate </p> */}
              </div>
              {/* <div className="integration-item">
          <span className="integration-icon">[Outlook Icon]</span>
          <p className="integration-name">Microsoft Outlook</p>
          <p className="integration-status">Connected as 'xx@gmail.com'</p>
        </div> */}
              <div className="integration-item">
                {/* <img className="integration-icon" src={WhatsApp} alt="" /> */}
                <p className="integration-name">NPS (Net Promoter Score) </p>
                <p className="integration-status">  How likely are you to recommend this security staff member to other customers?  </p>
                <p className="integration-name"> 3.5/5 </p>
                <p className="integration-status">14 responses</p>
              </div>

              <div className="integration-item">
                {/* <img className="integration-icon" src={WhatsApp} alt="" /> */}
                <p className="integration-name"> Service Quality Score </p>
                <p className="integration-status"> How would you rate the quality of the service in terms of punctuality, professionalism, and behavior? </p>
                <p className="integration-name"> 4.2/5 </p>
                <p className="integration-status">18 responses</p>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>


  );
};

export default Performance;