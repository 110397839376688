import React, { useState , useRef, useEffect } from 'react';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';

import RecommendIcon from '@mui/icons-material/Recommend';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PaidIcon from '@mui/icons-material/Paid';
import { Redirect } from "react-router-dom"
import { register , login } from "../../../services/userService";


  const PartnerRegistration = () => {
    
    React.useEffect(() => { 
    },[])

    const startRegistration = async () => {
        let view= 'provider-staff-allocation-company'
        const resp= await register(view);
        window.location.href = resp.data.authUrl; 
        // alert("Authentication is not enabled for Demo App");
    }

    const initiateLogin = async () => {
        const resp= await login();
        window.location.href = resp.data.authUrl; 
        // alert("Authentication is not enabled for Demo App");
    }

    // if (localStorage.getItem('x-auth')) return <Redirect to="/staffing" />;

    return <> 
      <div className="dashboard">
        <div className="staffing-new-assignments-body">
          <div className="security-staff-registration-container">
            
            <header className="security-staff-registration-header">
              <div className="security-staff-registration-header-content">
                <h1 className="security-staff-registration-title">Optimize Your Security Staff Allocation</h1>
                <p className="security-staff-registration-subtitle">
                  Discover the power of RefineAI's AI powered location planning platform. <br/>
                  Streamline your security operations with advanced features designed for efficiency.
                </p>
                <button className="security-staff-registration-button" onClick={startRegistration}>
                  GET STARTED NOW
                </button>
                <div className="mt-2 staffing-login" onClick={initiateLogin } > Log In </div>
              </div>
            </header>

            <section className="security-staff-registration-features">

            <div className="security-staff-registration-feature">
                <RecommendIcon className="security-staff-registration-icon ar-app-icon" style={{ fontSize: '45px' }} />
                <h3 className="mt-2">Recommendation Engine</h3>
                <p>
                Our recommendation engine leverages proximity search, NPS scores, and advanced machine learning. It can be customized to meet business preferences, such as promoting new staff and rewarding those who consistently deliver high customer satisfaction.
                </p>
              </div>
              <div className="security-staff-registration-feature">
                <AutoGraphIcon className="security-staff-registration-icon ar-app-icon" style={{ fontSize: '45px' }} />
                <h3 className="mt-2">Dynamic Pricing</h3>
                <p>
                Enhance revenue with our dynamic pricing, which optimizes margins through multiple negotiations. It accounts for several factors such as crime rates, staff availability, and local events to set competitive prices.
                </p>
              </div>
              <div className="security-staff-registration-feature">
                <PaidIcon className="security-staff-registration-icon money-icon" style={{ fontSize: '45px' }} />
                <h3 className="mt-2">Streamlined Payments & Compliance</h3>
                <p>
                With Stripe, quickly process payments to staff or receive them from customers. Features include fraud detection, tax compliance, identity verification, and dynamic tax calculations for secure, compliant transactions.
                </p>
              </div>

            </section>
          </div>
        </div>
      </div>
    </>

}
export default PartnerRegistration ;