import React, { createContext, useContext, useState, useCallback } from 'react';
import { getProviderCompanyByIdContext } from '../services/staffingService';

const ProviderCompanyContext = createContext();

export const ProviderCompanyProvider = ({ children }) => {
    const [providerCompanyData, setProviderCompanyData] = useState(null);

    const fetchProviderCompanyData = useCallback(async (providerCompanyId) => {
        const response = await getProviderCompanyByIdContext(providerCompanyId);
        const data = response.data;
        setProviderCompanyData(data);
    }, []);

    return (
        <ProviderCompanyContext.Provider value={{ providerCompanyData, fetchProviderCompanyData }}>
            {children}
        </ProviderCompanyContext.Provider>
    );
};

export const useProviderCompany = () => {
    return useContext(ProviderCompanyContext);
};
