import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import '../../styles/dashboard.css';
import { Toggle, Checkbox } from 'rsuite';
import DashboardSidebar from "./dashboardSidebar"
import "../../styles/workOrder.css"
import DistanceMap from './DistanceMap';
import RejectionModal from "./RejectionModal";
import StatusTabsHeader from "./workOrderStatusTabs"
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactComponent as Calendar} from "../../../_asset/img/calendar.svg";
import { ReactComponent as ExternalLink} from "../../../_asset/img/external-link.svg";
import { getProviderOffers, getAcceptOffer } from "../../../../services/staffingService";
import toast from 'react-hot-toast';
import OfficerDashboardSidebar from '../officerDashboardSidebar';
import OfficerHeader from '../officerHeader';


function formatJobDates(job) {
  return job.shifts.map(shift => {
    const date = new Date(shift.date);
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  });
}

const CompletedJobShiftsDisplay = ({ job, offer }) => {

  const dates = formatJobDates(job);
  const firstDate = dates[0];
  const additionalDatesCount = dates.length - 1;

  return (
    <div className="staff-order-name">
      <div>
        <span>
          {firstDate}
          {additionalDatesCount > 0 && (
            <span style={{ fontWeight: 'bold', margin: '10px' }}>
              (+ {additionalDatesCount} more shifts)
            </span>
          )}
        </span>
      </div>
    </div>
  );
};


const OrderCard = ({ offer, staff, openOffers, setOpenOffers, acceptedOffers, setAcceptedOffers, completedOffers, setCompletedOffers }) => {


  const { jobId: job } = offer;
  return (
    <div className="staff-order-card">
      <div className="staff-order-details">
        <div className="staff-order-content">
          <div className="staff-order-header d-flex justify-content-between">
            <div className="staff-order-name" style={{ color: '#376D37' }}> Well done! Job completed successfully. </div>
            <div className="staff-order-id">
              <StarIcon style={{ color: '#FFA41E' }} />
              <StarIcon style={{ color: '#FFA41E' }} />
              <StarIcon style={{ color: '#FFA41E' }} />
              <StarIcon style={{ color: '#FFA41E' }} />
              <StarHalfIcon style={{ color: '#FFA41E' }} />
            </div>
          </div>
          <div className="staff-order-address"> <CompletedJobShiftsDisplay job={job} offer={offer} /> </div>
          <div className="staff-order-address">{job.formattedAddress}. </div>
          <div className="staff-order-total">  {job.service.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} </div>
          {/* <div className="staff-order-total"> <a href='/payments' target="_blank" rel="noreferrer" > Check Payment <OpenInNewIcon style={{fontSize:'16px', marginBottom:'5px'}} />  </a>  </div> */}
          <div className="staff-order-total" >
            <hr />

            {offer.invoice.status === 'paid' ? (
              <>
                Payment received for invoice from {offer.provider_resource_id.firstName}. <br />
                <a href={offer.invoice.url} target="_blank" style={{ color: '#2a9d8f', textDecoration: 'underline', fontWeight: 'bold' }}>
                  View receipt
                </a>
              </>
            ) : (
              <>
                Invoice issued <br />
                Current payment status -
                <strong className="ml-1" style={{ color: offer.jobId.invoice.status === 'open' ? '#e9c46a' : '#e76f51' }}>
                  {offer.invoice.status}
                </strong>

              </>
            )}

          </div>
        </div>
      </div>

    </div>
  );
};


const NewOrdersTab = ({ offers, staff }) => {
  return (
    <div className="staff-new-orders-tab">
      {/* <div className="staff-new-orders-header d-flex justify-content-between">
          <div>New</div>
          <div> <span> Next >> </span> </div>  
      </div>  */}
      <div className="staff-orders-container">
        {offers.map(offer => (
          <OrderCard key={offer._id} offer={offer} staff={staff}
          />
        ))}
      </div>
    </div>
  );
};

const StatusTabs = () => {

  const [openOffers, setOpenOffers] = useState([]);
  const [acceptedOffers, setAcceptedOffers] = useState([]);
  const [completedOffers, setCompletedOffers] = useState([]);
  const [staff, setStaff] = useState({});
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [gridView, setGridView] = useState(0);
  const { providerCompanyId } = useParams();

  useEffect(() => {
    getOffers();
  }
    , []);

  const getOffers = async () => {
    const resp = await getProviderOffers(providerCompanyId);

    setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open' }));
    setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted' }));
    setCompletedOffers(resp.data.offer.filter((o) => { return o.status === 'completed' }));
    setStaff({ ...resp.data.offer[0].provider_resource_id });
  }


  // if ( openOffers.length === 0 ) return <Redirect to="/staffing/staff/dashboard/work-order/accepted" /> ;

  return (
    <>
      <div className="dashboard officer_dashboard">
        <OfficerDashboardSidebar isOpen={showSideDrawer} />
        <div className='dashboard_content'>
          <OfficerHeader isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />

          <div className="staffing-new-assignments-body" id='scrollBar'>
            <div className='page_title'>
              <div className='left_side'>
                <h2>Officers</h2>
                <p>Manage your orders here</p>
              </div>
              <div className='right_side'>
                <Link to={`/provider-company/${providerCompanyId}/customers`}>
                  <button className="create-new-job-button">
                    <Calendar /> Scheduled Shifts
                  </button>
                </Link>
              </div>
            </div>
            <div className="main-content">
              <div className="main-content-inner">
                {/* ===== status tabs ===== */}
                <StatusTabsHeader
                  providerCompanyId={providerCompanyId}
                  openOffers={openOffers}
                  acceptedOffers={acceptedOffers}
                  completedOffers={completedOffers}
                  activeTab={'completed'}
                />
                {/* ====== new orders ==== */}
                {
                  Object.keys(staff).length > 0 && (
                    <NewOrdersTab
                      offers={completedOffers}
                      staff={staff}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusTabs;
