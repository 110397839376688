import React, { useEffect, useState } from "react";
import ConstructionIcon from "@mui/icons-material/Construction";
import { unlinkCalendar, nylasOAuth } from "../../../../services/staffingService";
import { useParams } from "react-router-dom";
import DashboardSidebar from "../../../common/dashboardSidebar";
import { useUser } from "../../../../context/UserContext";
import { Loader } from "rsuite";
import GoogleCalendar from "../../icons/google-calendar.png";
import Header from "../../../common/header";
// import "./account.css";

const Integrations = () => {
  const { userInfo, userInfoReady } = useUser();
  const { providerCompanyId } = useParams();
  const [loading, setLoading] = useState(true);
  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const tokenIsValid = isTokenValid(userInfo.integrations);
  const expiryDate = getTokenExpiryDate(userInfo.integrations);

  useEffect(() => {
    if (userInfoReady) {
      setLoading(false);
    }
  }, [userInfoReady]);

  const handleLinkCalendar = async () => {
    const resp = await nylasOAuth();
    window.location.href = resp.data.authUrl;
  };

  const handleUnlinkCalendar = async (grantId) => {
    await unlinkCalendar(grantId);
    // Refresh user data or handle unlink action
  };

  function isTokenValid(integrations) {
    if (!integrations || !integrations.nylas) {
      return false;
    }
    const { expiresIn, created } = integrations.nylas;
    const issueDate = new Date(created);
    const expiryDate = new Date(issueDate.getTime() + expiresIn * 1000);
    const now = new Date();
    return now < expiryDate;
  }

  function getTokenExpiryDate(integrations) {
    if (!integrations || !integrations.nylas) {
      return null;
    }
    const { expiresIn, created } = integrations.nylas;
    const issueDate = new Date(created);
    const expiryDate = new Date(issueDate.getTime() + expiresIn * 1000);
    return expiryDate.toLocaleString();
  }

  return (
    <>

          <div className="p-4">
            {userInfo && (
              <>
                <div className="align-items-start account_card ">
                  <div className="d-flex">
                    <ConstructionIcon
                      style={{
                        color: "#13A391",
                        fontSize: "20px",
                        marginRight: "20px",
                      }}
                    />
                    <h6 className="mb-1 staffing-set-up-guide-title">
                      Integrations
                    </h6>
                  </div>

                  <div>
                    <div className="account-integrations-calendar-container">
                      <img
                        className="account-integrations-calendar-icon"
                        src={GoogleCalendar}
                        alt="Google Calendar"
                      />
                      <div className="account-integrations-calendar-text">
                        <p className="account-integrations-calendar-label">
                          Google Calendar
                        </p>
                        {userInfo.integrations && userInfo.integrations.nylas ? (
                          <p className="account-integrations-email">
                            {userInfo.integrations.nylas.email} <br />
                            {tokenIsValid ? (
                              <>
                                <span
                                  className="account-integrations-action"
                                  onClick={() =>
                                    handleUnlinkCalendar(
                                      userInfo.integrations.nylas.grantId
                                    )
                                  }
                                >
                                  (Unlink)
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="account-integrations-action"
                                  onClick={handleLinkCalendar}
                                >
                                  (Relink)
                                </span>
                                <span> - Token expired</span>
                              </>
                            )}
                          </p>
                        ) : (
                          <p
                            className="account-integrations-link"
                            onClick={handleLinkCalendar}
                          >
                            Link your calendar
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {loading && <Loader backdrop center />}
          </div>

    </>
  );
};

export default Integrations;
