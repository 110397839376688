import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { Button } from 'rsuite';

import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import EditIcon from '@mui/icons-material/Edit';
import BusinessIcon from '@mui/icons-material/Business';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PublicIcon from '@mui/icons-material/Public';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

import { updateCustomerCompanyDetails } from '../../../../../services/staffingService';

// Validation schema for the form
const companyDetailsSchema = yup.object().shape({
  companyLegalName: yup.string().required('Company legal name is required'),
  companyFriendlyName: yup.string().required('Company friendly name is required'),
  hourlyBillingRate: yup.number().required('Hourly billing rate is required').min(45, 'Hourly rate should be at least $45').max(500, 'Cannot exceed $500'),
  companyWebsiteUrl: yup.string().url('Invalid URL').nullable(),
  facilityType: yup.string().required('Facility type is required'),
  contactPersonName: yup.string().required('Contact person name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Phone number is required'),
});

const ContactInfo = ({ initialCompanyData, providerCompanyId }) => {
  const [companyData, setCompanyData] = useState(initialCompanyData);
  const [isEditing, setIsEditing] = useState(false);

  const { register, handleSubmit, control, formState: { errors }, reset } = useForm({
    resolver: yupResolver(companyDetailsSchema),
    defaultValues: {
      companyLegalName: initialCompanyData?.companyLegalName || '',
      companyFriendlyName: initialCompanyData?.companyFriendlyName || '',
      hourlyBillingRate: initialCompanyData?.hourlyBillingRate || '',
      companyWebsiteUrl: initialCompanyData?.companyWebsiteUrl || '',
      facilityType: initialCompanyData?.facilityType || '',
      contactPersonName: initialCompanyData?.contact?.contactPersonName || '',
      email: initialCompanyData?.contact?.email || '',
      phone: initialCompanyData?.contact?.phone || '',
    },
  });

  useEffect(() => {
    setCompanyData(initialCompanyData);
    reset({
      companyLegalName: initialCompanyData?.companyLegalName || '',
      companyFriendlyName: initialCompanyData?.companyFriendlyName || '',
      hourlyBillingRate: initialCompanyData?.hourlyBillingRate || '',
      companyWebsiteUrl: initialCompanyData?.companyWebsiteUrl || '',
      facilityType: initialCompanyData?.facilityType || '',
      contactPersonName: initialCompanyData?.contact?.contactPersonName || '',
      email: initialCompanyData?.contact?.email || '',
      phone: initialCompanyData?.contact?.phone || '',
    });
  }, [initialCompanyData, reset]);

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const onSubmit = async (data) => {
    try {
      await updateCustomerCompanyDetails(providerCompanyId, companyData._id, {
        companyLegalName: data.companyLegalName,
        companyFriendlyName: data.companyFriendlyName,
        hourlyBillingRate: data.hourlyBillingRate,
        companyWebsiteUrl: data.companyWebsiteUrl,
        facilityType: data.facilityType,
        contactPersonName: data.contactPersonName,
        phone: data.phone,
        email: data.email,
      });

      setCompanyData((prevData) => ({
        ...prevData,
        companyLegalName: data.companyLegalName,
        companyFriendlyName: data.companyFriendlyName,
        hourlyBillingRate: data.hourlyBillingRate,
        companyWebsiteUrl: data.companyWebsiteUrl,
        facilityType: data.facilityType,
        contact: {
          contactPersonName: data.contactPersonName,
          phone: data.phone,
          email: data.email,
        },
      }));

      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update company details:', error);
    }
  };

  return (
    <>
      {!isEditing ? (
        <div className="prepopulated-values-data">
          <p><BusinessIcon className="icon" /> Company Legal Name: {companyData.companyLegalName || 'N/A'}</p>
          <p><HomeWorkIcon className="icon" /> Company Friendly Name: {companyData.companyFriendlyName || 'N/A'}</p>
          <p><AttachMoneyIcon className="icon" /> Hourly Billing Rate: {companyData.hourlyBillingRate || 'N/A'}</p>
          <p><PublicIcon className="icon" /> Company Website: {companyData.companyWebsiteUrl || 'N/A'}</p>
          <p><HomeWorkIcon className="icon" /> Facility Type: {companyData.facilityType || 'N/A'}</p>
          <p><PersonIcon className="icon" /> Contact Person: {companyData.contact?.contactPersonName || 'N/A'}</p>
          <p><EmailIcon className="icon" /> Email: {companyData.contact?.email || 'N/A'}</p>
          <p><PhoneIcon className="icon" /> Phone: {companyData.contact?.phone || 'N/A'}</p>

          <Button variant="text" startIcon={<EditIcon />} onClick={toggleEditMode} className="edit-button">Edit</Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="configure-form">
          <div className="form-geoups">
            <div className="form-group">
              <label className="title">Company Legal Name</label>
              <input type="text" {...register('companyLegalName')} className="form-control" placeholder="Amazon Inc." />
              {errors.companyLegalName && <span className="errorTextForm">{errors.companyLegalName.message}</span>}
            </div>

            <div className="form-group">
              <label className="title">Friendly Name</label>
              <input type="text" {...register('companyFriendlyName')} className="form-control" placeholder="Amazon Warehouse" />
              {errors.companyFriendlyName && <span className="errorTextForm">{errors.companyFriendlyName.message}</span>}
            </div>

            <div className="form-group">
              <label className="title">Hourly Billing Rate ($)</label>
              <input type="number" {...register('hourlyBillingRate')} className="form-control" placeholder={48} />
              {errors.hourlyBillingRate && <span className="errorTextForm">{errors.hourlyBillingRate.message}</span>}
            </div>

            <div className="form-group">
              <label className="title">Company Website URL</label>
              <input type="text" {...register('companyWebsiteUrl')} className="form-control" placeholder="https://www.amazon.com" />
              {errors.companyWebsiteUrl && <span className="errorTextForm">{errors.companyWebsiteUrl.message}</span>}
            </div>

            <div className="form-group">
              <label className="title">Facility Type</label>
              <select {...register("facilityType")} className="form-control">
                <option value="store">Store</option>
                <option value="workplace">Workplace</option>
                <option value="warehouse">Warehouse</option>
                <option value="office">Office</option>
                <option value="factory">Factory</option>
                <option value="construction">Construction Site</option>
                <option value="other">Other</option>
              </select>
              {errors.facilityType && <span className="errorTextForm">{errors.facilityType.message}</span>}
            </div>
          </div>

          <div className="form-geoups">
            <div className="form-group">
              <label className="title">Contact Person</label>
              <input type="text" {...register('contactPersonName')} className="form-control" placeholder="John Doe" />
              {errors.contactPersonName && <span className="errorTextForm">{errors.contactPersonName.message}</span>}
            </div>

            <div className="form-group">
              <label className="title">Email</label>
              <input type="email" {...register('email')} className="form-control" placeholder="john.doe@example.com" />
              {errors.email && <span className="errorTextForm">{errors.email.message}</span>}
            </div>

            <div className="form-group phone_field">
              <label className="title">Phone</label>
              <Controller name="phone" control={control} render={({ field }) => (
                <PhoneInput defaultCountry="US" value={field.value} onChange={field.onChange}  />
              )} />
              {errors.phone && <span className="errorTextForm">{errors.phone.message}</span>}
            </div>
          </div>

          <div className="text-right">
            <button type="submit" className="staffing-btn confirm-btn">Save</button>
            <button type="button" className="staffing-btn cancel-btn ml-2" onClick={toggleEditMode}>Cancel</button>
          </div>
        </form>
      )}
    </>
  );
};

export default ContactInfo;
