import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCheck } from '@fortawesome/free-solid-svg-icons';
import '../../styles/shiftAttendance.css'; // Make sure to create this CSS file for styling

function formatJobDateTime(dateTime) {
    const date = new Date(dateTime);
    return date.toLocaleDateString('en-US', {
        weekday: 'short', // "Mon", "Tue", etc.
        month: 'short',   // "Jul"
        day: 'numeric',   // "31"
    });
}

function formatTime(dateTime) {
    const date = new Date(dateTime);
    return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
    });
}

const CountdownTicker = ({ targetTime, label }) => {
    const calculateTimeLeft = (targetTime) => {
        const now = new Date().getTime();
        const difference = targetTime - now;

        const timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((difference % (1000 * 60)) / 1000),
        };

        return difference > 0 ? timeLeft : { days: 0, hours: 0, minutes: 0, seconds: 0 };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(new Date(targetTime).getTime()));

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimeLeft(calculateTimeLeft(new Date(targetTime).getTime()));
        }, 1000);

        return () => clearInterval(timerId);
    }, [targetTime]);

    return (
        <div className="shift-checkins-countdown-ticker">
            <div>
                * {label} in
                <span className="shift-checkins-time-left">
                    {timeLeft.days > 0 && ` ${timeLeft.days} days, `}
                    {timeLeft.hours > 0 && ` ${timeLeft.hours} hrs, `}
                    {` `}{timeLeft.minutes} mins, {timeLeft.seconds} secs
                </span>
            </div>
        </div>
    );
};

const ShiftAttendance = ({ offer, checkIn, checkOut }) => {
    const [activeTab, setActiveTab] = useState(null);
    const now = new Date().getTime();

    useEffect(() => {
        if (offer.shifts.length > 0) {
            setActiveTab(offer.shifts[0].shiftId);
        }
    }, [offer.shifts]);

    // Updated getCheckInActionComponent
const getCheckInActionComponent = (shift) => {
    const checkInWindowStart = new Date(shift.checkIn.timeWindow.start).getTime();
    const checkInWindowEnd = new Date(shift.checkIn.timeWindow.end).getTime();
    const checkInConfirmed = shift.checkIn.confirmed;

    switch (true) {
        case (checkInWindowStart <= now && now <= checkInWindowEnd && !checkInConfirmed):
            return (
                <div className="staff-restaurant check-in" onClick={() => { checkIn(offer._id, shift.shiftId) }}>
                    <div className="staff-label">
                        <FontAwesomeIcon icon={faCheck} /> Geo Check-In
                    </div>
                </div>
            );
        case checkInConfirmed:
            return (
                <div className="shift-checkins-status">
                    <div>Checked in at {formatTime(shift.checkIn.timestamp)}</div>
                </div>
            );
        case (now > checkInWindowEnd && !checkInConfirmed):
            return (
                <div className="shift-checkins-status">
                    <div>Check-in window has passed. No show</div>
                </div>
            );
        case (now < checkInWindowStart):
            return (
                <CountdownTicker targetTime={checkInWindowStart} label="Shift Check-in starts in " />
            );
        default:
            return null;
    }
};

// Updated getCheckOutActionComponent
const getCheckOutActionComponent = (shift) => {
    const checkOutWindowStart = new Date(shift.checkOut.timeWindow.start).getTime();
    const checkOutWindowEnd = new Date(shift.checkOut.timeWindow.end).getTime();
    const checkOutConfirmed = shift.checkOut.confirmed;
    const checkInConfirmed = shift.checkIn.confirmed;

    if (!checkInConfirmed) {
        return (
            <div className="shift-checkins-status">
                <div>Check-in required before check-out.</div>
            </div>
        );
    }

    switch (true) {
        case (checkOutWindowStart <= now && now <= checkOutWindowEnd && !checkOutConfirmed):
            return (
                <div className="staff-restaurant check-out" onClick={() => { checkOut(offer._id, shift.shiftId) }}>
                    <div className="staff-label">Geo Check-Out</div>
                </div>
            );
        case checkOutConfirmed:
            return (
                <div className="shift-checkins-status">
                    <div>Checked out at {formatTime(shift.checkOut.timestamp)}</div>
                </div>
            );
        case (now > checkOutWindowEnd && !checkOutConfirmed):
            return (
                <div className="shift-checkins-status">
                    <div>Check-out window has passed. You missed it.</div>
                </div>
            );
        case (now < checkOutWindowStart):
            return (
                <CountdownTicker targetTime={checkOutWindowStart} label="Shift Check-out until " />
            );
        default:
            return null;
    }
};

/*
    const getCheckOutActionComponent = (shift) => {
        const checkOutWindowStart = new Date(shift.checkOut.timeWindow.start).getTime();
        const checkOutWindowEnd = new Date(shift.checkOut.timeWindow.end).getTime();
        const checkOutConfirmed = shift.checkOut.confirmed;
        const checkInConfirmed = shift.checkIn.confirmed;

        if (!checkInConfirmed) {
            return (
                <div className="shift-checkins-status">
                    <div>Check-in required before check-out.</div>
                </div>
            );
        }

        switch (true) {
            case (checkOutWindowStart <= now && now <= checkOutWindowEnd && !checkOutConfirmed):
                return (
                    <div className="shift-checkins-check-out" onClick={() => { checkOut(offer._id, shift.shiftId) }}>
                        <div className="shift-checkins-check-button">
                            <FontAwesomeIcon icon={faCheck} /> Geo Check-Out
                        </div>
                    </div>
                );
            case checkOutConfirmed:
                return (
                    <div className="shift-checkins-status">
                        <div>Checked out at {formatTime(shift.checkOut.timestamp)}</div>
                    </div>
                );
            case (now > checkOutWindowEnd && !checkOutConfirmed):
                return (
                    <div className="shift-checkins-status">
                        <div>Check-out window has passed. You missed it.</div>
                    </div>
                );
            case (now < checkOutWindowStart):
                return (
                    <CountdownTicker targetTime={checkOutWindowStart} label="Shift Check-out until " />
                );
            default:
                return null;
        }
    };
*/
    const filteredShifts = offer.shifts.filter((shift) => shift.shiftId === activeTab);

    return (
        <div className="shift-checkins-container">
            <div className="shift-checkins-tabs">
                {offer.shifts.map(shift => (
                    <div
                        key={shift.shiftId}
                        className={`shift-checkins-tab ${shift.shiftId === activeTab ? 'shift-checkins-tab-active' : ''}`}
                        onClick={() => setActiveTab(shift.shiftId)}
                    >
                        {formatJobDateTime(shift.checkIn.timeWindow.start)}
                    </div>
                ))}
            </div>
            <div className="shift-checkins-details-container">
                {filteredShifts.map((shift) => {
                    return (
                        <div key={shift.shiftId} className="shift-checkins-details">
                            <div className="shift-checkins-info">
                                <div className="shift-checkins-time-location">
                                    <div className="shift-checkins-icon-text">
                                        <FontAwesomeIcon icon={faClock} />
                                        <span>
                                            {shift ? `${formatTime(shift.startTime)} - ${formatTime(shift.endTime)}` : 'Shift times not found'}
                                        </span>
                                    </div>
                                </div>
                                <div className="shift-checkins-meeting-details">
                                    {getCheckInActionComponent(shift)}
                                    {getCheckOutActionComponent(shift)}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ShiftAttendance;
