import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  createStripeAccountLink,
} from "../../../../services/staffingService";

import { Redirect, Link, useParams } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

import { useUser } from "../../../../context/UserContext";
import { useProviderCompany } from "../../../../context/ProviderCompanyContext";
import { Loader } from "rsuite";
import { AvatarGroup, Avatar, Badge, Button } from "rsuite";

import PaymentsIcon from "@mui/icons-material/Payments";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import ConstructionIcon from "@mui/icons-material/Construction";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";


const providerResourceSchema = yup.object({
  providerResourceEmail: yup
    .string()
    .required("Email is required for Provider Resource")
    .email("Must be a valid email address"),
});

const Account = () => {
  const { userInfo, userInfoReady } = useUser();

  const { providerCompanyId } = useParams();
  const { providerCompanyData, fetchProviderCompanyData } =  useProviderCompany();
  const [loading, setLoading] = useState(true);
  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const [resourceResponse, setResourceResponse] = useState("");
  const [resourceError, setResourceError] = useState(false);


  const {
    register: registerResource,
    handleSubmit: handleSubmitResource,
    formState: { errors: errorsResource },
    reset: resetResource,
  } = useForm({
    resolver: yupResolver(providerResourceSchema),
  });



  const onSubmitProviderResource = async (data) => {
    console.log(data)
  };

  useEffect(() => {
    if (providerCompanyId) {
      fetchProviderCompanyData(providerCompanyId);
      setLoading(false);
    }
  }, [providerCompanyId, fetchProviderCompanyData]);

  const stripeIntegration = async () => {
    setLoading(true);
    try {
      const resp = await createStripeAccountLink(providerCompanyId);
      window.location.href = resp.data.accountLink;
    } catch (error) {
      setLoading(false);
      console.error("Failed to create Stripe account link:", error);
    }
  };

  return (
    <>
  
  {providerCompanyData && (
  <>
    <div>
      {
        // No Stripe account exists
        !providerCompanyData.stripeAccount && (
          <div className="d-flex align-items-start account_card">
            <PaymentsIcon className="checkboxUnfinished" />
            <div>
              <h6 className="mb-1 staffing-set-up-guide-title">
                No Stripe Account Found
              </h6>
              <p
                className="mb-2 staffing-set-up-guide-description"
                style={{ color: "#5f6368", fontSize: "14px" }}
              >
                You don't have a Stripe account connected yet. Please set up a Stripe account for payments.
              </p>
              <button
                onClick={stripeIntegration}
                className="staffing-set-up-guide-link"
              >
                Set up Stripe Account
              </button>
            </div>
          </div>
        )
      }

      {
        // Full verification: charges enabled, details submitted, and payouts enabled
        providerCompanyData.stripeAccount &&
        providerCompanyData.stripeAccount.charges_enabled &&
        providerCompanyData.stripeAccount.details_submitted &&
        providerCompanyData.stripeAccount.payouts_enabled && (
          <div className="d-flex align-items-start account_card">
            <PaymentsIcon className="checkboxSuccess" />
            <div>
              <h6 className="mb-1 staffing-set-up-guide-title">
                Congratulations! Your Stripe account is verified
                and ready to receive payments and make payouts.
              </h6>
              <p
                className="mb-2 staffing-set-up-guide-description"
                style={{ color: "#5f6368", fontSize: "14px" }}
              >
                Your account is fully verified and you can start
                transactions.
              </p>
              <a
                href="https://dashboard.stripe.com/dashboard"
                target="_blank"
              >
                <button className="staffing-set-up-guide-link">
                  Go to Dashboard
                </button>
              </a>
            </div>
          </div>
        )
      }

      {
        // No email provided yet
        providerCompanyData.stripeAccount &&
        !providerCompanyData.stripeAccount.email && (
          <div className="d-flex align-items-start account_card">
            <PaymentsIcon className="checkboxUnfinished" />
            <div>
              <h6 className="mb-1 staffing-set-up-guide-title">
                Manage Payments
              </h6>
              <p
                className="mb-2 staffing-set-up-guide-description"
                style={{ color: "#5f6368", fontSize: "14px" }}
              >
                Configure Stripe for identity verification and <br /> financial transactions.
              </p>
              <button
                onClick={stripeIntegration}
                className="staffing-set-up-guide-link"
              >
                Go
              </button>
            </div>
          </div>
        )
      }

      {
        // Email provided but details not submitted
        providerCompanyData.stripeAccount &&
        providerCompanyData.stripeAccount.email &&
        !providerCompanyData.stripeAccount.details_submitted && (
          <div className="d-flex align-items-start account_card">
            <PaymentsIcon className="checkboxUnfinished" />
            <div>
              <h6 className="mb-1 staffing-set-up-guide-title">
                Complete Your Stripe Setup
              </h6>

              <button
                onClick={stripeIntegration}
                className="staffing-set-up-guide-link"
              >
                Complete Verification
              </button>
            </div>
          </div>
        )
      }
    </div>
  </>
)}

{loading && <Loader backdrop center />}


    </>
  );
};
export default Account;
