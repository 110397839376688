import React, { useState } from "react";
import { Avatar, Dropdown } from "rsuite";
import "../styles/header.css";
import { Link } from "@mui/material";
import SidebarLeft from "../../_asset/img/sidebar-left.svg";
import NotificationIcon from "../../_asset/img/notification.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useUser } from "../../../context/UserContext";
import Notifications from "./notifications"; // Make sure to adjust the import path based on your file structure

const OfficerHeader = ({ isOpen, setIsOpen }) => {
    const { userInfo } = useUser();
    const location = useLocation();
    const pathName = location?.pathname?.split('/')?.[4];

    // State to manage notification dropdown visibility and notifications
    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState([
        // Example dummy notifications; replace with fetched data as needed
        { message: "Offer rejected by Elon", createdAt: new Date(), readBy: [], link: "/projects/1" },
        { message: "New job assigned for fulfillment", createdAt: new Date(), readBy: [], link: "/meetings" },
    ]);
    const [unreadCount, setUnreadCount] = useState(notifications.length);

    // Toggle notification dropdown visibility
    const toggleNotifications = () => setShowNotifications(!showNotifications);

    return (
        <div className="header_wrapper">
            <div className="leftside">
                <div className={`menubar ${isOpen ? 'active' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                    <img src={SidebarLeft} alt="Sidebar Toggle" />
                </div>
                <span className="page_name" style={{ textTransform: 'capitalize' }}>
                    {pathName.replace('-', ' ')}
                </span>
            </div>
            <div className="rightside">
                <div className="notification" onClick={toggleNotifications}>
                    <img src={NotificationIcon} alt="Notifications" />
                    {unreadCount > 0 && <span className="notification-icon">{unreadCount}</span>}
                    {showNotifications && (
                        <div className="notification-dropdown">
                            <Notifications
                                notifs={notifications}
                                setNotifs={setNotifications}
                                n={unreadCount}
                                setN={setUnreadCount}
                            />
                        </div>
                    )}
                </div>

                <span className="line"></span>

                <Dropdown
                    title={
                        <div className="profile">
                            <Link to="/home" className="dashboard-sidebar-avatar-link">
                                <Avatar bordered circle src={userInfo.profilePictureUrl} />
                            </Link>
                            <div className="user_details">
                                <p className="name">{userInfo.firstName} {userInfo.lastName}</p>
                                <span className="roll">Officer</span>
                            </div>
                        </div>
                    }
                >
                    {/* Additional dropdown items can be added here */}
                </Dropdown>
            </div>
        </div>
    );
};

export default OfficerHeader;
