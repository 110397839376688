// import './main.css';
import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useHistory } from "react-router-dom";

import Education from './Education';
import LivingWith from './LivingWith';
import ApartMent from './Apartment';
import Household from './Household';
import Races from './Races';
import Occupations from './Occupation';

import Info from "./Info"

import _ from "lodash";

const Main = ({demographics}) => {

    const [limitExceeded, setLimitExceeded] = useState(false)
    const [loading, setLoading] = useState(true)
    const [exception, setException] = useState(false)
    const [dts, setDts] = useState([]);
    const [areas, setAreas] = useState([]);
    const [attributes, setAttributes] = useState({});
    /*
    const [ loc, setLoc ] = useState({
      statekey : useParams().statekey, 
      citykey: useParams().citykey
    });
    */
    const history = useHistory();
    const nf = new Intl.NumberFormat();

    useEffect(() => {
        function getData(dtset) {
            if (dtset && dtset.length > 0) {
                const obj = dtset[0].proportions[0];
                setDts([...dtset[0].proportions]);
                setLoading(false);
                const plzs = _.map(dtset[0], 'plz');
                setAreas([...plzs]);
                setAttributes({
                    state: dtset[0].state,
                    city: dtset[0].city,
                    population: dtset[0].population,
                    households: dtset[0].households,
                    old: dtset[0].old,
                    young: dtset[0].young,
                    center: [dtset[0].lng, dtset[0].lat],
                });
            }
        }
    
        getData(demographics);
    }, [demographics]); // Add demographics as a dependency
    

    return (

        <div className='content-wrapper'>
            {dts.length > 0 && !loading &&
                <>
<div style={{ fontSize: '0.85rem', color: '#555', marginTop: '10px' }}>
  <p>
    <u> Info </u> : Data aggregated over overall census ZCTA areas within the selected city. 
    <a href="https://en.wikipedia.org/wiki/ZIP_Code_Tabulation_Area" 
       target="_blank" 
       rel="noopener noreferrer" 
       style={{ color: '#007BFF', textDecoration: 'none', marginLeft: '5px' }}>
       Learn more about ZCTA
    </a>.
  </p>
</div>                    
<div className='grid_box_content'>
                        <Info dts={dts} attributes={attributes} />
                        <LivingWith dts={dts} />
                    </div>
                    <ApartMent dts={dts} />
                    <div className='bar_chart'>
                        <Household dts={dts} />
                    </div>
                    <div className='races_chart'>
                        <Education dts={dts} />
                        <Races dts={dts} />
                    </div>
                        <Occupations dts={dts} />
                </>
            }
            {loading && dts.length == 0 && <div> Loading ... </div>}
        </div>
    )
}

export default Main; 