import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import WorkIcon from "@mui/icons-material/Work";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Button,
  Badge,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Drawer, Tabs, Toggle, Avatar } from "rsuite";
import Send from "../../../_asset/img/send-2.svg";
// import Rectangle from '../../../_asset/img/Rectangle.svg';
import { ReactComponent as Magicpen } from "../../../_asset/img/magicpen.svg";
import Paperclip from "../../../_asset/img/paperclip-2.png";
import AskAI from "./tabs/askAI";
import OfficerDetails from "./tabs/details";
import OfficerPreferences from "./tabs/preferences";
import OfficerDocuments from "./tabs/documents";

const OfficerDrawer = ({
  officerData,
  providerCompanyId,
  officers,
  filteredOfficers,
  setOfficers,
  setFilteredOfficers,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div>
      <td>
        <span className="share_icon" onClick={() => setOpenDrawer(true)}>
          <img src={Send} alt="Send" />
        </span>
      </td>

      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        className="customer_side_drawer"
      >
        <Drawer.Body>
          <div className="header_content text-center">
            <h2 className="drawer__title">
            {officerData.user_id.firstName && officerData.user_id.lastName 
              ? `${officerData.user_id.firstName} ${officerData.user_id.lastName}` 
              : officerData.user_id.email}

            </h2>
          </div>
          <div className="body__content">
            <Tabs defaultActiveKey="1" appearance="subtle">
              <Tabs.Tab eventKey="1" title="Ask AI">
                <AskAI />
              </Tabs.Tab>

              <Tabs.Tab eventKey="3" title="Details">
                <div className="tabs__content" id="scrollBar">
                  <OfficerDetails
                    officerData={officerData}
                    providerCompanyId={providerCompanyId}
                  />
                </div>
              </Tabs.Tab>

              <Tabs.Tab eventKey="2" title="Preferences">
                <div className="tabs__content" id="scrollBar">
                  <OfficerPreferences
                    officerData={officerData}
                    providerCompanyId={providerCompanyId}
                  />
                  {!officerData.preferences && (
                    <p>No preferences set by an Officer.</p>
                  )}
                </div>
              </Tabs.Tab>

              {/* <Tabs.Tab eventKey="4" title="Performance">
                                <div className='tabs__content' id='scrollBar'>
                                    <p>  placeholder to dispay overall performance of an officer, CSAT, punctuality, reviews etc., </p>
                                </div>
                            </Tabs.Tab> */}

              <Tabs.Tab eventKey="5" title="Documents">
                <div className="tabs__content" id="scrollBar">
                  <OfficerDocuments
                    officerData={officerData}
                    providerCompanyId={providerCompanyId}
                    officers={officers}
                    filteredOfficers={filteredOfficers}
                    setOfficers={setOfficers}
                    setFilteredOfficers={setFilteredOfficers}
                  />
                </div>
              </Tabs.Tab>
            </Tabs>
          </div>
        </Drawer.Body>
      </Drawer>
    </div>
  );
};

const NewOrder = ({
  officers,
  providerCompanyId,
  filteredOfficers,
  setOfficers,
  setFilteredOfficers,
}) => {
  const [loading, setLoading] = useState(true);
  const [isToggled, setIsToggled] = useState(false);
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleCreateWorkOrder = (customerCompanyId) => {
    history.push(
      `/provider-company/${providerCompanyId}/work-order/${customerCompanyId}/new`
    );
  };

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className="">
      <div className="staffing-set-up-guide-container">
        <div className="staffing-set-up-guide-card">
          <div className="card-body p-0">
            <div className="unassigne_content customer_lists">
              <table class="table border-0">
                <thead>
                  <tr>
                    <th>Officer</th>
                    <th>Email</th>
                    {/* <th>Legal Name</th> */}
                    {/* <th>Jobs</th> */}
                    <th>Address</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody id="data-body">
                  {filteredOfficers?.length > 0
                    ? filteredOfficers.map((officer, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <p className="required_col">
                                {/* <BusinessIcon style={{ fontSize: '20px', color: '#3D4784', verticalAlign: 'middle', marginRight: '8px' }} /> */}
                                <Avatar
                                  src={
                                    officer.user_id
                                      ? officer.user_id.profilePictureUrl
                                      : undefined
                                  }
                                  className=""
                                  circle
                                  style={{
                                    fontSize: "20px",
                                    color: "#3D4784",
                                    verticalAlign: "middle",
                                    marginRight: "8px",
                                  }}
                                />
                                {/* Display the first letter if no profile picture URL is available */}
                                {/* {!resource.user_id.profilePictureUrl && resource.user_id.firstName.charAt(0)} */}
                                {/* { officer.firstName[0] }
                                                        </Avatar> */}
                                {officer.firstName}
                                {/* {officer.user_id.firstName} {officer.user_id?.lastName} */}
                              </p>
                            </td>
                            <td>
                              <p className="required_col">
                                {officer.user_id
                                  ? officer.user_id.email
                                  : "N/A"}
                              </p>
                            </td>
                            {/* <td>
                                                    <p className="required_col">{company.companyLegalName}</p>
                                                </td> */}
                            {/* <td>
                                                    <p className="required_col">{company.jobCount}</p>
                                                </td> */}
                            <td>
                              <p className="required_col">
                                {officer.formattedLocation
                                  ? officer.formattedLocation
                                  : "N/A"}
                              </p>
                            </td>
                            {/* <td className="badge_tab">
                                                    <Badge onClick={() => handleCreateWorkOrder(officer._id)}>
                                                         Edit Details
                                                    </Badge>
                                                </td> */}
                            <td>
                              <span className="share_icon">
                                <OfficerDrawer
                                  officerData={officer}
                                  providerCompanyId={providerCompanyId}
                                  officers={officers}
                                  filteredOfficers={filteredOfficers}
                                  setOfficers={setOfficers}
                                  setFilteredOfficers={setFilteredOfficers}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
              {!filteredOfficers?.length > 0 && (
                <div className="no_data">
                  {" "}
                  <p className="provider-company-customer-no-companies p-0 m-0">
                    No officers found for selected filters.
                  </p>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrder;
